import {
  BodySmall,
  Caption,
  CountryFlag,
  LineGraph,
  Subtitle1,
  Icon,
} from 'components/shared';
import { colors, globalContributor } from 'constants/index';
import { Country } from 'types';
import { createScoreDistribution } from './Evaluation';
import styled from 'styled-components';
import { ModalXClose } from 'components/ModalXClose';
import { Maybe, Role, User, Competitor, CompetitiveCategory, CompetitorFragment, Stakeholder } from 'data/graphql/generated';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';
import { useParams } from 'react-router-dom';
import { IconName } from 'types';
import { CompetitorTitleWrapper, CompetitorTitle, CompetitorImage  } from './EvaluationStakeHolder';
import { TreatmentIcon } from 'components/CompetitiveLandscape/TreatmentIcon';

const FlagGraphRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;
`;

const Ratings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const StyledLineGraph = styled(LineGraph)`
  padding: 0;

  > .lineGraph__numberMarkers {
    top: 15px;
    left: 0;
    width: 100%;
    padding-bottom: 15px;
  }

  > .lineGraph__graphWrapper {
    height: 15px;
  }
`;

const ModalCompetitorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const ModalCompetitorImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
`;

const ModalCompetitorTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.black70};
`;

const CompetitorGroupWrapper = styled.div`
  border: 1px solid ${colors.greyLight};
  border-radius: 5px;
  padding: 20px;
`;

const StakeholderSection = styled(CompetitorGroupWrapper)<{ bgColor: string }>`
  background-color: ${props => `${props.bgColor}10`}; // Using 10% opacity version of the color
  padding: 16px;
  border-radius: 8px;
`;

const EmptyStateWrapper = styled(CompetitorGroupWrapper)`
border:none;
  background-color: #FFFDF7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const EmptyStateText = styled(BodySmall)`
  color: ${colors.greyDark};
`;

interface URLParams {
  category: CompetitiveCategory;
}
export interface DistributionModalProps {
  visible: boolean;
  handleClose(): void;
  companyUsersData?: User[] | null;
  competitor?: Competitor;
  competitors: CompetitorFragment[];
  stakeHolder?: any;
  title?: string;
  subtitle?: string;
  votingInfo?: any;
  groupOutcomes?: any;
  ratings?: {
    stakeholder: Stakeholder;
    competitorId: any;
    id: number;
    region?: Maybe<string>;
    score: number;
    user?: Pick<User, 'id' | 'country' | 'role' | 'name' | 'title'> | null;
  }[];
  hideRatings?: boolean;
}

export type DistributionModalValues = Pick<
  DistributionModalProps,
  'subtitle' | 'ratings' | 'title' | 'hideRatings' | 'competitor' | 'stakeHolder' | 'votingInfo' | 'groupOutcomes'>;

const ModalXCloseCustom = styled(ModalXClose)`
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    max-height: none;
    padding: 48px 20px 0px 0px;
    
    .modal__content {
      height: 100%;
      max-height: none;
      border-radius: 0;
    }
  }
`;

export const DistributionModalCustom: React.FC<DistributionModalProps> = ({
  visible,
  competitor,
  competitors,
  stakeHolder,
  handleClose,
  title,
  subtitle,
  ratings,
  votingInfo,
  groupOutcomes,
  companyUsersData,
  hideRatings
}) => {
  const sortedRatings = ratings || [];


  const stakeHolders = [
    {
      id: 1,
      title: 'Patients',
      subtitle: 'Patient',
      color: colors.purple,
      type: Stakeholder.Patient,
      icon: 'Patient'
    },
    {
      id: 2,
      title: 'HCPs',
      subtitle: 'Healthcare Professional',
      color: colors.green,
      type: Stakeholder.Healthcare,
      icon: 'HCP'
    },
    {
      id: 3,
      title: 'Payors',
      subtitle: 'Payor',
      color: colors.blue,
      type: Stakeholder.Payor,
      icon: 'Dollar'
    }
    ]
  
  const {
    category,
  }: URLParams = useParams();

  const ratingsUsers =
    sortedRatings?.map((rating) => rating.user?.id).filter((val) => !!val) ||
    [];

  const unratedUsers =
    companyUsersData
      ?.filter((user) => !ratingsUsers.includes(user?.id))
      .map((val) => ({
        user: val,
      })) || [];

  const combinedUsers = [...sortedRatings, ...unratedUsers].sort((a, b) => {
    if (!a.user?.country || !b.user?.country) {
      return 0;
    }

    // Sort by role
    if (a.user.role === Role.Lead && b.user.role !== Role.Lead) {
      return 1;
    }
    if (b.user.role === Role.Lead && a.user.role !== Role.Lead) {
      return -1;
    }

    // Sort by title
    // Some Leads might have no title. Default should be 'Lead'
    const titleA =
      a.user.role === Role.Lead && !a?.user.title
        ? Role.Lead.toUpperCase()
        : a?.user.title?.toUpperCase();
    const titleB =
      b.user.role === Role.Lead && !b?.user.title
        ? Role.Lead.toUpperCase()
        : b?.user.title?.toUpperCase();

    if (!!titleA && !!titleB) {
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
    }

    if (!titleA && !!titleB) {
      return -1;
    }
    if (!titleB && !!titleA) {
      return 1;
    }

    const countryA = a.user.country.toUpperCase();
    const countryB = b.user.country.toUpperCase();

    // Put global contributor below contributors
    if (
      countryA === globalContributor.toUpperCase() &&
      countryB !== globalContributor.toUpperCase()
    ) {
      return 1;
    }
    if (
      countryB === globalContributor.toUpperCase() &&
      countryA !== globalContributor.toUpperCase()
    ) {
      return -1;
    }

    // Leads have countries too so sort alphabetical for only contributors
    if (a.user.role !== Role.Lead) {
      if (countryA < countryB) {
        return -1;
      }
      if (countryA > countryB) {
        return 1;
      }
    }
    const nameA = a.user.name.toUpperCase();
    const nameB = b.user.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const mergedDataWithScores = combinedUsers.reduce((acc: any, item: any) => {
    // if (!item.user) return acc; // Skip if no user
  
    // // Check if the user already exists in the accumulator
    // const existingUser = acc.find((entry: any) => entry.user.id === item.user.id);
  
    // if (existingUser) {
    //   // Add the score to the existing user's scores array
    //   if ('score' in item) {
    //     existingUser.scores.push(item.score);
    //   }
    // } else {
      // Add new user with their score
      acc.push({
        user: item.user,
        stakeholder: item.stakeholder,
        scores: 'score' in item ? [item.score] : [],
        competitorId: item.competitorId,
      });
    // }
  
    return acc;
  }, []);

  return (
    <ModalXCloseCustom handleClose={handleClose} visible={visible}>
      <Subtitle1>{title}</Subtitle1>

      {stakeHolder && 
        <CompetitorTitleWrapper>
          {stakeHolder?.image ? (
            <CompetitorImage imageURL={stakeHolder.image}/>
          ) : stakeHolder?.icon ? (
            <Icon 
              size={40}
              name={stakeHolder.icon as IconName} 
              color={stakeHolder.color}
            />
          ) : null}
          <CompetitorTitle>{stakeHolder?.subtitle}</CompetitorTitle>
        </CompetitorTitleWrapper>
      }
      {/* <CompetitorImage imageURL={competitor.image} /> */}
      {subtitle && <Caption color={colors.greyDark}>{subtitle}</Caption>}
      {competitor 
        && 
        <CompetitorTitleWrapper style={{marginTop: 10}}>
          {competitor.image ? (
            <CompetitorImage imageURL={competitor.image} />
          ) : (
            <TreatmentIcon size={40} />
          )}
          <CompetitorTitle>{competitor.title}</CompetitorTitle>
          
        </CompetitorTitleWrapper>
      }

      {(category === CompetitiveCategory.Technologies || category === CompetitiveCategory.Actions) ? 
      <Ratings>
        {mergedDataWithScores.map((rating: any) => {
          if (rating.scores.length === 0) {
            return (
              <div key={rating.user.id}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <CountryFlag size={30} user={rating.user} disableTooltip />
                  <BodySmall>Not voted yet</BodySmall>
                </div>
                <Caption
                  style={{
                    textAlign: 'left',
                    marginLeft: 35,
                    marginTop: 2,
                  }}
                  color={colors.greyDark}
                >
                  {getUserTitleOrCountryAndName(rating.user)}
                </Caption>
              </div>
            );
          }
        
          const formattedScores = rating.scores.map((score: any) => ({ score }));
          const scoreDistribution = createScoreDistribution(formattedScores);
          
          return (
            <div key={rating.id}>
              <FlagGraphRow>
                <div>
                  {rating.user ? (
                    <CountryFlag size={30} user={rating.user} disableTooltip />
                  ) : (
                    <CountryFlag
                      size={30}
                      country={rating.region as Country}
                      disableTooltip
                    />
                  )}
                </div>
                <div style={{ height: 30, width: '100%' }}>
                  <StyledLineGraph
                    values={scoreDistribution}
                    maxRating={5}
                  />
                </div>
              </FlagGraphRow>
              {!rating?.user ? null : (
                <Caption
                  style={{
                    textAlign: 'left',
                    marginLeft: 35,
                    marginTop: 2,
                  }}
                  color={colors.greyDark}
                >
                  {getUserTitleOrCountryAndName(rating?.user)}
                </Caption>
              )}
            </div>
          );
        })}
        </Ratings>
      : 
      
      <Ratings>
        {competitor ? (
          // Check if competitor is included in any stakeholder groups
          stakeHolders.some((stake: any) => 
            groupOutcomes?.some(
              (group: any) =>   
                group.stakeholder === stake?.type &&
                group.title === title &&  
                group.competitors.some((c: any) => c.id === competitor.id)
            )
          ) ? (
            // Original stakeholders.map code when competitor is included
            stakeHolders.map((stake: any) => {
              const isIncludedInGroup = groupOutcomes?.some(
                (group: any) => 
                  group.stakeholder === stake?.type &&
                  group.title === title &&  
                  group.competitors.some((c: any) => c.id === competitor.id)
              );
              return (
                <StakeholderSection 
                  key={stake.id} 
                  bgColor={stake.color}
                >
                  <ModalCompetitorWrapper>
                    {stake.icon && (
                      <Icon 
                        size={30}
                        name={stake.icon as IconName} 
                        color={stake.color}
                      />
                    )}
                    <ModalCompetitorTitle>{stake.subtitle}</ModalCompetitorTitle>
                  </ModalCompetitorWrapper>
                  
                  {mergedDataWithScores.filter((rating: any) => 
                    sortedRatings.some(
                      (sr) => sr.stakeholder === stake.type && sr.user?.id === rating.user.id
                    )
                  ).length === 0 ? (
                    <BodySmall color={colors.black}>
                      {!isIncludedInGroup ? 'Competitor not included for this stakeholder.' : 
                        mergedDataWithScores.map((rating: any) => (
                          <div key={rating.user.id}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                              <CountryFlag size={30} user={rating.user} disableTooltip />
                              <BodySmall>Not voted yet</BodySmall>
                            </div>
                            <Caption
                              style={{
                                textAlign: 'left',
                                marginLeft: 35,
                                marginTop: 2,
                              }}
                              color={colors.greyDark}
                            >
                              {getUserTitleOrCountryAndName(rating.user)}
                            </Caption>
                          </div>
                        ))
                      }
                     
                    </BodySmall>
                  ) : (
                    mergedDataWithScores
                      .filter((rating: any) => {
                        // Show rating if it has matching competitorId or if it has no competitorId (not voted)
                        return !rating.stakeholder || rating.stakeholder === stake.type;
                      })
                      .map((rating: any) => {
                        // Check if user hasn't voted (no scores or no competitorId)
                        if (rating.scores.length === 0 || !rating.competitorId) {
                          return (
                            <div key={rating.user.id}>
                              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <CountryFlag size={30} user={rating.user} disableTooltip />
                                <BodySmall>Not voted yet</BodySmall>
                              </div>
                              <Caption
                                style={{
                                  textAlign: 'left',
                                  marginLeft: 35,
                                  marginTop: 2,
                                }}
                                color={colors.greyDark}
                              >
                                {getUserTitleOrCountryAndName(rating.user)}
                              </Caption>
                            </div>
                          );
                        }
                    
                        const formattedScores = rating.scores.map((score: any) => ({ score }));
                        const scoreDistribution = createScoreDistribution(formattedScores);
                        
                        return (
                          <div key={rating.id}>
                            <FlagGraphRow>
                              <div>
                                {rating.user ? (
                                  <CountryFlag size={30} user={rating.user} disableTooltip />
                                ) : (
                                  <CountryFlag
                                    size={30}
                                    country={rating.region as Country}
                                    disableTooltip
                                  />
                                )}
                              </div>
                              <div style={{ height: 30, width: '100%' }}>
                                <StyledLineGraph
                                  values={scoreDistribution}
                                  maxRating={5}
                                />
                              </div>
                            </FlagGraphRow>
                            {!rating?.user ? null : (
                              <Caption
                                style={{
                                  textAlign: 'left',
                                  marginLeft: 35,
                                  marginTop: 2,
                                }}
                                color={colors.greyDark}
                              >
                                {getUserTitleOrCountryAndName(rating?.user)}
                              </Caption>
                            )}
                          </div>
                        );
                      })
                  )}
                </StakeholderSection>
              );
            })
          ) : (
            <EmptyStateWrapper>
              <EmptyStateText>
                Outcome not included for this competitor.
              </EmptyStateText>
            </EmptyStateWrapper>
          )
        ) : (
          // Check if any competitors are included before mapping
          competitors.some((comp: any) => 
            groupOutcomes?.some((group: any) => 
              group.stakeholder === stakeHolder?.type &&
              group.title === title &&
              group.competitors.some((c: any) => c.id === comp.id)
            )
          ) ? (
            // Original competitors.map code when at least one competitor is included
            competitors.map((comp: any) => {
              const isIncludedInGroup = groupOutcomes?.some(
                (group: any) => 
                  group.stakeholder === stakeHolder?.type &&
                  group.title === title &&
                  group.competitors.some((c: any) => c.id === comp.id)
              );
              
              return (
                <CompetitorGroupWrapper key={comp.id}>
                  <ModalCompetitorWrapper>
                    {comp.image ? (
                      <ModalCompetitorImage src={comp.image} alt={comp.title} />
                    ) : (
                      <TreatmentIcon size={30} />
                    )}
                    <ModalCompetitorTitle>{comp.title}</ModalCompetitorTitle>
                  </ModalCompetitorWrapper>
                  
                  {mergedDataWithScores.filter((rating: any) => 
                    sortedRatings.some(
                      (sr) => sr.competitorId === comp.id && sr.user?.id === rating.user.id
                    )
                  ).length === 0 ? (
                    <BodySmall color={colors.black}>
                      {!isIncludedInGroup ? 'Competitor not included for this stakeholder.' : 
                        mergedDataWithScores.map((rating: any) => (
                          <div key={rating.user.id}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                              <CountryFlag size={30} user={rating.user} disableTooltip />
                              <BodySmall>Not voted yet</BodySmall>
                            </div>
                            <Caption
                              style={{
                                textAlign: 'left',
                                marginLeft: 35,
                                marginTop: 2,
                              }}
                              color={colors.greyDark}
                            >
                              {getUserTitleOrCountryAndName(rating.user)}
                            </Caption>
                          </div>
                        ))
                      }
                    </BodySmall>
                  ) : (
                    // map only the data that same competitor id 
                    mergedDataWithScores
                      .filter((rating: any) => {
                        // Show rating if it has matching competitorId or if it has no competitorId (not voted)
                        return !rating.competitorId || rating.competitorId === comp.id;
                      })
                      .map((rating: any) => {
                        // Check if user hasn't voted (no scores or no competitorId)
                        if (rating.scores.length === 0 || !rating.competitorId) {
                          return (
                            <div key={rating.user.id}>
                              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <CountryFlag size={30} user={rating.user} disableTooltip />
                                <BodySmall>Not voted yet</BodySmall>
                              </div>
                              <Caption
                                style={{
                                  textAlign: 'left',
                                  marginLeft: 35,
                                  marginTop: 2,
                                }}
                                color={colors.greyDark}
                              >
                                {getUserTitleOrCountryAndName(rating.user)}
                              </Caption>
                            </div>
                          );
                        }
                      
                        const formattedScores = rating.scores.map((score: any) => ({ score }));
                        const scoreDistribution = createScoreDistribution(formattedScores);
                        
                        
                        return (
                          <div key={rating.id} >
                            <FlagGraphRow>
                              <div>
                                {rating.user ? (
                                  <CountryFlag size={30} user={rating.user} disableTooltip />
                                ) : (
                                  <CountryFlag
                                    size={30}
                                    country={rating.region as Country}
                                    disableTooltip
                                  />
                                )}
                              </div>
                              <div style={{ height: 30, width: '100%' }}>
                                <StyledLineGraph
                                  values={scoreDistribution}
                                  maxRating={5}
                                />
                              </div>
                            </FlagGraphRow>
                            {!rating?.user ? null : (
                              <Caption
                                style={{
                                  textAlign: 'left',
                                  marginLeft: 35,
                                  marginTop: 2,
                                }}
                                color={colors.greyDark}
                              >
                                {getUserTitleOrCountryAndName(rating?.user)}
                              </Caption>
                            )}
                          </div>
                        );
                      })
                  )}
                </CompetitorGroupWrapper>
              );
            })
          ) : (
            <EmptyStateWrapper>
              <EmptyStateText>
                Outcome not included for this stakeholder.
              </EmptyStateText>
            </EmptyStateWrapper>
          )
        )}
      </Ratings>
      }
    </ModalXCloseCustom>
  );
};