import { BodySmall, LineGraph, ButtonRound } from 'components/shared';
import { colors } from 'constants/index';
import {
  CompetitiveLandscapeRatingFragment,
  CompetitorFragment,
  PostItGroupFragment,
  CompetitiveLandscapeOpportunity,
  User,
  Competitor,
  PostItCardFragment,
} from 'data/graphql/generated';
import getUniqueCombinedUsers from 'utils/getUniqueCombinedUsers';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  CompetitorImage,
  CompetitorTitle,
  CompetitorTitleContainer,
  CompetitorTitleWrapper,
} from './EvaluationStakeHolder';
import { average } from 'utils/average';
import { GraphScroller } from './GraphScroller';
import { throttle } from 'lodash';
import { ScorePill } from 'components/shared/ScorePill';
import { DistributionModalValues } from './DistributionModalCustom';
import { TreatmentIcon } from './TreatmentIcon';
import { isSafari } from 'utils/isSafari';
import { device } from 'utils/breakpoints';

const MOBILE_GRAPHS_CLASSNAME = 'Mobile-graphs-container';

const AllBoxNew = styled.div<{ isApproved: boolean; isLast: boolean }>`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  height: 125px;           /* Set a height for vertical centering */
  width: 125px;            /* Optional: set width if needed */
  flex-direction: column;

  @media ${device.tabletMax} {
    border:none;
  }
`;
const BoxNew = styled.div<{ isApproved: boolean; isLast: boolean }>`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  height: 125px;           /* Set a height for vertical centering */
  width: 125px;            /* Optional: set width if needed */
  flex-direction: column;

  @media ${device.tabletMax} {
    border:none;
  }
   
  @media (max-width: 714px) {
    height: auto;
  }
`;
const AverageScore = styled.div`
color: var(--black, #141427);
text-align: center;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 136.364% */
`;
const VoteCell = styled.div<{type: string}>`
color: ${({ type }) => {
  if (type === 'success') return '#078766';
  if (type === 'warning') return '#FF1E61';
  return '#696975'; // default color if no match
}};
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
`;


const GraphAreaWrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : '125px')};

  background: ${colors.white};
  padding: 10px;
  border-radius: 0px 5px 5px 0px;

  @media (max-width: 714px) {
    border-radius: 0px 0px 5px 5px;
    height: auto;
  }
`;

const AllCompetitorsGraphAreaWrapper = styled.div<{
  isMobile: boolean;
  height?: string;
  average: number;
  isApproved: boolean;
  isLast: boolean;
  isNa: boolean;
}>`
  margin: 0 10px;
  min-width: 125px;
  width: 125px;
  height: 100%;
  flex: ${({ isMobile }) => (isMobile ? 1 : 'none')};
  background: ${({ average, isNa }) => {
    if (isNa) return 'white';
    if (average >= 1 && average < 2) return '#E4CCFF';
    if (average >= 2 && average < 3) return '#EBD9FF';
    if (average >= 3 && average < 4) return '#F2E6FF';
    if (average >= 4 && average <= 5) return '#F8F2FF';
    return 'white'; // default color if no match
  }};

  border: 2px solid transparent; /* Initial transparent border */
  box-sizing: border-box; 

  // border-radius: ${({ isLast }) => (isLast ? '0 10px 10px 0' : '0')};
  // border-top-color: ${({ isApproved }) => isApproved ? '#078766': ''};
  // border-bottom-color: ${({ isApproved }) => isApproved ? '#078766': ''};
  // border-right-color: ${({ isLast, isApproved }) => isApproved && isLast ? '#078766': ''};
  

  @media ${device.tabletMax} {
    flex: 1;

    justify-content: center;
    display: flex;
    border: none;
    width: 100%;
  }

  @media (max-width: 714px) {
    flex: none;
    min-width: auto;
    border-radius: 15px;

    height: 65px;
  }
  @media ${device.mobile} {
    min-width: auto;


    height: 65px;
  }

 
`;

const CompetitorsGraphAreaWrapper = styled.div<{
  isMobile: boolean;
  height?: string;
  average: number;
  isApproved: boolean;
  isLast: boolean;
  isNa: boolean;
}>`
  min-width: 125px;
  width: 125px;
  height: 100%;
  flex: none;
  background: ${({ average, isNa }) => {
    if (isNa) return 'white';
    if (average >= 1 && average < 2) return '#E4CCFF';
    if (average >= 2 && average < 3) return '#EBD9FF';
    if (average >= 3 && average < 4) return '#F2E6FF';
    if (average >= 4 && average <= 5) return '#F8F2FF';
    return '#FFF4F7'; // default color if no match
  }};

  border: 2px solid transparent; /* Initial transparent border */
  box-sizing: border-box; 

  border-radius: ${({ isLast, isApproved }) => (isLast && isApproved ? '0 20px 20px 0' : '0')} !important;
  // border-top-color: ${({ isApproved }) => isApproved ? '#078766': ''} !important;
  // border-bottom-color: ${({ isApproved }) => isApproved ? '#078766': ''} !important;
  // border-right-color: ${({ isLast, isApproved }) => isApproved && isLast ? '#078766': ''} !important;
  
  

  @media ${device.tabletMax} {
    flex: 1;

    justify-content: center;
    display: flex;
    border: none;
    width: 100%;
  }

  @media (max-width: 714px) {
    flex: none;
    min-width: auto;
    border-radius: 15px !important;

    height: 65px;
  }
  @media ${device.mobile} {
    min-width: auto;
    height: 65px;
  }

  &:hover {
    border-color: ${colors.purple};
    cursor: pointer;
  }

  ${({ isApproved, isLast }) =>
    isApproved &&
    `
    &:hover {
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      border-left: 2px solid #078766;
      cursor: pointer;
      border-right: ${!isLast ? '2px solid #078766' : '2px solid transparent'};
    }
   
  `}
`;

const CompetitorGraphsScrollWrapper = styled.div<{ height?: string; isApproved: boolean; isThreat: boolean}>`
  display: flex;
  gap: 2px;
  height: ${({ height }) => (height ? height : '125px')};
  border: 2px solid transparent;
  box-sizing: border-box;
  @media ${device.desktopMin} {
    &:last-child ${CompetitorsGraphAreaWrapper}:last-child {
      ${({ isApproved, isThreat }) =>
        !isApproved && !isThreat &&
        `
        border-radius: 0 0px 05px 0 !important;
        `};
    }
  }

  ${({ isThreat, isApproved }) =>
    isThreat &&
    `
    @media (min-width: 714px) {
      border-color: #C53030; /* Red for Threat */
      border-radius: 0px 20px 20px 0px;
      border-left: 2px solid transparent;
    }
  `}

  ${({ isApproved, isThreat }) =>
    isApproved && !isThreat &&
    `
    @media (min-width: 714px) {
      border-color: #078766; /* Green for Approved */
      border-radius: 0px 20px 20px 0px;
      border-left: 2px solid transparent;
    }
  `}
`;

const StyledLineGraph = styled(LineGraph)`
  height: 100%;
  max-height: 105px;

  .lineGraph__graphWrapper {
    height: 42px;
    margin-bottom: 20px;
  }

  @media (max-width: 714px) {
    .lineGraph__graphWrapper {
      height: 55px;
    }
  }
`;


const MobileGraphs = styled.div`
  width: 240px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
`;

export const EvaluationGraphsCompetitor = ({
  isMobile,
  showDistribution,
  competitors,
  group,
  groups2,
  groupOutcomes,
  cardItems,
  companyUsersData,
  stakeholderDefinitionId,
  overallAverageScore,
  scoreDistribution,
  selectedRegion,
  selectedRegions,
  setDistributionModalRatings,
  rowRefs,
}: {
  isMobile: boolean;
  isLead: boolean;
  showDistribution: boolean;
  competitors: CompetitorFragment[];
  group: PostItGroupFragment;
  groups2: PostItGroupFragment[];
  cardItems: PostItCardFragment[];
  groupOutcomes: any;
  companyUsersData?: User[] | null;
  stakeholderDefinitionId: any;
  competitorId: any;
  selectedRegion: string;
  selectedRegions: string[];
  overallAverageScore: number;
  scoreDistribution: Record<number, number>;
  setDistributionModalRatings({
    title,
    competitor,
    ratings,
  }: DistributionModalValues): void;
  rowRefs?: Record<number, React.RefObject<HTMLDivElement>>;
}) => {
  const mobileGraphsRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState('0px');

  const [scrollContainerMetrics, setScrollContainerMetrics] = useState({
    scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
    offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
    scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
  });

  const normalizeString = (str: string | null | undefined) => {
    return str
      ? str
          .toLowerCase()               // Convert to lowercase
          .replace(/-/g, ' ')          // Replace hyphens with spaces
          .trim()                      // Remove leading/trailing spaces
      : '';                            // Return an empty string if null or undefined
  };
  useLayoutEffect(() => {
    if (showDistribution && isMobile) {
      setScrollContainerMetrics({
        scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
        offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
        scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
      });

      const throttledScrollHandler = throttle(
        () => {
          setScrollContainerMetrics({
            scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
            offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
            scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
          });
        },
        300,
        { trailing: true, leading: false }
      );

      if (mobileGraphsRef.current)
        mobileGraphsRef.current.addEventListener(
          'scroll',
          throttledScrollHandler
        );
      window.addEventListener('resize', throttledScrollHandler);
      const element = mobileGraphsRef.current;

      return () => {
        element?.removeEventListener('scroll', throttledScrollHandler);
        window?.removeEventListener('resize', throttledScrollHandler);
      };
    }
  }, [showDistribution, isMobile]);

  useLayoutEffect(() => {
    //Update row heights to match title rows
    if (rowRefs) {
      setHeight(Number(rowRefs[group.id]?.current?.offsetHeight) + 'px');
    }
  }, [rowRefs, group]);

  const handleScrollerClick = useCallback(
    (scrollDirection: 'left' | 'right') => {
      const mobileGraphs = document.getElementsByClassName(
        MOBILE_GRAPHS_CLASSNAME
      )?.[0];

      mobileGraphs?.scrollBy({
        left: scrollDirection === 'left' ? -240 : 240,
        top: 0,
        behavior: isSafari() ? 'auto' : 'smooth',
      });
    },
    []
  );

  const lastStakeholderId = competitors[competitors.length - 1]?.id;

  
  const competitiveLandscapeOpportunities = group?.competitiveLandscapeOpportunities as CompetitiveLandscapeOpportunity[];

  const selectedOpportunity =competitiveLandscapeOpportunities.find(
    (opportunity) => {
      return (
        opportunity.stakeholderDefinitionId ===
          stakeholderDefinitionId
      );
    }
  );
  const isApproved = selectedOpportunity?.approved;
  const isThreat = selectedOpportunity?.threat;
  
  const averageScores = competitors.map((competitor) => {
    const filteredGroups = groups2.filter(g => g.title === group.title);
    let allRatings2: any = [];
  
    // Collect ratings for each competitor
    filteredGroups.forEach(filteredGroup => {
      const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating || [];
      const ratings = competitiveLandscapeRating2.filter(
        (rating) =>
          rating.stakeholderDefinitionId === stakeholderDefinitionId &&
          rating.competitorId === competitor.id &&
          (
            // Include ratings where user country matches any selected region
            selectedRegions.map(normalizeString).some(region => 
              normalizeString(rating.user?.country) === region
            ) ||
            // Also include global/regional ratings if global is selected
            (selectedRegions.includes('global') && 
              ['global / regional'].includes(normalizeString(rating.user?.country)))
          )
      );
      allRatings2 = [...allRatings2, ...ratings];
    });
  
    // Calculate the average score for the competitor, treating "N/A" as 0
    const scores = allRatings2.map((val: any) => (val.score === 'N/A' ? 0 : val.score));
    const validScores = scores.filter((score: null) => score !== null);
  
    const averageScore = validScores.length
      ? (validScores.reduce((acc: any, score: any) => acc + score, 0) / validScores.length).toFixed(1)
      : 'N/A'; // Return "N/A" if no valid scores found
  
    return averageScore !== 'N/A' ? parseFloat(averageScore) : 'N/A'; // Return number or "N/A"
  });
  
  // Step 2: Calculate the global average of all `averageScore` values
  const validScoresForGlobalAverage = averageScores.filter((score: any) => score !== 'N/A'); // Remove "N/A" values
  
  const globalAverageScore =
    validScoresForGlobalAverage.length > 0
      ? (
          validScoresForGlobalAverage.reduce((acc: any, score: any) => acc + score, 0) /
          validScoresForGlobalAverage.length
        ).toFixed(1)
      : 'N/A'; // Set "N/A" if all scores are "N/A"
 
      const getVotingStatus = (averageScore: string, uniqueRatingUsers: User[], uniqueCombinedUsers: User[], filteredGroups: any[], outcome: string) => {
    if (!isNaN(Number(averageScore))) {
      return {
        type: uniqueRatingUsers.length === uniqueCombinedUsers.length ? 'success' : 'default',
        status: 'voted',
        count: `${uniqueRatingUsers.length}/${uniqueCombinedUsers.length}`,
        outcome: outcome
      };
    } else if (isNaN(Number(averageScore)) && filteredGroups.length > 0) {
      return {
        type: 'warning',
        status: 'not_voted',
        count: `0/${uniqueCombinedUsers.length}`,
        outcome: outcome
      };
    } else {
      return {
        type: 'default',
        status: 'na',
        count: 'N/A',
        outcome: outcome
      };
    }
  };

  if (isMobile) {
    return (
      <>
        {showDistribution ? (
          <div style={{ display: 'flex', gap: 2 }}>
            <GraphScroller
              // Left scroll
              hide={() => {
                const scrolledPastStart =
                  scrollContainerMetrics.scrollLeft === 0;

                return scrolledPastStart;
              }}
              onClick={() => handleScrollerClick('left')}
            />

            <MobileGraphs
              className={MOBILE_GRAPHS_CLASSNAME}
              ref={mobileGraphsRef}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 2,
                }}
              >
                <CompetitorTitleContainer>
                    <CompetitorTitleWrapper>
                        <ButtonRound
                          size={40}
                          iconName="Treatment"
                          level="primary"
                        />
                       
                      <CompetitorTitle>All</CompetitorTitle>
                    </CompetitorTitleWrapper>
                    
                    <CompetitorGraphsScrollWrapper 
                      isThreat={isThreat || false}
                     isApproved={isApproved || false}
                     className="competitorGraphsScrollWrapper"
                     >
              
                    <CompetitorsGraphAreaWrapper
                      isMobile={isMobile}
                      average={globalAverageScore === 'N/A' ? 0 : +globalAverageScore}
                      isApproved={isApproved || false}
                      isLast={false}
                      isNa={globalAverageScore === 'N/A' ? true : false}
                    >
                    
                      <BoxNew
                        isApproved={isApproved || false}
                        isLast={false}
                      >    
                        <AverageScore>{globalAverageScore}</AverageScore>
                      </BoxNew>
                    </CompetitorsGraphAreaWrapper>
                 
                  </CompetitorGraphsScrollWrapper>
                </CompetitorTitleContainer>
                {competitors.map((competitor) => {
                   const isLast = competitor.id === lastStakeholderId;

                   let filteredGroups = groups2.filter(g => g.title === group.title);
                  let allRatings2: CompetitiveLandscapeRatingFragment[] = [];
           
                  // Iterate over each filtered group to collect ratings
                  filteredGroups.forEach(filteredGroup => {
                    const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
                  
                    // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
                    const ratings = competitiveLandscapeRating2.filter(
                       (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId 
                        && rating.competitorId === competitor.id &&
                       (
                        // Include ratings where user country matches any selected region
                        selectedRegions.map(normalizeString).some(region => 
                          normalizeString(rating.user?.country) === region
                        ) ||
                        // Also include global/regional ratings if global is selected
                        (selectedRegions.includes('global') && 
                          ['global / regional'].includes(normalizeString(rating.user?.country)))
                      )
                     );
                  
                    allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
                  });
           
                  const averageScore = average(
                    allRatings2.map((val) => val.score)
                  ).toFixed(1);
           
           
                  const competitiveLandscapeOpportunities = group?.competitiveLandscapeOpportunities as CompetitiveLandscapeOpportunity[];
           
                  const selectedOpportunity =competitiveLandscapeOpportunities.find(
                     (opportunity) => {
                       return (
                         opportunity.stakeholderDefinitionId ===
                           stakeholderDefinitionId
                       );
                     }
                   );
           
                   const isApproved = selectedOpportunity?.approved;
                   const sortedRatings = allRatings2 || [];
                   const { uniqueCombinedUsers, uniqueRatingUsers } = getUniqueCombinedUsers(
                     sortedRatings,
                     companyUsersData || undefined,
                   );

                   filteredGroups = filteredGroups.filter(group => 
                        cardItems.some(card => card.title === group.title && card.include === true )
                    );
              
                    const checkGroups = (competitorId: number, title: string) => {
                      return !groupOutcomes.some((outcome: any) =>
                        outcome.title === title &&
                        outcome.competitors.some((com: any) => com.id === competitorId)
                      );
                    };
              
                    const isNa = checkGroups(competitor.id, group.title);
                     
                     return (
                  <CompetitorTitleContainer
                    key={competitor.id}
                  >
                    <CompetitorTitleWrapper>
                      {competitor.image ? (
                        <CompetitorImage imageURL={competitor.image} />
                      ) : (
                        <TreatmentIcon />
                      )}
                      <CompetitorTitle>{competitor.title}</CompetitorTitle>
                      
                    </CompetitorTitleWrapper>
                    <CompetitorGraphsScrollWrapper 
                      className="competitorGraphsScrollWrapper"
                      isThreat={isThreat || false}
                      isApproved={isApproved || false}
                    >
                    <CompetitorsGraphAreaWrapper
                      key={competitor.id}
                      isMobile={isMobile}
                      average={Number(averageScore)}
                      isApproved={isApproved || false}
                      isLast={isLast}
                      isNa={isNa}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                       <BoxNew onClick={() => {
                          setDistributionModalRatings({
                            competitor: competitor as Competitor,
                            title: competitor.title,
                            ratings: allRatings2,
                            votingInfo: getVotingStatus(
                              averageScore,
                              uniqueRatingUsers,
                              uniqueCombinedUsers,
                              filteredGroups,
                              group.title
                            ),
                            hideRatings: isNa,
                          });
                        }}
                        
                        isApproved={isApproved || false}
                        isLast={isLast}
                        >
                      <AverageScore>
                      {isNa ? '' : !isNaN(Number(averageScore)) ? 
                        averageScore 
                      : isNaN(Number(averageScore)) && filteredGroups.length > 0 ? '-' : ''}
                      </AverageScore>
                      {isNa ? (
                        <VoteCell type={'default'}>
                          N/A
                        </VoteCell>
                      ) : !isNaN(Number(averageScore)) ? (
                        <VoteCell type={uniqueRatingUsers.length === uniqueCombinedUsers.length ? 'success' : 'default'}>
                          {uniqueRatingUsers.length} / {uniqueCombinedUsers.length}
                        </VoteCell>
                      ) : isNaN(Number(averageScore)) && filteredGroups.length > 0 ? (
                        <VoteCell type={'warning'}>
                          (0/{uniqueCombinedUsers.length})
                        </VoteCell>
                      ) : (
                        <VoteCell type={'default'}>
                          N/A
                        </VoteCell>
                      )}
                    </BoxNew>
                      </div>

                      
                    </CompetitorsGraphAreaWrapper>
              </CompetitorGraphsScrollWrapper>
                  </CompetitorTitleContainer>
                )})}

              </div>

              
            </MobileGraphs>
            <GraphScroller
              hide={() => {
                const roomToScroll =
                  scrollContainerMetrics.scrollWidth >=
                  scrollContainerMetrics.offsetWidth;

                const scrolledToEnd =
                  scrollContainerMetrics.scrollLeft ===
                  scrollContainerMetrics.scrollWidth -
                    scrollContainerMetrics.offsetWidth;

                return roomToScroll && scrolledToEnd;
              }}
              right
              onClick={() => handleScrollerClick('right')}
            />
          </div>
        ) : (
          <GraphAreaWrapper>
            <ScorePill value={overallAverageScore} />

            <StyledLineGraph
              showWeakStrongLabel={!isMobile}
              values={scoreDistribution}
              maxRating={5}
            />
          </GraphAreaWrapper>
        )}
      </>
    );
  }

  return showDistribution ? (
    <CompetitorGraphsScrollWrapper height={height}
    isThreat={isThreat || false}
    isApproved={isApproved || false}>

      <AllCompetitorsGraphAreaWrapper 
            isMobile={isMobile} 
            average={Number(globalAverageScore)}
            isApproved={isApproved || false}
            isLast={false}
            isNa={false}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <AllBoxNew
              isApproved={isApproved || false}
            isLast={false}
          >    
          <AverageScore>{globalAverageScore}</AverageScore>
        </AllBoxNew>
        </div>
      </AllCompetitorsGraphAreaWrapper>            
      {competitors.map((competitor) => {
        const isLast = competitor.id === lastStakeholderId;

        let filteredGroups = groups2.filter((g) => {
          return (
            g.title === group.title  // Ensure safe access to competitiveLandscapeRating
          );
        });
       let allRatings2: CompetitiveLandscapeRatingFragment[] = [];

       // Iterate over each filtered group to collect ratings
       filteredGroups.forEach(filteredGroup => {
         const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
       
         // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
         const ratings = competitiveLandscapeRating2.filter(
            (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId 
            && rating.competitorId === competitor.id &&
            (
              // Include ratings where user country matches any selected region
              selectedRegions.map(normalizeString).some(region => 
                normalizeString(rating.user?.country) === region
              ) ||
              // Also include global/regional ratings if global is selected
              (selectedRegions.includes('global') && 
                ['global / regional'].includes(normalizeString(rating.user?.country)))
            )
          );
       
         allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
       });

       const averageScore = average(
         allRatings2.map((val) => val.score)
       ).toFixed(1);


       const competitiveLandscapeOpportunities = group?.competitiveLandscapeOpportunities as CompetitiveLandscapeOpportunity[];

       const selectedOpportunity =competitiveLandscapeOpportunities.find(
          (opportunity) => {
            return (
              opportunity.stakeholderDefinitionId ===
                stakeholderDefinitionId
            );
          }
        );

        const isApproved = selectedOpportunity?.approved;
        const sortedRatings = allRatings2 || [];
        const { uniqueCombinedUsers, uniqueRatingUsers } = getUniqueCombinedUsers(
          sortedRatings,
          companyUsersData || undefined,
        );

      filteredGroups = filteredGroups.filter(group => 
          cardItems.some(card => card.title === group.title && card.include === true )
      );

      const checkGroups = (competitorId: number, title: string) => {
        return !groupOutcomes.some((outcome: any) =>
          outcome.title === title &&
          outcome.competitors.some((com: any) => com.id === competitorId)
        );
      };

      const isNa = checkGroups(competitor.id, group.title);
   
        return (
          <CompetitorsGraphAreaWrapper 
            key={competitor.id} 
            isMobile={isMobile} 
            average={Number(averageScore)}
            isApproved={isApproved || false}
            isLast={isLast}
            isNa={isNa}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <BoxNew onClick={() => {
                      // if (isNa) return;
                          setDistributionModalRatings({
                            competitor: competitor as Competitor,
                            title: competitor.title,
                            ratings: allRatings2,
                            votingInfo: getVotingStatus(
                              averageScore,
                              uniqueRatingUsers,
                              uniqueCombinedUsers,
                              filteredGroups,
                              group.title
                            ),
                            hideRatings: isNa,
                          });
                        }}
                        
                        isApproved={isApproved || false}
                        isLast={isLast}
                        >
                      <AverageScore>
                      {isNa ? '' : !isNaN(Number(averageScore)) ? 
                        averageScore 
                      : isNaN(Number(averageScore)) && filteredGroups.length > 0 ? '-' : ''}
                      </AverageScore>
                      {isNa ? (
                        <VoteCell type={'default'}>
                          N/A
                        </VoteCell>
                      ) : !isNaN(Number(averageScore)) ? (
                        <VoteCell type={uniqueRatingUsers.length === uniqueCombinedUsers.length ? 'success' : 'default'}>
                          {uniqueRatingUsers.length} / {uniqueCombinedUsers.length}
                        </VoteCell>
                      ) : isNaN(Number(averageScore)) && filteredGroups.length > 0 ? (
                        <VoteCell type={'warning'}>
                          (0/{uniqueCombinedUsers.length})
                        </VoteCell>
                      ) : (
                        <VoteCell type={'default'}>
                          N/A
                        </VoteCell>
                      )}
                    </BoxNew>
              {/* <ScorePill value={+averageScore}>
                {averageScore ? (
                  <>
                    <BodySmall
                      style={{ marginRight: 3 }}
                      color={colors.greyDark}
                    >
                      Av.
                    </BodySmall>
                    <BodySmall color={colors.black}>
                      {'' + averageScore}
                    </BodySmall>
                  </>
                ) : (
                  <BodySmall color={colors.greyDark}>No ratings</BodySmall>
                )}
              </ScorePill> */}
              {/* {!!isLead && (
                <ChartButton
                  onClick={() => {
                    setDistributionModalRatings({
                      title: competitor.title,
                      ratings: allRatings2,
                    });
                  }}
                />
              )} */}
            </div>

            {/* <StyledCompetitorsLineGraph
              showWeakStrongLabel={false}
              values={scoreDistribution}
              maxRating={5}
            /> */}
          </CompetitorsGraphAreaWrapper>
        );
      })}
      {/* Remaining White space */}
      {/* <div
        className="graph__white-space"
        style={{
          width: '100%',
          height: '100%',
          flex: 1,
          borderRadius: '0 5px 5px 0',
          background: colors.white,
        }}
      /> */}
    </CompetitorGraphsScrollWrapper>
  ) : (
    <GraphAreaWrapper height={height}>
      {isMobile && (
        <ScorePill value={overallAverageScore}>
          {overallAverageScore ? (
            <>
              <BodySmall style={{ marginRight: 3 }} color={colors.greyDark}>
                Av.
              </BodySmall>
              <BodySmall color={colors.black}>
                {'' + overallAverageScore}
              </BodySmall>
            </>
          ) : (
            <BodySmall color={colors.greyDark}>No ratings</BodySmall>
          )}
        </ScorePill>
      )}
      <StyledLineGraph
        showWeakStrongLabel={!isMobile}
        values={scoreDistribution}
        maxRating={5}
      />
    </GraphAreaWrapper>
  );
};
