import React from 'react';
import styled from 'styled-components';
import { colorsv2 } from 'constants/index';
import { ApolloError } from '@apollo/client';

import ErrorPage from 'components/ErrorPage';
import { Loading } from 'components/Loading';

interface Props {
  bg?: string;
  errors: (ApolloError | undefined)[];
  isLoading: boolean;
  dataMissing: boolean;
}

const LoadingWrapper = styled.div<{ bg?: string }>`
  background: ${({ bg }) => bg === 'dark' ? colorsv2.bg.surfaceBase2 : ''};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 150px;
  height: 100vh;
  width: 100%;
`;

export const ErrorWrapper: React.FC<Props> = ({
  bg,
  errors,
  isLoading,
  dataMissing,
  children,
}) => {
  if (isLoading) {
    return (
      <LoadingWrapper bg={bg}>
        <Loading 
          color={bg === 'dark' ? 'white' : 'purple'}
          textColor={bg === 'dark' ? colorsv2.textInverted.default : colorsv2.text.default} 
        />
      </LoadingWrapper>
    )
  }

  const permissionError = errors.some((err) =>
    err?.message?.includes('not authorized')
  );

  if (permissionError) return <ErrorPage errorType="unpermitted" />;
  if (dataMissing) return <ErrorPage errorType="generic" />;

  return <>{children}</>;
};
