import { Button } from 'components/Button';
import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import React from 'react';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { BodySmall } from './TextStyles';

const Wrapper = styled.div`
  @media ${device.tabletMin} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Email = styled(BodySmall)`
  color: ${colors.greyDark};

  @media ${device.tabletMin} {
    margin-right: 5px;
  }
`;

const InviteButton = styled(Button)`
  cursor: pointer;
  height: 20px;
  padding: 0;
  margin-right: 20px;
`;

interface Props {
  verifiedEmail: boolean;
  displayStatus: boolean;
  resendInvite: () => Promise<boolean | void>;
  email: string;
}

export default function EmailAndInviteButton({
  verifiedEmail,
  displayStatus,
  resendInvite,
  email,
}: Props) {
  const [{ user: loggedInUser }] = useAuthContext();
  const [reinviteSent, setReinviteSent] = React.useState<boolean>(false);

  async function handleResendInvite() {
    const successfulReinvite = await resendInvite();
    if (successfulReinvite) setReinviteSent(true);
  }

  return (
    <Wrapper>
      <Email>{email}</Email>
      {!verifiedEmail &&
        displayStatus &&
        loggedInUser?.role !== 'CONTRIBUTOR' && (
          <>
            {reinviteSent ? (
              <BodySmall>Invite sent</BodySmall>
            ) : (
              <InviteButton text="Resend invite" onClick={handleResendInvite} />
            )}
          </>
        )}
    </Wrapper>
  );
}
