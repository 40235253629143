import { globalContributor } from 'constants/index';
import { Role, User } from 'data/graphql/generated';
import { startCase } from './startCase';

export const getUserTitleOrCountryAndName = (
  user?: Pick<User, 'role' | 'title' | 'country' | 'name'>,
  showName: boolean = true
) => {
  if (!user) return '';
  return `${
    user?.role === Role.Lead
      ? user?.title || startCase(Role.Lead)
      : user?.role === Role.Contributor && user.country === globalContributor
      ? user?.title || startCase(user.country || '')
      : startCase(user.country || '')
  }${showName ? ` • ${user.name}` : ''}`;
};
