import { CustomCountriesDropdownToggle } from 'components/PatientFlow/CustomCountriesDropdownToggle';
import { zIndex } from 'constants/zIndex';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components/macro';
import { Country, CountryGlobal } from 'types';
import { device } from 'utils/breakpoints';
import { CountriesDropdown } from './CountriesDropdown';
import { IconContainer } from './Icon';
import { ButtonLabel } from './TextStyles';

export const DropdownToggle = styled(CustomCountriesDropdownToggle)<{
  multiSelect?: boolean;
}>`
  background: ${colors.white};
  border: 1px solid ${colors.greyDark};

  ${ButtonLabel} {
    line-height: 15px;
  }

  &:hover {
    background-color: ${colors.white};
  }

  ${ButtonLabel}, ${IconContainer} {
    color: ${colors.black};
  }

  .chevron {
    margin: -5px;
  }
`;

export const Dropdown = styled(CountriesDropdown)<{
  multiSelect?: boolean;
}>`
  top: calc(100% + 1px);
  z-index: ${zIndex.countriesDropdownSmall};
  width: ${({ multiSelect }) => (multiSelect ? '242px' : '217px')};
  right: 0;
  left: unset;

  @media ${device.desktopMin} {
    left: -1px;
  }
`;

interface Props {
  country?: CountryGlobal;
  active?: boolean;
  onClick?(e?: React.MouseEvent<HTMLDivElement>): void;
  viewOnly?: boolean;
  hideCountryName?: boolean;
  largeIcon?: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  showDropdown: boolean;
  setCountry?: (country: CountryGlobal) => void;
  allCountries: Country[];
  className?: string;
  globalOptionText?: string;
  dropDownRef?: React.MutableRefObject<HTMLDivElement | null>;
  multiSelect?: boolean;
  selectedRegions?: CountryGlobal[];
}

export function CustomCountriesDropdownSmall({
  country,
  active,
  onClick,
  viewOnly,
  className,
  hideCountryName,
  largeIcon,
  showDropdown,
  setShowDropdown,
  setCountry,
  allCountries,
  globalOptionText,
  dropDownRef,
  multiSelect,
  selectedRegions,
}: Props) {
  return (
    <DropdownToggle
      country={country}
      allCountries={allCountries}
      active={active}
      onClick={onClick}
      viewOnly={viewOnly}
      hideCountryName={hideCountryName}
      largeIcon={largeIcon}
      className={className}
      globalOptionText={globalOptionText}
      multiSelect={multiSelect}
      selectedRegions={selectedRegions}
    >
      <Dropdown
        globalOptionText={globalOptionText}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        activeCountry={country}
        setCountry={setCountry}
        allCountries={allCountries}
        innerRef={dropDownRef}
        multiSelect={multiSelect}
        selectedRegions={selectedRegions}
      />
    </DropdownToggle>
  );
}
