import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { colorsv2 } from 'constants/index';
import { StepButton, Subtitle1, Subtitle2 } from './shared';
import useDesktop from 'hooks/useDesktop';
import { HelpIcon } from 'components/HelpIcon';
import { useDrugsQuery } from 'data/graphql/generated';
import { checkIfFullNmblr } from 'containers/Strategies';
import * as themeUtils from 'utils/themes';
import { device } from 'utils/breakpoints';

const HelpIconContainer = styled.div`
  position: absolute;
  right: -40px;
`;

const StyledLeftNavButton = styled(StepButton)``;

const TitleWrapper = styled(Subtitle2)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledRightNavButton = styled(StepButton)``;

export const CurrentPageText = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  margin-top: 1px;

  @media ${device.mobile} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  cursor: default;
  display: flex;
  gap: 5px;
  position: relative;

  @media screen and (max-width: 944px) {
    ${CurrentPageText} {
      order: -1;
      margin-right: 10px;
    }
    ${StyledLeftNavButton} {
      margin-left: auto;
    }
    ${StyledRightNavButton} {
      margin-left: 5px;
    }
  }
`;

const StepNumber = styled(Subtitle1)`
  color: ${colorsv2.textInverted.weak};
  margin-right: 4px;
`;

interface Props {
  title?: string;
  hideArrows?: boolean;
  className?: string;
  isAdmin?: boolean;
}

export const PageNav: React.FC<Props> = ({
  title,
  hideArrows,
  children,
  className,
  isAdmin,
}) => {

  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const { pathname } = useLocation();

  const { data: drugData, loading } = useDrugsQuery({
    variables: {
      where: {
        id: Number(drugId || 0),
      },
    },
    fetchPolicy: 'network-only',
  });

  const possibleDrugs = drugData?.drugs?.items;
  const miniFeatureSet = useMemo(() => {
    if (possibleDrugs) {
      return possibleDrugs.filter(i => i.id === Number(drugId)).flatMap((p: any) =>
        p.ProductOnDrugs?.flatMap((f: any) =>
          f.Product?.features?.map((g: any) => g.featureSet)
        )
      );
    }
    return [];
  }, [drugId, possibleDrugs]);
  const isFullNmblr = checkIfFullNmblr(miniFeatureSet);
  const isFullCache = localStorage?.getItem('@nmblr/isFullNmblr');

  const feature = (
    pathname.split('/').find((p) => p.includes('_')) || ''
  ).replace('_', '.');

  const navTitle: any = themeUtils.getThemeTool(feature)?.name || title || '';

  const prevTool = isFullNmblr 
    ? themeUtils.getPrevThemeTool(feature)
    : themeUtils.getPrevBlockTool(feature);

  const prevToolUrl = prevTool?.key ? `/d/${drugId}/strategy/${strategyId}/${prevTool.key.replace('.', '_')}` : '';

  const nextTool = isFullNmblr
    ? themeUtils.getNextThemeTool(feature)
    : themeUtils.getNextBlockTool(feature);

  const nextToolUrl = nextTool?.key ? `/d/${drugId}/strategy/${strategyId}/${nextTool.key.replace('.', '_')}` : '';

  const history = useHistory();
  const isDesktop = useDesktop();

  useEffect(() => {
    if (loading) {
      return;
    }

    // cache full nmblr state to avoid re-rendering
    const isFullCache: any = localStorage?.getItem('@nmblr/isFullNmblr');
    if (isFullCache !== isFullNmblr) {
      localStorage
        ?.setItem(
          '@nmblr/isFullNmblr', 
          isFullNmblr ? 'true' : 'false'
        );
    }
  }, [isFullNmblr, loading]);

  return navTitle ? (
    <Wrapper className={className}>
      {!hideArrows ? (
        <StyledLeftNavButton
          disabled={!prevToolUrl}
          onClick={() => {
            prevToolUrl && history.push(prevToolUrl);
          }}
          tooltip={prevToolUrl ? (!isDesktop ? '' : 'Previous step: ' + prevTool.name) : ''}
          direction="l"
        />
      ) : null}
      
      <CurrentPageText>
        {feature && isFullCache === 'true' && <StepNumber>{feature}</StepNumber>}
        
        {navTitle?.split?.('||')?.map?.((val: any, index: number) => {
          if (index === 1) {
            return (
              <TitleWrapper key={index} color={colorsv2.textInverted.default}>
                {val}
              </TitleWrapper>
            );
          }

          return (
            <TitleWrapper key={index} color={colorsv2.textInverted.default}>
              {val}
            </TitleWrapper>
          );
        })}
      </CurrentPageText>

      {!hideArrows ? (
        <StyledRightNavButton
          disabled={!nextToolUrl}
          onClick={() => {
            nextToolUrl && history.push(nextToolUrl);
          }}
          tooltip={nextToolUrl? (!isDesktop ? '' : 'Next step: ' + nextTool.name) : ''}
          direction="r"
        />  
      ) : null}

      {children}

      {isDesktop && !isAdmin ? (
        <HelpIconContainer>
          <HelpIcon />
        </HelpIconContainer>
      ) : null}
    </Wrapper>
  ) : null;
};
