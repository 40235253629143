import {
  BodySmall,
  Caption,
  CountryFlag,
  LineGraph,
  Subtitle1,
} from 'components/shared';
import { colors, globalContributor } from 'constants/index';
import { Country } from 'types';
import { createScoreDistribution } from './Evaluation';
import styled from 'styled-components';
import { ModalXClose } from 'components/ModalXClose';
import { Maybe, Role, User } from 'data/graphql/generated';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';

const FlagGraphRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;
`;

const Ratings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const StyledLineGraph = styled(LineGraph)`
  padding: 0;

  > .lineGraph__numberMarkers {
    top: 15px;
    left: 0;
    width: 100%;
    padding-bottom: 15px;
  }

  > .lineGraph__graphWrapper {
    height: 15px;
  }
`;

export interface DistributionModalProps {
  visible: boolean;
  handleClose(): void;
  companyUsersData?: User[] | null;

  title?: string;
  subtitle?: string;
  ratings?: {
    id: number;
    region?: Maybe<string>;
    score: number;
    user?: Pick<User, 'id' | 'country' | 'role' | 'name' | 'title'> | null;
  }[];
}

export type DistributionModalValues = Pick<
  DistributionModalProps,
  'subtitle' | 'ratings' | 'title'
>;

export const DistributionModal: React.FC<DistributionModalProps> = ({
  visible,
  handleClose,
  title,
  subtitle,
  ratings,
  companyUsersData,
}) => {
  const sortedRatings = ratings || [];

  const ratingsUsers =
    sortedRatings?.map((rating) => rating.user?.id).filter((val) => !!val) ||
    [];

  const unratedUsers =
    companyUsersData
      ?.filter((user) => !ratingsUsers.includes(user?.id))
      .map((val) => ({
        user: val,
      })) || [];

  const combinedUsers = [...sortedRatings, ...unratedUsers].sort((a, b) => {
    if (!a.user?.country || !b.user?.country) {
      return 0;
    }

    // Sort by role
    if (a.user.role === Role.Lead && b.user.role !== Role.Lead) {
      return 1;
    }
    if (b.user.role === Role.Lead && a.user.role !== Role.Lead) {
      return -1;
    }

    // Sort by title
    // Some Leads might have no title. Default should be 'Lead'
    const titleA =
      a.user.role === Role.Lead && !a?.user.title
        ? Role.Lead.toUpperCase()
        : a?.user.title?.toUpperCase();
    const titleB =
      b.user.role === Role.Lead && !b?.user.title
        ? Role.Lead.toUpperCase()
        : b?.user.title?.toUpperCase();

    if (!!titleA && !!titleB) {
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
    }

    if (!titleA && !!titleB) {
      return -1;
    }
    if (!titleB && !!titleA) {
      return 1;
    }

    const countryA = a.user.country.toUpperCase();
    const countryB = b.user.country.toUpperCase();

    // Put global contributor below contributors
    if (
      countryA === globalContributor.toUpperCase() &&
      countryB !== globalContributor.toUpperCase()
    ) {
      return 1;
    }
    if (
      countryB === globalContributor.toUpperCase() &&
      countryA !== globalContributor.toUpperCase()
    ) {
      return -1;
    }

    // Leads have countries too so sort alphabetical for only contributors
    if (a.user.role !== Role.Lead) {
      if (countryA < countryB) {
        return -1;
      }
      if (countryA > countryB) {
        return 1;
      }
    }
    const nameA = a.user.name.toUpperCase();
    const nameB = b.user.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  return (
    <ModalXClose handleClose={handleClose} visible={visible}>
      <Subtitle1>{title}</Subtitle1>
      {subtitle && <Caption color={colors.greyDark}>{subtitle}</Caption>}
      <Ratings>
        {combinedUsers.map((rating) => {
          // Unrated user
          if (!('id' in rating)) {
            return (
              <div key={rating.user.id}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <CountryFlag size={30} user={rating.user} disableTooltip />
                  <BodySmall>Not voted yet</BodySmall>
                </div>

                <Caption
                  style={{
                    textAlign: 'left',
                    marginLeft: 35,
                    marginTop: 2,
                  }}
                  color={colors.greyDark}
                >
                  {getUserTitleOrCountryAndName(rating.user)}
                </Caption>
              </div>
            );
          }
          // Rated users
          const scoreDistribution = createScoreDistribution([rating]);
          return (
            <div key={rating.id}>
              <FlagGraphRow>
                <div>
                  {rating.user ? (
                    <CountryFlag size={30} user={rating.user} disableTooltip />
                  ) : (
                    <CountryFlag
                      size={30}
                      country={rating.region as Country}
                      disableTooltip
                    />
                  )}
                </div>
                <div style={{ height: 30, width: '100%' }}>
                  <StyledLineGraph
                    values={scoreDistribution}
                    maxRating={5}
                    showPeakLabel
                  />
                </div>
              </FlagGraphRow>
              {!rating?.user ? null : (
                <Caption
                  style={{
                    textAlign: 'left',
                    marginLeft: 35,
                    marginTop: 2,
                  }}
                  color={colors.greyDark}
                >
                  {getUserTitleOrCountryAndName(rating?.user)}
                </Caption>
              )}
            </div>
          );
        })}
      </Ratings>
    </ModalXClose>
  );
};