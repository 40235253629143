/*
  adding a collaborator
     - new email address? --> create a user linked to this drug and strategy, but dont send email (userCreate)
     - existing email? --> attach user to this strategy (userUpdate)
        (collabs page creates the accounts, these become the strategy.users)

  removing a collaborator
        - new (unverified account) - delete the account (userDelete)
        - existing email - detach user from strategy (userUpdate)

     no emails sent until strategy is published - add logic to strategyPublish resovler

    - strategyPublish
        - user verified? permissions email (sendUpdatePermissionsEmail)
        - user not verified ? invite email (userInviteResend)

     - userInviteCreate creates a user and sends invite email
     - userCreate creates a user (but no invite)
     - userInviteResend sends the invite email to a user
     - strategyPublish sets isDraft false, sends email to collaborators
  */

import { ButtonPill } from 'components/shared/ButtonPill';
import StrategyCreationPage from 'containers/StrategyCreationPage';
import {
  useCompanyUsersQuery,
  User,
  useStrategyUpdateMutation,
  useStrategyPublishMutation,
  useStrategyWithDrugQuery,
  StrategyFragment,
} from 'data/graphql/generated';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import CollaboratorsTable from 'components/shared/CollaboratorsTable';
import { EditCollaboratorModal } from './EditCollaboratorModal';
import { DeleteCollaboratorModal } from './DeleteCollaboratorModal';
import ErrorPage from 'components/ErrorPage';
import { AddCollaboratorModal } from 'components/shared/AddCollaboratorModal';
import lodash from 'lodash';

export const AddCollaboratorButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <ButtonPill
    text="Add collaborator"
    iconName="Plus"
    iconSize={16}
    onClick={onClick}
    level="outline-brand"
  />
);

const CollaboratorComponents = styled.div`
`;

const StrategyCollaboratorsCreate = () => {
  const [, setFailedInvites] = useState(false);

  const history = useHistory();
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const [strategyUpdate] = useStrategyUpdateMutation();
  const [strategyPublish] = useStrategyPublishMutation();
  
  const [values,] = React.useState<StrategyFragment | undefined>(
    undefined
  );
  const [users, setUsers] = React.useState<User[]>([]);

  const [usersForDropdown, setUsersForDropdown] = useState<
    (User | null | undefined)[]
  >([]);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const { 
    data: strategy, 
    refetch, 
    loading, 
    error 
  } = useStrategyWithDrugQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'no-cache',
  });

  //TODO - This needs to include drug users
  const companyId = strategy?.strategy?.drug?.companyId;

  const { refetch: refetchUsersForDropdown } = useCompanyUsersQuery({});

  const currentStrategyUsers = strategy?.strategy?.users?.flat() || [];
  const currentDrugUsers = strategy?.strategy?.drug?.users?.flat() || [];

  const currentStrategyAndDrugUniqueUsers = lodash.uniqBy(
    [...currentStrategyUsers, ...currentDrugUsers],
    'id'
  );

  const closeModal = () => {
    setAddModalOpen(false);
    setEditModalOpen(false);
    setSelectedUser(null);
  }

  const updateStrategy = async () => {
    if (!values) return;

    try {
      await strategyUpdate({
        variables: {
          id: +strategyId,
          data: {
            startYear: values.startYear,
            endYear: values.endYear,
            indication: values.indication,
            strategicProblem: values.strategicProblem,
            strategicQuestion: values.strategicQuestion,
            winningAspiration: values.winningAspiration,
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  const handleSave = async () => {
    updateStrategy();
    history.push('/');
  };

  const handlePublish = async () => {
    try {
      setFailedInvites(false);
      await strategyPublish({ variables: { id: +strategyId } });
      history.push(`/`);
    } catch (err) {
      console.error(err);
      if (err instanceof Error && err.message.includes('inviteUser')) {
        setFailedInvites(true);
        history.push(`/`);
      }
    }
  }

  useEffect(() => {
    const data = strategy?.strategy;

    if (data) {
      data?.users && setUsers(data.users);
    }
  }, [strategy]);

  useEffect(() => {
    //companyId has to be retrieved asynchronously
    //so it's being used to get usersForDropdown via this useEffect

    if (typeof companyId !== 'undefined') {
      (async () => {
        try {
          const response = await refetchUsersForDropdown({
            id: +companyId,
            strategyId: +strategyId,
          });

          if (response?.data?.companyUsers?.items) {
            setUsersForDropdown(response.data.companyUsers?.items);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [companyId, refetchUsersForDropdown, strategyId, addModalOpen]);

  useEffect(() => {
    if (!editModalOpen && !deleteModalOpen && !addModalOpen) {
      setSelectedUser(null);
      //refetch when closing modal
      refetch();
    }
  }, [editModalOpen, deleteModalOpen, addModalOpen, refetch]);

  if (error && !loading) return <ErrorPage errorType="generic" />;
  if (!strategy?.strategy && !loading) return <ErrorPage errorType="unpermitted" />;
  
  return (
    <>
      {addModalOpen && (
        <AddCollaboratorModal
          handleClose={closeModal}
          visible
          usersForDropdown={usersForDropdown}
          usersToExclude={currentStrategyAndDrugUniqueUsers}
          excludedUsersMessage="Already in strategy"
          heading="Add collaborator"
          message="Choose who you want to invite to work on this strategy."
          strategyId={+strategyId}
          drugId={+drugId}
          submitBtnMessage="Add"
        />
      )}

      {editModalOpen && (
        <EditCollaboratorModal
          modalOpen={editModalOpen}
          closeModal={closeModal}
          type="edit"
          user={selectedUser}
        />
      )}

      <DeleteCollaboratorModal
        name={selectedUser?.name}
        collabUserId={selectedUser?.id}
        removeStrategyId={+strategyId}
        modalOpen={deleteModalOpen}
        setModalOpen={(state) => {
          setDeleteModalOpen(state);
        }}
        message="This person won’t be able to access the strategy."
      />
      
      <StrategyCreationPage
        isLoading={loading}
        title="Collaborators"
        subtext="Choose who you want to collaborate with. You can add more people later."
        handleSave={handleSave}
        nextButtonDisabled={false}
        titleRightActions={(
          <AddCollaboratorButton onClick={() => setAddModalOpen(true)} />
        )}
        footerLeftButtonHandler={() => (
          history.push(`/d/${drugId}/strategy/${strategyId}/create/period`)
        )}
        footerNextButtonHandler={handlePublish}
      >
        <CollaboratorComponents>
          <CollaboratorsTable
            editUser={(user) => {
              setSelectedUser(user);
              setEditModalOpen(true);
            }}
            deleteUser={(user) => {
              setSelectedUser(user);
              setDeleteModalOpen(true);
            }}
            users={users}
            displayVerifiedStatus={false}
            displayRoleUserName={false}
            drugId={+drugId}
            companyId={strategy?.strategy?.drug?.companyId}
            strategyId={+strategyId}
          />
        </CollaboratorComponents>
      </StrategyCreationPage>
    </>
  );
};

export default StrategyCollaboratorsCreate;
