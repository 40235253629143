import { Button } from 'components/Button';
import { Navbar } from 'components/Navbar';
import { BodySmall, ButtonPill, Heading3 } from 'components/shared';
import { colorsv2 } from 'constants/index';
import useMobile from 'hooks/useMobile';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { Page } from 'components/Page';
import { Loading } from 'components/Loading';

interface Props {
  title: string;
  subtext?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  nextButtonDisabled: boolean;
  handleSave: () => void;
  titleRightActions?: React.ReactNode;
  footerLeftButtonColor?: string;
  footerLeftButtonHandler: () => void;
  footerNextButtonHandler: () => void | Promise<any>;
}

export const PageWrapper = styled(Page)`
  height: 100vh;
  padding-top: 80px;
  overflow: hidden;

  @media ${device.tabletMax} {
    padding-top: 48px;
    padding-bottom: 0px;

    > div {
      width: 100%; 
    }
  }
`;

export const ModalStrategy = styled.div`
  background: #fff;
  border-radius: 8px;
  height: 100%;

  @media ${device.tabletMax} {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 48px);
    width: 100%;

    > div:first-child {
      height: 100%; 
    }
  }
`;

export const ModalStrategyTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;

  > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 4px; 
    width: 100%;
  }

  @media ${device.mobile} {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
`;

export const ModalStrategyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: scroll;
  padding: 32px;

  @media ${device.tabletMax} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const ModalStrategyFooter = styled.div`
  border-top: 1px solid ${colorsv2.border.weak};
  display: flex;
  justify-content: space-between;
  padding: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 16px;
  }
`;

const StepTitle = styled(Heading3)`
`;

const Description = styled(BodySmall)`
  max-width: 693px;
`;

const ButtonsLeft = styled.div`
  display: flex;
  gap: 8px;

  @media ${device.mobile} {
    width: 100%;

    > div {
      width: 100%;
    }
  }
`;

const ButtonsRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  left: 0;
`;

const DefaultButton = styled(Button)<{ color?: string, loading?: boolean }>`
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};

  span {
    color: ${({ color }) => color || colorsv2.text.default};
  }
`;

const SaveButton = styled(DefaultButton)<{ loading?: boolean }>`
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
`;

const NextButton = styled(ButtonPill)`
  width: 145px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StrategyCreationPage: React.FC<Props> = ({
  children,
  title,
  subtext,
  isLoading = false,
  nextButtonDisabled,
  handleSave,
  titleRightActions,
  footerLeftButtonColor,
  footerLeftButtonHandler,
  footerNextButtonHandler,
}) => {
  const [loading, setLoading] = useState<string | null>(null);

  const isMobile = useMobile();
  const history = useHistory();
  const isPeriodPage = history.location.pathname.includes('period');
  const isCollaboratorsPage = history.location.pathname.includes(
    'collaborators'
  );

  const footerLeftButtonText = isPeriodPage
    ? isMobile
      ? 'Discard'
      : 'Discard'
    : 'Back';

  const footerNextButtonText = isMobile ? 'Next' : (isCollaboratorsPage ? 'Publish' : 'Next');

  return (
    <PageWrapper bg="wave" fixed={true}>
      <Navbar
        disableSecondary
        exclude={['drugLink', 'filesLink', 'strategyLink']}
      />

      {isLoading ? (
        <>
          <Loading 
            text="Loading..." 
            color="white"
            textColor="#FFF"
            style={{
              marginTop: 120
            }}
          />
        </>
      ) : (
        <ModalStrategy>
          <ModalStrategyContent>
            <ModalStrategyTitle>
              <div>
                <TitleContainer>
                  <StepTitle>{title}</StepTitle>
                </TitleContainer>

                {subtext && (
                  <TitleContainer>
                    <Description>{subtext}</Description>
                  </TitleContainer>
                )}
              </div>

              {titleRightActions && (
                <div>
                  {titleRightActions}
                </div>
              )}
            </ModalStrategyTitle>

            {children}
          </ModalStrategyContent>

          <ModalStrategyFooter>
            {isMobile ? (
              <>
                <ButtonsLeft>
                  <NextButton
                    loading={loading === 'next'}
                    text={footerNextButtonText}
                    disabled={nextButtonDisabled}
                    onClick={async () => {
                      setLoading('next');
                      await footerNextButtonHandler();
                      setLoading(null);
                    }}
                    className="cypress-strategy-next"
                  />
                </ButtonsLeft>

                <ButtonsRight>
                  <DefaultButton
                    color={footerLeftButtonColor}
                    text={footerLeftButtonText}
                    onClick={footerLeftButtonHandler}
                  />

                  <SaveButton 
                    text="Save and exit" 
                    onClick={handleSave} 
                  />
                </ButtonsRight>
              </>
            ) : (
              <>
                <ButtonsLeft>
                  <DefaultButton
                    loading={loading === 'leftButton'}
                    color={footerLeftButtonColor}
                    text={footerLeftButtonText}
                    onClick={async () => {
                      setLoading('leftButton');
                      await footerLeftButtonHandler();
                      setLoading(null);
                    }}
                  />
                </ButtonsLeft>

                <ButtonsRight>
                  <SaveButton 
                    loading={loading === 'save'}
                    text="Save and exit" 
                    onClick={async () => {
                      setLoading('save');
                      await handleSave();
                      setLoading(null);
                    }} 
                  />

                  <NextButton
                    loading={loading === 'next'}
                    text={footerNextButtonText}
                    disabled={nextButtonDisabled}
                    onClick={async () => {
                      setLoading('next');
                      await footerNextButtonHandler();
                      setLoading(null);
                    }}
                    className="cypress-strategy-next"
                  />
                </ButtonsRight>
              </>
            )}
          </ModalStrategyFooter>
        </ModalStrategy>
      )}
    </PageWrapper>
  );
};

export default StrategyCreationPage;
