import { useState } from 'react';
import {
  Router,
  Switch,
  Route as ReactRouterRoute,
  Redirect,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useAuthContext } from 'contexts/AuthContext';
import { KeyStakeholders } from 'containers/KeyStakeholders';
import PatientJourney from 'containers/PatientJourney';
import { Admin } from 'containers/Admin';
import { Users } from 'containers/Users';
import { Products } from 'containers/Products';
import { AdminCompany } from 'containers/AdminCompany';
import { Drugs } from 'containers/Strategies';
import { Strategy } from 'containers/Strategy';
import { Welcome } from 'containers/Welcome';
import { StyleGuide } from 'containers/StyleGuide';
import { People } from 'containers/People';
import { Files } from 'containers/Files';
import { KeyInsights } from 'containers/KeyInsights';
import { PatientFlow } from 'containers/PatientFlow';
import SigninPage from 'components/Signin/SigninPage';
import ForgotPassword from 'components/Signin/ForgotPassword';
import ResetPassword from 'components/Signin/ResetPassword';
import { CreateAccount } from 'components/Signin/CreateAccount';
import StrategyPeriodCreate from 'components/CreateStrategy/StrategyPeriodCreate';
import StrategyConfirmCreate from 'components/CreateStrategy/StrategyConfirmCreate';
import StrategyDescriptionsCreate from 'components/CreateStrategy/StrategyDescriptionsCreate';
import { Terms } from 'containers/Terms';
import StrategyCollaboratorsCreate from 'components/CreateStrategy/StrategyCollaboratorsCreate';
import { StrategicQuestion } from 'containers/StrategicQuestion';
import { CompetitiveLandscape } from 'containers/CompetitiveLandscape';
import { EvidenceGenerationIdeas } from 'containers/EvidenceGenerationIdeas';
import { StrategicPossibilities } from 'containers/StrategicPossibilities';
import { DriversAndBarriers } from 'containers/DriversAndBarriers';

import { CompetitiveAdvantage } from 'containers/CompetitiveAdvantage';
import { BrandPositioning } from 'containers/BrandPositioning';

import { CoreValueMessages } from 'containers/CoreValueMessages';
import { CriticalSuccessFactors } from 'containers/CriticalSuccessFactors';

import { AccessStrategy } from 'containers/AccessStrategy';
import { AccessStrategySetup } from 'components/AccessStrategy/AccessStrategySetup';
import { AccessStrategyPrioritisation } from 'components/AccessStrategy/AccessStrategyPrioritisation';
import { MedicalStrategy } from 'containers/MedicalStrategy';
import { CommercialStrategyBigIdeas } from 'containers/CommercialStrategy/CommercialStrategyBigIdeas';
import { CommercialStrategyKeyTactics } from 'containers/CommercialStrategy/CommercialStrategyKeyTactics';
import { CommercialStrategyThePlan } from 'containers/CommercialStrategy/CommercialStrategyThePlan';
import { CommercialStrategySummary } from 'containers/CommercialStrategy/CommercialStrategySummary';
import { LongTermStrategyFutureTrends } from 'containers/LongTermStrategyFutureTrends';
import { LongTermStrategySolutions } from 'containers/LongTermStrategySolutions';
import { LongTermStrategyThePlan } from 'containers/LongTermStrategyThePlan';
import { LongTermStrategySummary } from 'containers/LongTermStrategySummary';
import { RiskMitigationSummary } from 'containers/RiskMitigationSummary';
import { RiskMitigationIdeas } from 'containers/RiskMitigationIdeas';

import { VersionCheck } from 'components/VersionCheck';
import { CollaborationModal } from 'components/CollaborationModal';
import { HelpModal } from 'components/HelpModal';
import { AuthRedirect } from 'components/AuthRedirect';
import { AccessStrategyGaps } from 'components/AccessStrategy/AccessStrategyGaps';
import { AccessStrategyEvidence } from 'components/AccessStrategy/AccessStrategyEvidence';
import AccessStrategyPico from 'components/AccessStrategy/AccessStrategyPico';
import { AccessStrategyTactics } from 'components/AccessStrategy/AccessStrategyTactics/AccessStrategyTactics';
import { AccessStrategySummary } from 'components/AccessStrategy/AccessStrategySummary';
import { IndicatorsPage } from 'components/3-5-critical-metrics/3-5-1-lead-and-lag-indicators/feature-lead-and-lag-indicators/src';
import { GoalsPage } from 'components/3-5-critical-metrics/3-5-2-goals/feature-goals/src';
import { GoalsTrackingPage } from 'components/3-5-critical-metrics/3-5-2-goals-tracking/feature-goals-tracking/src';

import { createBrowserHistory } from 'history';
import { PatientJourneyPageProvider } from 'contexts/PatientJourneyPageContext';
import { CompetitiveLandscapeResult } from 'containers/CompetitiveLandscapeResult';
import { DndKitDemo } from 'containers/DndKitDemo';

const Route = Sentry.withSentryRouting(ReactRouterRoute);

export const history = createBrowserHistory();

export default function Routing() {
  const [auth] = useAuthContext();
  const [failedInvites, setFailedInvites] = useState(false);

  if (auth.loading) return null;

  return (
    <Router history={history}>
      <PatientJourneyPageProvider>
        <div className="app">
          <AuthRedirect />
          <CollaborationModal />

          <HelpModal />
          <Switch>
            <Route path="/dnd-kit-demo">
              <DndKitDemo />
            </Route>

            <Route path="/terms">
              <Terms />
            </Route>
            {auth.user ? (
              <>
                {auth.user?.role === 'ADMIN' ? (
                  <Switch>
                    <Route path="/admin/company/:id">
                      <AdminCompany />
                    </Route>
                    <Route path="/users">
                      <Users />
                    </Route>
                    <Route path="/blocks">
                      <Products />
                    </Route>
                    <Route path="/admin">
                      <Admin />
                    </Route>
                    <Route path="/signin/reset-password">
                      <ResetPassword />
                    </Route>
                    <Route path="/">
                      <Redirect to="/admin" />
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/style-guide">
                      <StyleGuide />
                    </Route>
                    <Route path="/d/:drugId/strategy/:strategyId/people">
                      <People />
                    </Route>
                    <Route path="/d/:drugId/strategy/:strategyId/files">
                      <Files />
                    </Route>
                    {/* Phase 1 Routes */}
                      <Route path="/d/:drugId/strategy/:strategyId/1_1/:stakeholder?">
                        <PatientJourney />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/1_2/:stakeholder?">
                        <KeyStakeholders />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/1_3/:category?/:stakeholder/result/:stakeholderDefinitionId/:region?">
                        <CompetitiveLandscapeResult />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/1_3/:category?/:stakeholder?/:stakeholderDefinitionId?/:competitorId?">
                        <CompetitiveLandscape />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/1_4">
                        <LongTermStrategyFutureTrends />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/1_5/:stakeholder?">
                        <KeyInsights />
                      </Route>
                      
                    {/* Phase 2 Routes */}
                      <Route path="/d/:drugId/strategy/:strategyId/2_1/:substep?">
                        <StrategicQuestion />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/2_2">
                        <PatientFlow />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/2_3/:stakeholderDefinitionId?">
                        <StrategicPossibilities />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/2_4/:possibilityId?">
                        <DriversAndBarriers />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/2_5/:stakeholder?/:stakeholderDefinitionId?">
                        <EvidenceGenerationIdeas />
                      </Route>

                    {/* Phase 3 Routes */}
                      <Route path="/d/:drugId/strategy/:strategyId/3_1/:positioningTab?">
                        <BrandPositioning />
                      </Route>
                    {/* Phase 3.2 Routes */}
                      <Route path="/d/:drugId/strategy/:strategyId/3_2/:coreValueMessage?">
                        <CoreValueMessages />
                      </Route>
                    {/* Phase 3.3 Routes */}
                      <Route path="/d/:drugId/strategy/:strategyId/3_3/:subStep?">
                        <CompetitiveAdvantage />
                      </Route>
                    {/* Phase 3.4 Routes */}
                      <Route path="/d/:drugId/strategy/:strategyId/3_4/:substep?/:competitiveAdvantageRowUrlParam?/:region?">
                        <CriticalSuccessFactors />
                      </Route>

                    {/* Phase 3.5 Routes */}
                      <Redirect 
                        from="/d/:drugId/strategy/:strategyId/3_5"
                        to="/d/:drugId/strategy/:strategyId/3_5/indicators" 
                        exact={true}
                      />
                      <Route path="/d/:drugId/strategy/:strategyId/3_5/indicators/:competitiveAdvantageRowUrlParam?">
                        <IndicatorsPage />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/3_5/goals/:competitiveAdvantageRowUrlParam?">
                        <GoalsPage />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/3_5/tracking/:competitiveAdvantageRowUrlParam?">
                        <GoalsTrackingPage />
                      </Route>

                    {/* Phase 4 Routes */}

                    {/* 4_1 Screens */}
                      <Route path="/d/:drugId/strategy/:strategyId/4_1/:subStep?/:strategicImperativeId?/:region?/:area?">
                        <MedicalStrategy />
                      </Route>

                    {/* 4_2 Screens */}
                      <Route path="/d/:drugId/strategy/:strategyId/4_2/setup">
                        <AccessStrategySetup />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_2/prioritisation/:archetypeId?">
                        <AccessStrategyPrioritisation />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_2/gaps">
                        <AccessStrategyGaps />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_2/evidence">
                        <AccessStrategyEvidence />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_2/pico">
                        <AccessStrategyPico />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_2/winning-actions">
                        <AccessStrategyTactics />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_2/summary">
                        <AccessStrategySummary />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_2">
                        <AccessStrategy />
                      </Route>

                    {/* 4_3 Screens */}
                      <Route path="/d/:drugId/strategy/:strategyId/4_3/big-ideas/:strategicImperativeId?">
                        <CommercialStrategyBigIdeas />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_3/winning-actions/:strategicImperativeId?/:region?">
                        <CommercialStrategyKeyTactics />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_3/the-plan/:strategicImperativeId?">
                        <CommercialStrategyThePlan />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_3/summary/:strategic/:region?">
                        <CommercialStrategySummary />
                      </Route>
                      <Redirect
                        from="/d/:drugId/strategy/:strategyId/4_3"
                        to="/d/:drugId/strategy/:strategyId/4_3/big-ideas"
                      />

                    {/* 4_3 Screens */}
                      <Route path="/d/:drugId/strategy/:strategyId/4_4/ideas/:strategicImperativeId?">
                        <RiskMitigationIdeas />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_4/summary">
                        <RiskMitigationSummary />
                      </Route>
                      <Redirect
                        from="/d/:drugId/strategy/:strategyId/4_4"
                        to="/d/:drugId/strategy/:strategyId/4_4/ideas"
                      />

                    {/* 4_5 Screens */}
                      <Route path="/d/:drugId/strategy/:strategyId/4_5/solutions">
                        <LongTermStrategySolutions />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_5/the-plan">
                        <LongTermStrategyThePlan />
                      </Route>
                      <Route path="/d/:drugId/strategy/:strategyId/4_5/summary">
                        <LongTermStrategySummary />
                      </Route>
                      <Redirect
                        from="/d/:drugId/strategy/:strategyId/4_5"
                        to="/d/:drugId/strategy/:strategyId/4_5/solutions"
                      />
                    {auth.user?.role === 'LEAD' ? (
                      <Route path="/d/:drugId/strategy/:strategyId/create/period">
                        <StrategyPeriodCreate />
                      </Route>
                    ) : null}
                    {auth.user?.role === 'LEAD' ? (
                      <Route path="/d/:drugId/strategy/:strategyId/create/collaborators">
                        <StrategyCollaboratorsCreate />
                      </Route>
                    ) : null}
                    {auth.user?.role === 'LEAD' ? (
                      <Route path="/d/:drugId/strategy/:strategyId/create/descriptions">
                        <StrategyDescriptionsCreate
                          setFailedInvites={setFailedInvites}
                        />
                      </Route>
                    ) : null}
                    {auth.user?.role === 'LEAD' ? (
                      <Route path="/d/:drugId/strategy/:strategyId/create/confirm">
                        <StrategyConfirmCreate failedInvites={failedInvites} />
                      </Route>
                    ) : null}
                    <Route exact path="/d/:drugId/strategy/:strategyId">
                      <Strategy />
                    </Route>
                    <Route path="/signin/reset-password">
                      <ResetPassword />
                    </Route>
                    <Route exact path="/">
                      <Drugs />
                    </Route>
                    <Route exact path="/d/:drugId/strategy/:strategyId/welcome">
                      <Welcome />
                    </Route>
                    <Route path="/signin">
                      <SigninPage />
                    </Route>
                    <Route path="/">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                )}
              </>
            ) : (
              <Switch>
                <Route path="/signup">
                  <CreateAccount />
                </Route>
                <Route path="/signin/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route path="/signin/reset-password">
                  <ResetPassword />
                </Route>
                <Route path={['/signin', '/']}>
                  <SigninPage />
                </Route>
              </Switch>
            )}
          </Switch>
        </div>
        <VersionCheck />

        {/* Portal containers */}
        <div id="unmet-need" />
        <div id="collaboration" />
      </PatientJourneyPageProvider>
    </Router>
  );
}