import React, {  useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { colorsv2, themes } from 'constants/index';
import {
  BodySmall,
  ButtonIcon,
  ButtonLabel,
  ButtonPill,
  Caption,
  Heading1,
  Heading2,
  Heading3,
  Icon,
} from './shared';
import { useContributionsQuery, useDrugsQuery } from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { BubbleSummary } from './Strategy/BubbleSummary';
import { useHistory } from 'react-router-dom';
import * as themeUtils from 'utils/themes';
import _ from 'lodash';
import { device } from 'utils/breakpoints';
import { ErrorWrapper } from './ErrorLoadingComponent';

interface Props {
  drugId: number;
  strategyId: number;
}

const PromoBackContainer = styled.div`
  background-color: transparent;
  border-radius: 5px;
  position: fixed;
  top: 70px;
  left: 15px;
  z-index: 8;
  display: flex;
`;

const PageWrapper = styled.div<{ expanded?: boolean }>`
  align-items: center;
  background: ${colorsv2.bg.surfaceBase2};
  display: flex;
  height: calc(100% - 47px);
  flex-direction: column;
  gap: 56px;
  position: fixed;
  top: 47px;
  padding: 56px 0px;
  overflow-x: hidden;
  overflow-y: ${({ expanded }) => expanded ? 'hidden' : 'scroll'};
  width: 100%;

  @media ${device.mobile} {
    gap: 16px;
    padding: 16px 0px;
  }
`;

const BlockIcon = styled.div`
`;

const BlockTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  ${Heading1},
  ${Heading2},
  ${Heading3} {
    line-height: 1;
  }
`;

const BlockButton = styled.div`
  button {
    padding: 4px 8px;
  }
`;

const BlockBackButton = styled(ButtonIcon)`
  left: 13px;
  top: 65px;
  position: fixed;

  button {
    background: rgba(255, 255, 255, 0.24);

    svg {
      color: #fff;
    }

    :hover {
      background: rgba(255, 255, 255, 0.24) !important;
    }

    :active {
      background: rgba(255, 255, 255, 0.24) !important;
    }
  }

  z-index: 100;
`;

const BlockContent = styled.div<{ theme: string }>`
  background: ${colorsv2.bg.surfaceBase2};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  height: 100%;
  width: 100%;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ${({ theme }) => {
    switch(theme) {
      case 'activate':
        return css`
          background-image: url(/themes/Activate@2x.png);
        `
      case 'develop':
        return css`
          background-image: url(/themes/Develop@2x.png);
        `
      case 'explore':
        return css`
          background-image: url(/themes/Explore@2x.png);
        `
      case 'discover':
        return css`
          background-image: url(/themes/Discover@2x.png);
        `
      default: 
        return css`
        `
    }
  }}
`;

const BlockWrapper = styled.div<{ 
  expanded?: boolean,
  theme: string 
}>`
  background: #fff;
  border-radius: 100%;
  height: 480px;
  min-height: 480px;
  padding: 3px;
  position: relative;
  width: 480px;

  ${({ theme }) => {
    switch(theme) {
      case 'activate':
        return css`
          background: ${colorsv2.bg.activateGradient};
        `
      case 'develop':
        return css`
          background: ${colorsv2.bg.developGradient};
        `
      case 'explore':
        return css`
          background: ${colorsv2.bg.exploreGradient};
        `
      case 'discover':
        return css`
          background: ${colorsv2.bg.discoverGradient};
        `
      default: 
        return css`
          background: ${colorsv2.bg.surfaceBase2};
        `
    }
  }}
`;

const BlockOverlay = styled.div<{ expanded?: boolean }>`
  ${({ expanded }) => expanded ? css`
    background: ${colorsv2.bg.surfaceBase2};
    display: flex;
    height: calc(100% - 48px);
    justify-content: center;
    position: fixed;
    padding-top: 16px;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 48px;
    width: 100%;
    z-index: 100;

    ${BlockWrapper} {
      height: 732px;
      min-height: 732px;
      min-width: 732px;
      position: absolute;
      width: 732px;
    }

    ${BlockTitle} {
      margin-top: -10px;
    }

    ${BlockContent} {
      align-items: center;
      gap: 54px;
      position: relative;
    }

    ${BlockTools} {
      align-items: center;
      display: flex;
      position: relative;
      left: 0;
      width: auto;
    }

    ${BlockTool} {
      left: 0;
    }

    ${BlockButton} {
      position: relative;
      top: 0;
    }
  ` : css`
     ${BlockButton} {
      position: absolute;
      top: 286px;
    }

      @media ${device.mobile} {
        ${BlockWrapper} {
          height: 343px;
          min-height: 343px;
          width: 343px;
        }

        ${BlockButton} {
          top: 218px;
        }
      }
  `}
`;

const BlockTool = styled.div<{ 
  theme: string,
  left?: number,
  hasStarted: boolean,
  preview?: boolean
}>`
  align-items: center;
  background: ${colorsv2.bg.layer};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 40px;
  gap: 8px;
  padding: 0 16px 0 4px;
  position: relative;

  left: ${({ left }) => left ? `${left}px` : '0'};
  pointer-events: ${({ preview }) => preview ? 'none' : 'auto'};

  :hover {
    background: ${colorsv2.bg.layerHover};
  }

  ${({ theme, hasStarted, preview }) => {
    switch(theme) {
      case 'activate':
        return css`
          ${BlockToolFeature} {
            background: ${hasStarted && !preview ? colorsv2.bg.activate : '#fff' };
          }

          ${BlockToolTitle} > p {
            color: ${colorsv2.text.activate};
          }
        `
      case 'develop':
        return css`
          ${BlockToolFeature} {
            background: ${hasStarted && !preview ? colorsv2.bg.develop : '#fff' };
          }

          ${BlockToolTitle} > p {
            color: ${colorsv2.text.develop};
          }
        `
      case 'explore':
        return css`
          ${BlockToolFeature} {
            background: ${hasStarted && !preview ? colorsv2.bg.explore : '#fff' };
          }

          ${BlockToolTitle} > p {
            color: ${colorsv2.text.explore};
          }
        `
      case 'discover':
        return css`
          ${BlockToolFeature} {
            background: ${hasStarted && !preview ? colorsv2.bg.discover : '#fff' };
          }

          ${BlockToolTitle} > p {
            color: ${colorsv2.text.discover};
          }
        `
      default: 
        return css`
        `
    }
  }}}
`;

const BlockToolIncluded = styled.div`
  align-items: center;
  background: ${colorsv2.bg.brand};
  border-radius: 16px;
  bottom: -16px;
  display: flex;
  height: 20px;
  gap: 2px;
  position: absolute;
  padding: 2px 4px;
  right: -10px;
  width: 100px;
`;

const BlockTools = styled.div<{  width: number }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: absolute;
  left: 410px;
  width: ${({ width }) => width}px;
`;

const BlockToolFeature = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  min-width: 32px;
  padding-top: 2px;
  width: 32px;
`;

const BlockToolTitle = styled.div`
  padding-top: 1px;
`;

export const StrategyBlock: React.FC<{
  drugId: number;
  strategyId: number;
  block?: string | null;
  blockTools?: any;
  theme: string;
  title: string;
  currentFeatures?: any;
  contributions?: any;
  expanded?: boolean;
  preview?: boolean;
  onActionClick?: (action?: string) => void;
}> = ({
  drugId,
  strategyId,
  block,
  blockTools,
  theme,
  title,
  currentFeatures,
  contributions,
  expanded,
  preview,
  onActionClick,
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const windowWidth = window.innerWidth;

  const getLeftPositions = (length: number) => {
    switch(length) {
      case 5:
        return [0, 37, 50, 37, 0]
      case 4:
        return [23, 46, 46, 23]
      case 3:
        return [37, 50, 37]
      case 2:
        return [46, 46]
      case 1:
        return [50]

    }
  }

  const hasStarted = (feature: string) => {
    switch (feature) {
      case '1.1':
        return contributions?.patientJourney;
      case '1.2':
        return contributions?.keyStakeholders;
      case '1.3':
        return contributions?.competitiveLandscape;
      case '1.4':
        return contributions?.keyTrendsAnalysis;
      case '1.5':
        return contributions?.keyInsights;
      case '2.1':
        return contributions?.strategicQuestion;
      case '2.2':
        return contributions?.patientFlow;
      case '2.3':
        return contributions?.options;
      case '2.4':
        return contributions?.driversAndBarriers;
      case '2.5':
        return contributions?.creatingValue;
      default:
        return false;
    }
  }

  const renderBlockTool = (data: any) => {
    if (!isDesktop && !expanded) {
      return;
    }

    const positions = getLeftPositions(data.length);
    let url = buildUrl(data.feature.replace('.', '_'));
    if (block) {
      url = `${url}?block=${block}`;
    }

    return (
      <BlockTool 
        theme={data.theme}
        left={positions?.[data.index] || 0}
        hasStarted={hasStarted(data.feature)}
        preview={preview}
        onClick={() => {
          history.push(url);
        }}
      >
        <BlockToolFeature>
          <BodySmall>{!block && data.feature}</BodySmall>
        </BlockToolFeature>
        <BlockToolTitle>
          <ButtonLabel>{data.name}</ButtonLabel>
        </BlockToolTitle>

        {preview && currentFeatures.includes(data.feature) && (
          <BlockToolIncluded>
            <Icon color="#fff" name="Check" size={16} />
            <Caption color={colorsv2.textInverted.default}>
              In your plan
            </Caption>
          </BlockToolIncluded>
        )}
      </BlockTool>
    )
  }

  const buildUrl = (page: string) => {
    return `/d/${drugId}/strategy/${strategyId}/${page}`;
  }

  return (
    <BlockOverlay expanded={expanded}>
      <BlockWrapper theme={theme}>
        {expanded && (
          <BlockBackButton
            iconName="Back"
            onClick={() => onActionClick?.()}
          />
        )}

        <BlockContent theme={theme}>
          <BlockTitle>
            {!block ? (
              <Heading1 color={colorsv2.textInverted.default}>
                {title}
              </Heading1>
            ) : (
              <>
                <BlockIcon>
                  <Icon
                    name={`Block${_.startCase(block)}` as any}
                    size={56}
                    height={56}
                    width={56}
                  />
                </BlockIcon>
                <div>
                  <Heading3 color={colorsv2.textInverted.default}>
                    Nmblr
                  </Heading3>
                  <Heading2 color={colorsv2.textInverted.default} weight="regular">
                    {title}
                  </Heading2>
                </div>
              </>
            )}
          </BlockTitle>

          <BlockTools width={(windowWidth / 3) - 80}>
            {!block ? (
              <>
                {Object.keys(themes[theme].tools).map((tool: any, index: any) => (
                  <React.Fragment key={index}>
                    {renderBlockTool({
                      index,
                      theme,
                      feature: tool,
                      name: themes[theme].tools[tool].name,
                      length: Object.keys(themes[theme].tools).length,
                    })}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                {Object.keys(blockTools).map((tool: any, index: any) => (
                  <React.Fragment key={index}>
                    {renderBlockTool({
                      index,
                      theme,
                      feature: tool,
                      name: blockTools[tool].name,
                      length: Object.keys(blockTools).length,
                    })}
                  </React.Fragment>
                ))}
              </>
            )}
          </BlockTools>

          <BlockButton>
            <ButtonPill
              iconName={isDesktop || expanded ? 'BarChart2' : undefined}
              iconRightName={!isDesktop && !expanded ? 'Arrow-right' : undefined}
              iconSize={16}
              text={isDesktop || expanded 
                ? 'Summary' 
                : (
                  !block 
                    ? Object.keys(themes[theme].tools).length
                    : Object.keys(blockTools).length
                ) + (
                  !block
                    ? (Object.keys(themes[theme].tools).length > 1 ? ' tools' : ' tool')
                    : (Object.keys(blockTools).length > 1 ? ' tools' : ' tool')
                )
              }
              level={`${theme}` as any}
              onClick={() => onActionClick?.(isDesktop || expanded ? 'summary' : 'tool')}
            />
          </BlockButton>
        </BlockContent>
      </BlockWrapper>
    </BlockOverlay>
  )
};

export const Strategy: React.FC<Props> = ({ drugId, strategyId }) => {
  const history = useHistory();
  const isDesktop = useDesktop();

  const { data: drugData } = useDrugsQuery({
    variables: { where: { id: drugId } },
    fetchPolicy: 'network-only',
  });

  const {
    data: contributionsData,
    loading: contributionLoading,
  } = useContributionsQuery({
    variables: { where: { strategyId } },
    fetchPolicy: 'cache-and-network',
  });

  const ProductOnDrugs = drugData?.drugs?.items?.filter(
    (e) => e.id === drugId
  )[0]?.ProductOnDrugs;

  const miniFeatureSet = useMemo(() => {
    if (ProductOnDrugs) {
      return ProductOnDrugs?.flatMap((f: any) => {
        if (f.Product?.features)
          return f.Product?.features?.map((g: any) => g.featureSet);
        return [];
      });
    }
    return [];
  }, [ProductOnDrugs]);

  const isFullNmblr = themeUtils.isFullNmblr(miniFeatureSet);

  const [view, setView] = useState<string|null>(null);
  const [viewType, setViewType] = useState<string|null>(null);

  if (!contributionsData || contributionLoading) {
    return (
      <ErrorWrapper 
        bg="dark"
        isLoading={true} 
        errors={[]} 
        dataMissing={false} 
      />
    );
  }

  return (
    <PageWrapper expanded={!isDesktop && !!view && viewType === 'tool'}>
      {Object.keys(themes).map((theme, key) => (
        <StrategyBlock 
          key={key}
          theme={theme}
          title={themes[theme].label}
          drugId={drugId}
          strategyId={strategyId}
          currentFeatures={miniFeatureSet}
          contributions={contributionsData?.contributions}
          expanded={!isDesktop && view === theme && viewType === 'tool'}
          preview={miniFeatureSet.length > 0 && !isFullNmblr}
          onActionClick={(action: any) => {
            setView(theme);
            setViewType(action);
          }}
        />
      ))}

      {miniFeatureSet.length > 0 && !isFullNmblr && isDesktop && (
        <PromoBackContainer>
          <ButtonPill
            level="outline-inverted"
            iconName="Arrow-left"
            className="add-insight"
            clickClassName="cypress-insight-create"
            onClick={async (e) => {
              history.push(`/d/${drugId}/strategy/${strategyId}/welcome`);
            }}
            text="Back to your plan"
          />
        </PromoBackContainer>
      )}

      {viewType === 'summary' && (
        <BubbleSummary
          closeBubbleSummary={() => {
            setViewType(!isDesktop && view ? 'tool' : null);
          }}
          strategyId={strategyId}
          bubble={Object.keys(themes).indexOf(view || '') + 1}
        />
      )}
    </PageWrapper>
  );
};