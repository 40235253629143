import { useEffect, useMemo, useState, useRef} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { CountryGlobal } from 'types';

import { EvaluationStakeHolder } from 'components/CompetitiveLandscape/EvaluationStakeHolder';
import { Loading } from 'components/Loading';
import { Navbar } from 'components/Navbar';
import { Observations } from 'components/Observations';
import { StakeholderDefinitionSummaryModal } from 'components/StakeholderDefinitionSummaryModal';
import useHandleRegionDropdown from 'hooks/useHandleRegionDropdown';
import { Icon } from 'components/shared';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { CategoryTabs } from 'components/CategoryTabs';

import { CustomCountriesDropdownSmall } from 'components/shared/CustomCountriesDropdownSmall';
import {
  BodyNormal,
  StepHeaderBar,
  Subtitle2,
} from 'components/shared';
import { TooltipWrapper } from 'components/shared/ButtonPill';
import {
  Wrapper as StakeholderDefinitionsWrapper,
} from 'components/shared/StakeholderDefinitions';
import { colors, polling, zIndex } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import { useStakeholderContext } from 'contexts/StakeholderContext';
import {
  CompetitiveCategory,
  CompetitiveLandscapeRatingFragment,
  CompetitorFragment,
  Sort,
  Stakeholder,
  Step,
  useCompetitorsQuery,
  usePostItGroupsWithCardsQuery,
  useStakeholderDefinitionsQuery,
} from 'data/graphql/generated';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { useQuery } from 'hooks/useQuery';
import { PostItGroupAndCards } from 'types';
import { device } from 'utils/breakpoints';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall } from 'components/shared/TextStyles';



const ComponentWrapper = styled.div``;


const Button = styled.button`
  display: flex;
  padding: 5px 20px 5px 10px;
  align-items: center;
  background: linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);
  border: 1px solid #FFF;
  border-radius: 40px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  height: 40px
  &:hover {
    opacity: 0.9;
  }
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  border-left: 1px solid white;
  padding-left: 8px;

  // &::before {
  //   content: '|';
  //   margin-right: 5px;
  //   color: #FFF;
  // }
`;

const StepHeader = styled(StepHeaderBar)`
  display: block;
  padding: 15px 20px 0 20px;
  min-height: unset;
  border-bottom: 0.5px solid ${colors.greyMedium};

  @media ${device.tabletMax} {
    padding-top: 8px;
    margin-top: 160px;
    display: block;
  }

  ${TooltipWrapper} {
    position: relative;
  }

  ${StakeholderDefinitionsWrapper} {
    margin-bottom: 15px;
  }
`;

const StyledPostItsEmpty = styled(PostItsEmpty)`
  background: var(--white-50, rgba(255, 255, 255, 0.50));
  height: 100%;
  @media ${device.tabletMin} {
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }

  @media ${device.mobile} {
    padding-bottom: 88px;
    height: auto;
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }
`;

const HeaderContainer = styled.div``;

export const ChooseCompetitorsBar = styled.div`
  min-height: 115px;
  padding: 15px;
  position: absolute;
  border-bottom: 0.5px solid ${colors.greyMedium};
  z-index: ${zIndex.chooseCompetitorsBar};
  background: ${colors.white};
  width: 100%;

  ${TooltipWrapper} {
    display: inline-grid;
    margin-right: 20px;
  }
`;


export const Divider = styled.div`
  height: 30px;
  width: 1px;
  background: ${colors.white50};
  margin-right: 15px;
`;

export const EvaluateButtonContainerDesktop = styled.div`
  margin-top: 20px;
  display: flex;
  margin-left: 5px;

  ${Divider} {
    margin-right: 10px;
  }

  @media ${device.desktopMin} {
    margin-top: 0;
  }
`;

export const PageNavChildren = styled.div`
  display: flex;
  justify-content: space-center;
  order: -1;
  flex-grow: 1;
  margin-left: 15px;
`;

const NavbarContent = styled.div`
  @media screen and (min-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    alignitems: center;
  }

  @media ${device.tabletMax} {
    margin-top: 10px;
  }
`;

const NavbarControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 325px;
  justify-content: flex-end;

  @media ${device.tabletMax} {
    justify-content: flex-start;
    margin-top: 10px;
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  category: CompetitiveCategory;
  stakeholderDefinitionId: string;
  competitorId: string;
  region: string;
}

const CategoryTabsWrapper = styled.div`
  @media ${device.desktopMin} {
    max-width: 100%;
    width: calc(100% - 300px);
  }
`;

export const CompetitiveLandscapeResult = () => {
  const {
    drugId,
    strategyId,
    category,
    stakeholderDefinitionId,
    competitorId,
    region
  }: URLParams = useParams();

  const [stakeholder,] = useStakeholderContext(
    'Patient'
  );

  const [{ user }] = useAuthContext();

  const history = useHistory();
  const query = useQuery();

  const {
    selectedRegion,
    uniqueRegions
  } = useHandleRegionDropdown({ region, user, strategyId: +strategyId });
  // const uniqueRegions = getUniqueRegions(strategy?.users || [], false);
  const regionsExcludingGlobal = useMemo(() => uniqueRegions.slice().sort(), [uniqueRegions]);

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showEvaluation,] = useState(
    query.get('evaluating') === 'true'
  );
  const [
    selectedRegions,
    setSelectedRegions,
  ] = useState<CountryGlobal[]>([...uniqueRegions, 'global']);
  const [
    countryDropdownActive,
    setCountryDropdownActive,
  ] = useState(false);
  const countryDropDownRef = useRef<HTMLDivElement | null>(null);

  useClickOutsideComponent(
    countryDropDownRef,
    () => {
      countryDropdownActive &&
        setCountryDropdownActive(false);
    },
    ['cypress-country-dropdown-toggle', 'cypress-countries-dropdown-item']
  );
  const [showDistribution, setShowDistribution] = useState(true);

  const competitorsQueryVars = {
    where: { strategyId: +strategyId },
  };

  const {
    data: strategyCompetitorsData,
    loading: competitorsLoading,
    error: competitorsError,
    startPolling: startPollingCompetitors,
    stopPolling: stopPollingCompetitors,
  } = useCompetitorsQuery({
    variables: competitorsQueryVars,
  });

  const {
    items: groupItems,
    loading: groupsLoading,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        // stakeholder,
        step: Step.Competitivelandscape,
        category: category,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Competitivelandscape,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
        // stakeholder,
        stakeholderDefinitionId: Number(stakeholderDefinitionId),
        category: category,
        // competitorId: Number(competitorId),
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Keystakeholders,
      // stakeholder,
      stakeholderDefinitionId: Number(stakeholderDefinitionId),
      // competitorId: Number(competitorId),
    }
  );

  // get "What are the outcomes that matter?" cards to sort the groups
  const {
    data: groupsWithCardsData,
    error: groupsWithCardsError,
    loading: groupsWithCardsLoading,
  } = usePostItGroupsWithCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        // stakeholder,
        step: Step.Keystakeholders,
        // title: `What are the outcomes that matter to ${mapStakeholderEnumToPostItGroupTitle[stakeholder]}?`,
        preset: true,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const addCardsToGroups = useMemo(
    () => {
      const uniqueTitles = new Set<string>();

      return groupItems.reduce((acc, val) => {
        // Filter cardItems by postItGroupId and include === true
        const filteredCards = cardItems
          .filter((c) => {
            return c.title === val.title && c.include === true; // Check for postItGroupId and include
          })
          .sort(({ pos: a }, { pos: b }) => b - a); // Sort by 'pos'


        // If there are no filtered cards, skip this group
        if (filteredCards.length === 0) return acc;

        // Check if the group title has already been added
        if (!uniqueTitles.has(val.title)) {
          uniqueTitles.add(val.title); // Add title to the set to avoid duplicates

          return [
            ...acc,
            {
              ...val,
              cards: filteredCards, // Assign filtered cards to the group
            },
          ];
        }

        // Skip groups with duplicate titles
        return acc;
      }, [] as PostItGroupAndCards[]);
    },
    [cardItems, groupItems] // Dependencies
  );

  const addCardsToGroups2 = useMemo(
    () =>
      groupItems.reduce((acc, val) => {
        // Check if stakeholder is not null before proceeding
        if (val.stakeholder !== null) {
          return [
            ...acc,
            {
              ...val,
              cards:
                cardItems
                  .filter((c) => c.postItGroupId === val.id)
                  .sort(({ pos: a }, { pos: b }) => b - a) || [],
            },
          ];
        }
        return acc; // If stakeholder is null, return the accumulator without changes
      }, [] as PostItGroupAndCards[]),
    [cardItems, groupItems]
  );

  // const cardsByAlignmentOrder = useMemo(
  //   () =>
  //     (whatAreTheOutcomesGroup?.cards || []).sort((a, b) => {
  //       return (
  //         (b?.collaboration?.alignmentCount || 0) -
  //         (a?.collaboration?.alignmentCount || 0)
  //       );
  //     }),
  //   [whatAreTheOutcomesGroup?.cards]
  // );

  // Sort all the cards by alignmentCount (if needed)

  const groups = addCardsToGroups;
  const groups2 = addCardsToGroups2;

  const {
    data: stakeholderDefinitions,
    error: stakeholderDefinitionsError,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsQuery({
    variables: {
      orderBy: { createdAt: Sort.Asc },
      where: {
        stakeholder: Stakeholder.Patient,
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(polling.default);
    startPollingCompetitors(polling.default);
    return () => {
      stopPolling();
      stopPollingCompetitors();
    };
  }, [
    startPolling,
    stopPolling,
    startPollingCompetitors,
    stopPollingCompetitors,
  ]);

  const validStakeholderDefinitions = useMemo(
    () =>
      stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
        (definition) => definition.title
      ) || [],
    [stakeholderDefinitions]
  );

  const selectedStakeholderDefinition = useMemo(
    () =>
      validStakeholderDefinitions?.find(
        (s) => s.id === +stakeholderDefinitionId
      ),

    [validStakeholderDefinitions, stakeholderDefinitionId]
  );

  const selectedCompetitor = useMemo(
    () =>
      strategyCompetitorsData?.competitors?.items.find(
        (c) => c.id === +competitorId
      ),
    [competitorId, strategyCompetitorsData?.competitors?.items]
  );

  const firstStakeholderDefinition = validStakeholderDefinitions[0];

  const [activeCompetitors, setActiveCompetitors] = useState<
    CompetitorFragment[]
  >([]);

  const hasStakeholderError =
    !stakeholderDefinitionsLoading && validStakeholderDefinitions.length === 0;

  const [, setRatings] = useState<CompetitiveLandscapeRatingFragment[]>(
    []
  );

  const targetPatientError = JSON.stringify(stakeholderDefinitionsError) || '';

  const hasNoTargetPatientsError = targetPatientError.includes(
    'NO_TARGET_PATIENTS'
  );
  const hasStakeholderNotIdentifiedError = targetPatientError.includes(
    'STAKEHOLDER_NOT_IDENTIFIED'
  );


  const dataIsLoading =
    cardsLoading ||
    groupsLoading ||
    stakeholderDefinitionsLoading ||
    groupsWithCardsLoading ||
    competitorsLoading;

  const dataMissing =
    !groupItems ||
    !cardItems ||
    !groupsWithCardsData ||
    !strategyCompetitorsData;

  const dataErrors = [
    groupsError || cardsError || groupsWithCardsError,
    competitorsError,
  ];

  const showingErrorPage =
    dataIsLoading || dataMissing || dataErrors.some((error) => !!error);
  useEffect(() => {
    if (regionsExcludingGlobal.length === 0) return;
    
    // Only set initial selections once when component mounts
    const initialSelections = [...regionsExcludingGlobal, 'global'];
    setSelectedRegions(initialSelections as CountryGlobal[]);
    
    // Empty dependency array means this only runs once on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (category === 'Technologies'
      || category === 'Actions'
    ) {
      if (strategyCompetitorsData?.competitors?.items) {
        const stakeholderCompetitorIds =
          selectedStakeholderDefinition?.CompetitorDetails?.filter(
            (cd) => cd?.stakeholder === stakeholder && cd?.type === category
          )?.map((cd) => cd?.competitorId) || [];
  
        setActiveCompetitors(
          (strategyCompetitorsData?.competitors?.items || [])
            .filter(
              (c) =>
                c?.CompetitorDetails?.some(
                  (detail) => detail?.stakeholder === stakeholder
                ) && stakeholderCompetitorIds.includes(c.id)
            )
            .slice()
            .sort((a, b) => a.title.localeCompare(b.title)) || []
        );
      }
    } else {
      if (strategyCompetitorsData?.competitors?.items) {
        setActiveCompetitors(() => (
          (strategyCompetitorsData?.competitors?.items || [])
            .slice()
            .sort((a, b) => a.title.localeCompare(b.title)) || []
        ));
      }
    }
   
  }, [
    strategyCompetitorsData?.competitors?.items,
    stakeholder,
    category,
    selectedStakeholderDefinition,
  ]);

  useEffect(() => {
    if (stakeholderDefinitionsLoading) return;
    // history.replace(url + queryParam);
  }, [
    activeCompetitors,
    competitorId,
    drugId,
    firstStakeholderDefinition?.id,
    history,
    selectedCompetitor?.id,
    showEvaluation,
    stakeholder,
    stakeholderDefinitionId,
    stakeholderDefinitionsLoading,
    strategyId,
  ]);

  useEffect(() => {
    if (groupsLoading || showingErrorPage) return;

    setRatings(
      groups
        .map((group) => {
          const competitiveLandscapeRating = group?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];

          return competitiveLandscapeRating.find(
            (rating) =>
              rating.stakeholderDefinitionId === +stakeholderDefinitionId &&
              rating.competitorId === +competitorId &&
              rating.user?.id === user?.id
          );
        })
        //For some annoying reason TS doesn't realise it's filtering out undefined
        //https://stackoverflow.com/questions/43010737/way-to-tell-typescript-compiler-array-prototype-filter-removes-certain-types-fro/54317362
        //T extends unknown is just syntax that allows me to use arrow functions for generics
        .filter(<T extends unknown>(val: T | undefined): val is T => {
          return !!val;
        })
    );
    //the groups variable should probably be memoized, but to prevent breaking anything, I am updating based on the initial data fetch instead as that's what matters
  }, [
    groupsLoading,
    groupItems,
    groupsWithCardsData,
    stakeholderDefinitionId,
    competitorId,
    user,
    groups,
    showingErrorPage,
  ]);

 const groupOutcomes = groups.map((group: any) => {
    // Collect card items with specific conditions
    const cardItems = group.cards.filter(
      (card: any) => card.category === group.category && card.include === true
    );

    const outcomes = cardItems.map((card: any) => {
      const competitors = activeCompetitors
        .filter((competitor: any) => {
          return competitor.CompetitorDetails.some(
            (detail: any) =>
              detail.stakeholder === card.stakeholder &&
              detail.type === group.category
          );
        })
        .map((competitor: any) => ({ id: competitor.id }));

      return {
        title: group.title,
        stakeholder: card.stakeholder,
        competitors: competitors,
      };
    });

    return outcomes;
  }).flat();

  return (
    <ComponentWrapper>
      {!!showSummaryModal && (
        <StakeholderDefinitionSummaryModal
          strategyId={Number(strategyId)}
          drugId={Number(drugId)}
          stakeholderDefId={selectedStakeholderDefinition?.id}
          handleClose={() => setShowSummaryModal(false)}
          visible={showSummaryModal}
        />
      )}
      <Navbar>
        <NavbarContent>
          <CategoryTabsWrapper>
            <CategoryTabs
              category={category}
              setCategory={(category) => {
                history.push(
                  `/d/${drugId}/strategy/${strategyId}/1_3/${category}/${stakeholder}/result/${stakeholderDefinitionId}`
                );
              }}
            />
          </CategoryTabsWrapper>
          <NavbarControls>
            <CustomCountriesDropdownSmall
              multiSelect
              selectedRegions={selectedRegions}
              setCountry={(country: CountryGlobal) => {
                if (selectedRegions.includes(country)) {
                  // If the region is already selected, deselect it
                  setSelectedRegions(selectedRegions.filter(
                    (region) => region !== country
                  ));
                } else {
                  // Add the region
                  setSelectedRegions([...selectedRegions, country]);
                }
              }}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                const target = e.target as HTMLDivElement;
                const dropdown = countryDropDownRef.current;
                if (!dropdown) return;
                if (dropdown.contains(target)) return;

                setCountryDropdownActive(!countryDropdownActive);
              }}
              dropDownRef={countryDropDownRef}
              viewOnly={false}
              // hideCountryName={isMobile}
              largeIcon
              globalOptionText={'Global / Regional users'}
              showDropdown={countryDropdownActive}
              setShowDropdown={setCountryDropdownActive}
              allCountries={regionsExcludingGlobal}
            />
            <Button
              onClick={() => {
                  history.push(
                    `/d/${drugId}/strategy/${strategyId}/1_3/${category}/${stakeholder}`
                  );
                }
              }
            >
              <Icon name={'Arrow-left'} size={40} color="white" />
              <ButtonText>Results</ButtonText>
            </Button>
          </NavbarControls>
        </NavbarContent>

        <Observations
          stakeholder={stakeholder}
          step={Step.Competitivelandscape}
        />
      </Navbar>

      <HeaderContainer>
        {!hasStakeholderError &&
          !hasNoTargetPatientsError &&
          !hasStakeholderNotIdentifiedError && (
            <StepHeader>
              {stakeholderDefinitionsLoading ? (
                <Loading style={{ width: 80, marginTop: 0 }} />
              ) : (
                <>
                  <Subtitle2 style={{ marginBottom: 5, marginTop: 5 }}>
                    Evaluate the competitive landscape
                  </Subtitle2>
                  <BodyNormal
                    color={colors.greyDark}
                    style={{ marginBottom: '15px' }}
                  >
                    Leads select which outcomes provide an opportunity for differentiation.
                  </BodyNormal>
                </>
              )}
            </StepHeader>
          )}
      </HeaderContainer>

      {(groups.length > 0 && groups2.length > 0) &&
        <EvaluationStakeHolder
          groups={groups}
          groupOutcomes={groupOutcomes}
          groups2={groups2}
          cardItems={cardItems}
          strategyId={+strategyId}
          drugId={+drugId}
          selectedRegion={selectedRegion}
          selectedRegions={selectedRegions}
          // stakeholder={'Patient'}
          stakeholderDefinitionId={+stakeholderDefinitionId}
          competitorId={+competitorId}
          competitors={activeCompetitors || []}
          showDistribution={showDistribution}
          setShowDistribution={setShowDistribution}
        />
      }

      {(groups.length === 0 && groups2.length === 0 && !groupsLoading) &&
        <div style={{ height: '100%', marginTop: 150 }}>
          <StyledPostItsEmpty
            title="Nothing to evaluate"
          >
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Work can only begin when a Lead has added the target population, outcomes and competitors
            </BodySmall>
            <div style={{ padding: 50 }}></div>
          </StyledPostItsEmpty>
        </div>
      }
    </ComponentWrapper>
  );
};