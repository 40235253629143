export const themes: any = {
  'discover': {
    'key': 'discover',
    'label': 'Discover',
    'tools': {
      '1.1': {
        'name': 'Patient Journey',
        'caption': 'What is their journey?'
      },
      '1.2': {
        'name': 'Key Stakeholder Analysis',
        'caption': 'Who are the stakeholders?'
      },
      '1.3': {
        'name': 'Competitive Analysis',
        'caption': 'Where is the potential for differentiation?'
      },
      '1.4': {
        'name': 'Key Trends Analysis',
        'caption': 'What opportunities and risks to we anticipate?'
      },
      '1.5': {
        'name': 'Insights',
        'caption': 'What insights are revealed?'
      }
    }
  },
  'explore': {
    'key': 'explore',
    'label': 'Explore',
    'tools': {
      '2.1': {
        'name': 'Strategic Question',
        'caption': 'What is our winning aspiration?'
      },
      '2.2': {
        'name': 'Patient Flow',
        'caption': 'Where are patients being lost?'
      },
      '2.3': {
        'name': 'Strategic Possibilities',
        'caption': 'What are our options?'
      },
      '2.4': {
        'name': 'Drivers and Barriers',
        'caption': 'Where are the risks?'
      },
      '2.5': {
        'name': 'Evidence Generation Ideas',
        'caption': 'How might we create value?'
      }
    }
  },
  'develop': {
    'key': 'develop',
    'label': 'Develop',
    'tools': {
      '3.1': {
        'name': 'Positioning',
        'caption': 'Differentiation'
      },
      '3.2': {
        'name': 'Core Value Messages',
        'caption': 'Value proposition'
      },
      '3.3': {
        'name': 'Strategic Imperatives',
        'caption': 'Competitive advantage'
      },
      '3.4': {
        'name': 'Goals and Distinctive Capabilities',
        'caption': 'Critical success factors'
      },
      '3.5': {
        'name': 'Lead and Lag Indicators',
        'caption': 'Critical metrics'
      }
    }
  },
  'activate': {
    'key': 'activate',
    'label': 'Activate',
    'tools': {
      '4.1': {
        'name': 'Medical Strategy',
        'caption': 'Enhancing medical practice'
      },
      '4.2': {
        'name': 'Access Strategy',
        'caption': 'Creating and sustaining patient access'
      },
      '4.3': {
        'name': 'Commercial Strategy',
        'caption': 'Shaping attitudes and beliefs'
      },
      '4.4': {
        'name': 'Risk Mitigation',
        'caption': 'Managing uncertainty'
      },
      '4.5': {
        'name': 'Long-term Strategy',
        'caption': 'Maintaining brand relevance'
      }
    }
  }
};

export const blocks: any = {
  // START: Discover
  'inspire': {
    'key': 'inspire',
    'label': 'Inspire',
    'theme': 'discover',
    'tools': ['1.1', '1.5']
  },
  'pulse': {
    'key': 'pulse',
    'label': 'Pulse',
    'theme': 'discover',
    'tools': ['1.2', '1.4', '1.5']
  },
  'scout': {
    'key': 'scout',
    'label': 'Scout',
    'theme': 'discover',
    'tools': ['1.3', '1.5']
  },
  // END: Discover

  // Start: Explore
  'beacon': {
    'key': 'beacon',
    'label': 'Beacon',
    'theme': 'explore',
    'tools': ['2.1']
  },
  'pathfinder': {
    'key': 'pathfinder',
    'label': 'Pathfinder',
    'theme': 'explore',
    'tools': ['2.2', '2.3', '2.4']
  },
  'proof': {
    'key': 'proof',
    'label': 'Proof',
    'theme': 'explore',
    'tools': ['2.5']
  },
  // End: Explore

  // Start: Develop
  'edge': {
    'key': 'edge',
    'label': 'Edge',
    'theme': 'develop',
    'tools': ['3.1', '3.4']
  },
  'convince': {
    'key': 'convince',
    'label': 'Convince',
    'theme': 'develop',
    'tools': ['3.2']
  },
  'focus': {
    'key': 'focus',
    'label': 'Focus',
    'theme': 'develop',
    'tools': ['3.3', '3.4', '3.5']
  },
  // End: Develop

  // Start: Activate
  'connect': {
    'key': 'connect',
    'label': 'Connect',
    'theme': 'activate',
    'tools': ['4.1', '4.2', '4.3']
  },
  'adapt': {
    'key': 'adapt',
    'label': 'Adapt',
    'theme': 'activate',
    'tools': ['4.4']
  },
  'horizon': {
    'key': 'horizon',
    'label': 'Horizon',
    'theme': 'activate',
    'tools': ['1.4', '4.5']
  }
};

export type NmblrThemes = keyof typeof themes;
export type NmblrBlocks = keyof typeof blocks;