import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { colorsv2, themes } from 'constants/index';
import { useContributionsQuery, useDrugsQuery } from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { BubbleSummary } from './Strategy/BubbleSummary';
import * as themeUtils from 'utils/themes';
import { StrategyBlock } from './Strategy';
import { ButtonPill, Heading4 } from './shared';
import { useHistory } from 'react-router-dom';
import { ErrorWrapper } from './ErrorLoadingComponent';
import { device } from 'utils/breakpoints';

interface Props {
  drugId: number;
  strategyId: number;
}

const PageWrapper = styled.div<{ expanded?: boolean }>`
  align-items: center;
  background: ${colorsv2.bg.surfaceBase2};
  display: flex;
  height: calc(100% - 47px);
  flex-direction: column;
  gap: 56px;
  position: fixed;
  top: 47px;
  padding: 56px 0px;
  overflow-x: hidden;
  overflow-y: ${({ expanded }) => expanded ? 'hidden' : 'scroll'};
  width: 100%;

  @media ${device.mobile} {
    gap: 16px;
    padding: 16px 0px;
  }
`;

const PromoContainer = styled.div`
  background: ${colorsv2.bg.surfaceStrong};
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1),
              0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  align-items: center;
  display: flex;
  height: 124px;
  position: fixed;
  padding: 16px;
  right: 16px;
  top: 64px;
  width: 373px;
`;

const PromoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PromoImage = styled.div`
  background: url(/WaveWithDots@2x.png) no-repeat;
  background-size: 100%;
  height: 92px;
  min-width: 129px;
  width: 129px;
`;

export const WelcomeScreen: React.FC<Props> = ({ drugId, strategyId }) => {
  const history = useHistory();
  const isDesktop = useDesktop();

  const {
    data: contributionsData,
    loading: contributionLoading,
  } = useContributionsQuery({
    variables: { where: { strategyId } },
    fetchPolicy: 'cache-and-network',
  });

  const { data: drugData } = useDrugsQuery({
    variables: { where: { id: drugId } },
    fetchPolicy: 'network-only',
  });

  const ProductOnDrugs = themeUtils.sortProductOnDrugs(
    drugData?.drugs?.items?.filter(
      (e) => e.id === drugId
    )[0]?.ProductOnDrugs
  );

  const [view, setView] = useState<string|null>(null);
  const [viewType, setViewType] = useState<string|null>(null);
  const [themeIndex, setThemeIndex] = useState<number>(0);

  const buildUrl = (page: string) => {
    return `/d/${drugId}/strategy/${strategyId}/${page}`;
  }

  if (!contributionsData || contributionLoading) {
    return (
      <ErrorWrapper 
        bg="dark"
        isLoading={true} 
        errors={[]} 
        dataMissing={false} 
      />
    );
  }

  return (
    <>
      <PageWrapper expanded={!isDesktop && !!view && viewType === 'tool'}>
        {ProductOnDrugs && ProductOnDrugs.map((drug: any, index: number) => {
          const tools = themeUtils.getBlockTools(drug.block.key);

          return (
            <StrategyBlock
              key={drug?.block?.key}
              drugId={drugId}
              strategyId={strategyId}
              block={drug?.block?.key}
              blockTools={tools}
              title={drug?.block?.label}
              theme={drug?.block?.theme}
              contributions={contributionsData?.contributions}
              expanded={!isDesktop && view === drug?.block?.key && viewType === 'tool'}
              onActionClick={(action: any) => {
                setView(drug?.block?.key);
                setViewType(action);
                setThemeIndex(Object.keys(themes).indexOf(drug?.block?.theme) + 1);
              }}
            />
          )
        })}

        {viewType === 'summary' && (
          <BubbleSummary
            closeBubbleSummary={() => setViewType(null)}
            strategyId={strategyId}
            bubble={themeIndex}
          />
        )}

        {isDesktop && ProductOnDrugs?.length !== 0 && (
          <PromoContainer>
            <PromoContent>
              <Heading4 weight="light">
                There’s a whole lot you can achieve with Nmblr.
              </Heading4>

              <ButtonPill
                text="Find out more"
                onClick={() => history.push(buildUrl(''))}
              />
            </PromoContent>
            <PromoImage />
          </PromoContainer>
        )}
      </PageWrapper>
    </>
  );
};
