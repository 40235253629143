import { Navbar as NavbarComponent } from 'components/Navbar';
import { StepTab, TabGroup } from 'components/shared';
import { useEffect } from 'react';
import { kebabCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import { PositioningTabKebabCase } from '../../types';
import { StepBarsWrapper, TabGroupWrapper } from '../StrategicQuestion';
import { SubStep } from '../../data/graphql/generated';
import { useGlobalContext } from 'contexts/GlobalContext';
import { KeyInsightsMenu } from 'components/KeyInsightsMenu';
import styled from 'styled-components';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';

type Props = {
  drugId: string;
  strategyId: string;
  competitorId: string;

  currentPositioningTab: PositioningTabKebabCase;
};

const NavbarContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: end;
  gap: 5px;
`;

export const ButtonContainerDesktop = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const positioningTabs = [
  { title: 'Competitor Positioning', subStep: SubStep.CompetitorPositioning },
  {
    title: 'The "Who"',
    subStep: SubStep.TheWho,
  },
  {
    title: 'The "Why"',
    subStep: SubStep.TheWhy,
  },
  {
    title: 'Decide',
    subStep: SubStep.TheDecide,
  },
];

export const Navbar = ({
  drugId,
  strategyId,
  competitorId,
  currentPositioningTab,
}: Props) => {
  const history = useHistory();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role);
  const showInsights = history.location.pathname.includes(kebabCase(SubStep.TheWho))

  const [brandPositioning, setBrandPositioning] = useGlobalContext(
    'brandPositioning'
  );

  useEffect(() => {
    // NOTE: Additional condition to prevent summary
    // from being pushed back to the history stack
    if (!competitorId
      && brandPositioning
      && !brandPositioning.includes(SubStep.TheDecide)
      && brandPositioning !== history.location.pathname
    ) {
      history.replace(
        brandPositioning
      );
    }
  }, [drugId, history, competitorId, brandPositioning]);
  return (
    <NavbarComponent pageNavChildren={<></>}>
      <TabGroupWrapper>
        <TabGroup componentName="StepTab">
          <StepBarsWrapper style={{ paddingBottom: !showInsights ? 10 : 0 }}>
            {positioningTabs?.map((p, idx) => {
              return (
                <StepTab
                  className={`cypress-stepTab-${kebabCase(p.subStep)}`}
                  key={p.subStep}
                  active={kebabCase(p.subStep) === currentPositioningTab}
                  text={`Part ${idx + 1}: ${p.title}`}
                  onClick={() => {
                    const url = `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCase(
                      p.subStep
                    )}/${competitorId || ''}`;
                    history.push(url);
                    setBrandPositioning(url)
                  }}
                />
              );
            })}

            <ButtonContainerDesktop>
              {showInsights && (
                <NavbarContent>
                  <KeyInsightsMenu
                    isLead={isLead}
                  />
                </NavbarContent>
              )}
            </ButtonContainerDesktop>
          </StepBarsWrapper>

        </TabGroup>
      </TabGroupWrapper>
    </NavbarComponent>
  );
};
