// V2 colors for rebranding
export const colorsv2 = {
  // START: Semantic colors
  bg: {
    brand: '#5131FF',
    brandHover: '#4A2DE9',
    brandDown: '#4027CA',
    brandDisabled: '#9885FF',
    brandExtraWeak: '#F7F5FF',
    danger: '',
    dangerWeak: '#FAEAE6',
    layer: '#38304D',
    layerHover: '#22156C',
    surface: '#FFFFFF',
    surfaceBrand: '#2C1B8B',
    surfaceBase: '#0A0023',
    surfaceBase2: '#14122F',
    surfaceMedium: '#EEF5FF',
    surfaceStrong: '#D4DEEA',

    // specific colors for themes / blocks
    // Activate
    activate: '#00F0AF',
    activateHover: '#29F2BC',
    activateDown: '#49F4C6',
    activateGradient: `radial-gradient(108.1% 108.1% at 88.6% 50%, rgba(81, 49, 255, 0.4) 0%, #00F0AF 100%)`,

    // Develop
    develop: '#DC78FA',
    developHover: '#E28EFB',
    developDown: '#E69EFB',
    developGradient: `radial-gradient(108.1% 108.1% at 88.6% 50%, rgba(81, 49, 255, 0.4) 0%, #DC78FA 100%) `,

    // Discover
    discover: '#FFBE1D',
    discoverHover: '#FFC942',
    discoverDown: '#FFD05D',
    discoverGradient: `radial-gradient(108.1% 108.1% at 88.6% 50%, rgba(81, 49, 255, 0.4) 0%, #FFBE1D 100%)`,

    // Explore
    explore: '#00C8FF',
    exploreHover: '#29D1FF',
    exploreDown: '#49D8FF',
    exploreGradient: `radial-gradient(108.1% 108.1% at 88.6% 50%, rgba(81, 49, 255, 0.4) 0%, #00C8FF 100%)`,
  },

  border: {
    brand: '#5131FF',
    default: '#0A0023',
    danger: '#C82F00',
    medium: '#BBB8C2',
    negative: '#FFF',
    weak: '#E7E6E9'
  },

  text: {
    brand: '#5131FF',
    default: '#0A0023',
    disabled: '#8F8B9B',
    danger: '#C82F00',
    dangerWeak: '#FAEAE6',
    weak: '#645D74',

    // specific colors for themes / blocks
    // Activate
    activate: '#00F0AF',

    // Develop
    develop: '#DC78FA',

    // Discover
    discover: '#FFBE1D',

    // Explore
    explore: '#00C8FF',
  },

  textInverted: {
    default: '#FFFFFF',
    weak: '#BBB8C2'
  },
  // END: Semantic colors

  // START: Primitive colors
  purple: {
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
    700: '',
    800: '',
    900: '',
    1000: '',
    1100: '',
    1200: '',
    1300: '',
    1400: '',
  },
  blue: {
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
    700: '',
    800: '',
    900: '',
    1000: '',
    1100: '',
    1200: '',
    1300: '',
    1400: '',
  },
  green: {
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
    700: '',
    800: '',
    900: '',
    1000: '',
    1100: '',
    1200: '',
    1300: '',
    1400: '',
  },
  yellow: {
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
    700: '',
    800: '',
    900: '',
    1000: '',
    1100: '',
    1200: '',
    1300: '',
    1400: '',
  },
  indigo: {
    50: '#F7F5FF',
    100: '#EEEAFF',
    200: '#D8D1FF',
    300: '#C3B8FF',
    400: '#AD9EFF',
    500: '#9885FF',
    600: '#836CFF',
    700: '#6D52FF',
    800: '#5131FF',
    900: '#4A2DE9',
    1000: '#4027CA',
    1100: '#3621AB',
    1200: '#2C1B8B',
    1300: '#22156C',
    1400: '#180F4D',
  },
  black: {
    10: '#FFFFFF',
    50: '#E7E6E9',
    100: '#BBB8C2',
    200: '#8F8B9B',
    300: '#645D74',
    400: '#38304D',
    500: '#0A0023',
    600: '#08001D',
    700: '#070017',
    800: '#050011',
    900: '#03000B'
  }
  // END: Primitive colors
}

export type ColorsV2 = keyof typeof colorsv2;

export const colors = {
  cream: '#FAF5E5',
  cream30: '#FDFCF7',

  darkRed: '#E51D5B',
  red: '#FF1E61',
  red50: '#FF8EB0',
  red30: '#FFBCD0',
  red25: '#FFC7D7',
  red20: '#FFD2DF',
  red10: '#FFE9EF',
  red05: '#FFF4F7',
  red03: '#FFF8FA',
  red02: '#FFFBFC',

  yellow: '#FFEFB1',
  yellow75: '#FFF3C4',
  yellow50: '#FFF7D8',
  yellow30: '#FFFAE8',
  yellow20a: 'rgba(255, 239, 177, 0.2)',
  yellow20: '#FFFCEF',
  yellow10: '#FFFDF7',
  yellow05: '#FFFEFB',

  purple: '#7800FF',
  purple70a: 'rgba(120, 0, 255,0.7)',
  purple60a: 'rgba(120, 0, 255,0.6)',
  purple25a: 'rgba(120, 0, 255,0.25)',
  purple10a: 'rgba(120, 0, 255,0.10)',
  purple05a: 'rgba(120, 0, 255,0.05)',
  purple80a: 'rgba(120, 0, 255, 0.8)',
  purple30a: 'rgba(120, 0, 255, 0.3)',
  purple60: '#A45CF5',
  purple50: '#BB80FF',
  purple30: '#D7B3FF',
  purple25: '#DDBFFF',
  purple23: '#E0C4FF',
  purple20: '#E4CCFF',
  purple10: '#F2E6FF',
  purple15: '#EBD9FF',
  purple05: '#F8F2FF',
  purple03: '#7800ff4d',

  purpleDark: '#6E02E9',
  purplePink: '#A001F0',
  purplePink30: 'rgba(160, 1, 240, 0.3)',

  green: '#01B67F',
  green50: '#80DABF',
  green40: '#99E2CC',
  green30: '#B3E9D9',
  green20: '#CCF0E5',
  green10: '#E6F8F2',
  green05: '#F2FBF9',
  green03: '#F7FDFB',
  greenDark: '#078766',

  blue: 'rgba(0, 161, 211, 1)',
  blue50: '#80D0E9',
  blue30: '#B3E3F2',
  blue20: '#CCECF6',
  blue40: '#BFE7F4',
  blue10: '#E6F6FB',
  blue15: '#D9F1F8',
  blue05: '#F2FAFD',

  blue50a: 'rgba(0, 161, 211, 0.5)',
  blue30a: 'rgba(0, 161, 211, 0.3)',
  blue20a: 'rgba(0, 161, 211, 0.2)',
  blue40a: 'rgba(0, 161, 211, 0.4)',
  blue10a: 'rgba(0, 161, 211, 0.1)',
  blue15a: 'rgba(0, 161, 211, 0.15)',
  blue05a: 'rgba(0, 161, 211, 0.05)',

  orange: '#FF521C',
  orange50: '#FFA88D',
  orange30: '#FFCBBB',
  orange20: '#FFDCD2',
  orange25: '#FFD4C6',

  orange10: '#FFEEE8',
  orange05: '#FFF6F4',
  burntOrange: '#B93F1F',

  black: '#141427',
  black90: '#2C2C3D',
  black80: '#434352',
  black70: '#5B5B68',
  black60: '#72727d',
  black50: '#898993',
  black40: 'rgba(20, 20, 39, 0.4)',
  black30: 'rgba(20, 20, 39, 0.3)',
  black20: '#D0D0D4',
  black10: '#1414271a',
  black05a: 'rgba(20, 20, 39, 0.05)',
  black05: '#F3F3F4',
  black02OnWhite: '#FAFAFB',

  black90a: 'rgba(20, 20, 39, 0.9)',
  black80a: 'rgba(20, 20, 39, 0.8)',
  black70a: 'rgba(20, 20, 39, 0.7)',
  black60a: 'rgba(20, 20, 39, 0.6)',
  black50a: 'rgba(20, 20, 39, 0.5)',
  black40a: 'rgba(20, 20, 39, 0.4)',
  black30a: 'rgba(20, 20, 39, 0.3)',
  black20a: 'rgba(20, 20, 39, 0.2)',
  black10a: 'rgba(20, 20, 39, 0.1)',

  white: '#FFFFFF',
  white94: 'rgba(255, 255, 255, 0.94)',
  white95: 'rgba(255, 255, 255, 0.95)',
  white90: 'rgba(255, 255, 255, 0.9)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white05: 'rgba(255, 255, 255, 0.05)',

  pink: '#FF00C8',
  pink50: '#FF80E3',
  pink30: '#FFB3EF',
  pink25: '#FFBFF1',
  pink20: '#FFCCF4',
  pink10: '#FFE6FA',
  pink05: '#FFF2FC',

  grey: '#F2F2F2',
  greyDark: '#696975',
  greyMedium: '#B9B9BE',
  greyLight: '#E8E8E9',
  grey03: '#F8F8F9',

  coloredGradient: 'linear-gradient(134deg, #3c4ec5, #be40ae)',
  fadedGradient: `radial-gradient(
    circle at bottom left,
    rgba(75, 126, 235, 0.1) 40%,
    rgba(83, 123, 234, 0.1) 0%,
    rgba(234, 78, 209, 0.1) 100%
  )`,
  purplePinkGradient: `linear-gradient(315deg, #FF00C8 -83%, #7800FF 93.75%)`,
  lightPinkGradient: `linear-gradient(
    90deg,
    rgb(255 255 255 / 90%) 0%,
    rgb(255 255 255 / 90%) 100%
  ),
  radial-gradient(
    circle at bottom left,
    rgba(10, 3, 18, 1) 0%,
    rgba(72, 16, 167, 1) 10%,
    rgba(75, 126, 235, 1) 20%,
    rgba(83, 123, 234, 1) 50%,
    rgba(234, 78, 209, 1) 60%,
    rgba(254, 247, 253, 1) 80% 100%
  )`,
  lightPinkGradientHover: `linear-gradient(
    50deg, rgba(227,224,250,1) 0%,
    rgba(225,234,252,1) 3%,
    rgba(227,234,252,1) 44%,
    rgba(238,227,249,1) 53%,
    rgba(253,233,249,1) 100%
    )`,
  bubbleSummaryGradient: `linear-gradient(
    50deg, rgba(0,0,0,1) 0%,
    rgba(64,20,149,1) 16%,
    rgba(62,73,195,1) 36%,
    rgba(78,99,191,1) 51%,
    rgba(156,53,174,1) 62%,
    rgba(170,49,172,1) 84%,
    rgba(198,170,197,1) 98%
    )`,
  redPurpleGradient: `linear-gradient(313.69deg, #7800FF -83%, #E51D5B 76.34%)`,
  greenPurpleGradient: `linear-gradient(313.69deg, #7800FF -83%, #078766 76.34%)`,
  black05OnWhiteGradient: `linear-gradient(0deg, #1414270d, #1414270d), #FFFFFF;`,
  bluePurpleFadedHover: `linear-gradient(313.69deg, rgba(120, 0, 255, 0.16) -83%, rgba(0, 161, 211, 0.16) 76.34%), #FFFFFF`,
  bluePurpleFaded: `linear-gradient(313.69deg, rgba(120, 0, 255, 0.06) -83%, rgba(0, 161, 211, 0.06) 76.34%), #FFFFFF`,
  bluePurple: `linear-gradient(313.69deg, rgba(120, 0, 255, 1) -83%, rgba(0, 161, 211, 1) 76.34%), #FFFFFF`,
  gradientPink: `rgba(255, 0, 200, 1)`,
  gradientPurple: `rgba(120, 0, 255, 1)`,
} as const;

export type Colors = keyof typeof colors;