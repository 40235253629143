import StrategyCreationPage from 'containers/StrategyCreationPage';
import {
  useStrategyUpdateMutation,
  useStrategyWithDrugQuery,
  useStrategyPublishMutation,
  StrategyFragment,
} from 'data/graphql/generated';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import {
  DrugDetails,
  Divider,
  DrugDetailBorderWrapper,
  DrugDetailWrapper,
  StrategicDrugDetailsWrapper,
} from 'containers/Strategies';
import Textarea from 'react-expanding-textarea';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import ErrorPage from 'components/ErrorPage';

const DrugDetailsContainer = styled.div`
  margin-top: 30px;

  ${StrategicDrugDetailsWrapper} {
    border: 1px solid ${colors.black10a};
    border-radius: 5px;
  }

  ${DrugDetailWrapper} {
    width: 100%;
  }

  @media ${device.tabletMin} {
    margin-bottom: 0;
  }

  @media ${device.desktopMin} {
    width: 559px;
  }
`;

const IndicationWrapper = styled(DrugDetailWrapper)`
  border: 1px solid ${colors.black10a};
  border-radius: 5px;
`;

interface Props {
  setFailedInvites: React.Dispatch<React.SetStateAction<boolean>>;
}

const StrategyDescriptionsCreate = ({ setFailedInvites }: Props) => {
  const history = useHistory();
  const { strategyId, drugId } = useParams<{
    strategyId: string;
    drugId: string;
  }>();
  const [strategyUpdate] = useStrategyUpdateMutation();
  const [strategyPublish] = useStrategyPublishMutation();
  const { data: strategy, loading, error } = useStrategyWithDrugQuery({
    variables: { id: +strategyId },
  });
  const [values, setValues] = React.useState<StrategyFragment | undefined>(
    undefined
  );

  async function updateStrategy() {
    if (!values) return;

    try {
      await strategyUpdate({
        variables: {
          id: +strategyId,
          data: {
            startYear: values.startYear,
            endYear: values.endYear,
            indication: values.indication,
            strategicProblem: values.strategicProblem,
            strategicQuestion: values.strategicQuestion,
            winningAspiration: values.winningAspiration,
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  const handleSave = () => {
    updateStrategy();
    history.push('/');
  };

  React.useEffect(() => {
    let data = strategy?.strategy;
    if (strategy?.strategy?.isDraft === false) history.push('/');

    if (data) {
      setValues(data);
    }
  }, [strategy, history]);

  if (loading) return <span>Loading...</span>;
  if (error) return <ErrorPage errorType="generic" />;
  if (!values) return <ErrorPage errorType="unpermitted" />;

  const updateField = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    setValues({ ...values, [target.name]: target.value });
  };

  async function handlePublish() {
    try {
      setFailedInvites(false);
      await strategyPublish({ variables: { id: +strategyId } });
      history.push(`/d/${drugId}/strategy/${strategyId}/create/confirm`);
    } catch (err) {
      console.error(err);
      if (err instanceof Error && err.message.includes('inviteUser')) {
        setFailedInvites(true);
        history.push(`/d/${drugId}/strategy/${strategyId}/create/confirm`);
      }
    }
  }

  return (
    <StrategyCreationPage
      subtext="Provide any of these descriptions or leave them blank if you’re unsure. You can update them later back at the brand page."
      title="Strategy description"
      handleSave={handleSave}
      nextButtonDisabled={!values}
      footerLeftButtonHandler={() =>
        history.push(`/d/${drugId}/strategy/${strategyId}/create/collaborators`)
      }
      footerNextButtonHandler={handlePublish}
    >
      <DrugDetailsContainer>
        <DrugDetails>
          <IndicationWrapper>
            <BodySmall>Indication</BodySmall>
            <Textarea
              className={'DrugDetailWrapper__textarea'}
              rows={2}
              value={String(values.indication)}
              onBlur={updateStrategy}
              name="indication"
              onChange={(e) => updateField(e)}
              placeholder="Describe the indication"
            ></Textarea>
          </IndicationWrapper>
          <DrugDetailBorderWrapper>
            <DrugDetailWrapper>
              <BodySmall>Winning aspiration</BodySmall>
              <Textarea
                className={'DrugDetailWrapper__textarea'}
                rows={2}
                value={String(values.winningAspiration)}
                onBlur={updateStrategy}
                name="winningAspiration"
                onChange={(e) => updateField(e)}
                placeholder="Define your winning aspiration"
              />
            </DrugDetailWrapper>
          </DrugDetailBorderWrapper>
          <StrategicDrugDetailsWrapper>
            <DrugDetails>
              <BodySmall>Strategic problem</BodySmall>
              <Textarea
                rows={2}
                className="DrugDetailWrapper__textarea"
                value={String(values.strategicProblem)}
                onBlur={updateStrategy}
                onChange={(e) => updateField(e)}
                name="strategicProblem"
                placeholder="Describe the problem"
              />
            </DrugDetails>
            <Divider></Divider>
            <div>
              <BodySmall>Strategic question</BodySmall>
              <Textarea
                rows={2}
                className="DrugDetailWrapper__textarea"
                value={String(values.strategicQuestion)}
                onBlur={updateStrategy}
                name="strategicQuestion"
                onChange={(e) => updateField(e)}
                placeholder='Rephrase problem as "How might we..."'
              />
            </div>
          </StrategicDrugDetailsWrapper>
        </DrugDetails>
      </DrugDetailsContainer>
    </StrategyCreationPage>
  );
};

export default StrategyDescriptionsCreate;
