export const countries = [
  // { name: 'abkhazia', img: 'abkhazia.png' },
  { name: 'afghanistan', img: 'afghanistan.png', code: 'af' },
  // { name: 'aland islands', img: 'aland-islands.png' },
  { name: 'albania', img: 'albania.png', code: 'al' },
  { name: 'algeria', img: 'algeria.png', code: 'dz' },
  { name: 'american samoa', img: 'american-samoa.png', code: 'as' },
  { name: 'andorra', img: 'andorra.png', code: 'ad' },
  { name: 'angola', img: 'angola.png', code: 'ao' },
  { name: 'anguilla', img: 'anguilla.png', code: 'ai' },
  { name: 'antigua and barbuda', img: 'antigua-and-barbuda.png', code: 'ag' },
  { name: 'argentina', img: 'argentina.png', code: 'ar' },
  { name: 'armenia', img: 'armenia.png', code: 'am' },
  { name: 'aruba', img: 'aruba.png', code: 'aw' },
  { name: 'australia', img: 'australia.png', code: 'au' },
  { name: 'austria', img: 'austria.png', code: 'at' },
  { name: 'azerbaijan', img: 'azerbaijan.png', code: 'az' },
  // { name: 'azores islands', img: 'azores-islands.png' },
  { name: 'bahamas', img: 'bahamas.png', code: 'bs' },
  { name: 'bahrain', img: 'bahrain.png', code: 'bh' },
  // { name: 'balearic islands', img: 'balearic-islands.png' },
  { name: 'bangladesh', img: 'bangladesh.png', code: 'bd' },
  { name: 'barbados', img: 'barbados.png', code: 'bb' },
  // { name: 'basque country', img: 'basque-country.png' },
  { name: 'belarus', img: 'belarus.png', code: 'by' },
  { name: 'belgium', img: 'belgium.png', code: 'be' },
  { name: 'belize', img: 'belize.png', code: 'bz' },
  { name: 'benin', img: 'benin.png', code: 'bj' },
  { name: 'bermuda', img: 'bermuda.png', code: 'bm' },
  { name: 'bhutan', img: 'bhutan.png', code: 'bt' },
  { name: 'bolivia', img: 'bolivia.png', code: 'bo' },
  { name: 'bonaire', img: 'bonaire.png', code: 'bq' },
  { name: 'bosnia and herzegovina', img: 'bosnia-and-herzegovina.png', code: 'ba' },
  { name: 'botswana', img: 'botswana.png', code: 'bw' },
  { name: 'brazil', img: 'brazil.png', code: 'br' },
  // { name: 'british columbia', img: 'british-columbia.png' },
  { name: 'british indian ocean territory', img: 'british-indian-ocean-territory.png', code: 'io' },
  // { name: 'british virgin islands', img: 'british-virgin-islands.png' },
  { name: 'brunei', img: 'brunei.png', code: 'bn' },
  { name: 'bulgaria', img: 'bulgaria.png', code: 'bg' },
  { name: 'burkina faso', img: 'burkina-faso.png', code: 'bf' },
  { name: 'burundi', img: 'burundi.png', code: 'bi' },
  { name: 'cambodia', img: 'cambodia.png', code: 'kh' },
  { name: 'cameroon', img: 'cameroon.png', code: 'cm' },
  { name: 'canada', img: 'canada.png', code: 'ca' },
  { name: 'canary islands', img: 'canary-islands.png', code: 'ic' },
  { name: 'cape verde', img: 'cape-verde.png', code: 'cv' },
  { name: 'cayman islands', img: 'cayman-islands.png', code: 'ky' },
  { name: 'central african republic', img: 'central-african-republic.png', code: 'cf' },
  // { name: 'ceuta', img: 'ceuta.png' },
  { name: 'chad', img: 'chad.png', code: 'td' },
  { name: 'chile', img: 'chile.png', code: 'cl' },
  { name: 'china', img: 'china.png', code: 'cn' },
  { name: 'christmas island', img: 'christmas-island.png', code: 'cx' },
  { name: 'cocos island', img: 'cocos-island.png', code: 'cc' },
  { name: 'colombia', img: 'colombia.png', code: 'co' },
  { name: 'comoros', img: 'comoros.png', code: 'km' },
  { name: 'cook islands', img: 'cook-islands.png', code: 'ck' },
  // { name: 'corsica', img: 'corsica.png' },
  { name: 'costa rica', img: 'costa-rica.png', code: 'cr' },
  { name: 'croatia', img: 'croatia.png', code: 'hr' },
  { name: 'cuba', img: 'cuba.png', code: 'cu' },
  { name: 'curacao', img: 'curacao.png', code: 'cw' },
  { name: 'cyprus', img: 'cyprus.png', code: 'cy' },
  // { name: 'czech republic', img: 'czech-republic.png' },
  // { name: 'democratic republic of congo', img: 'democratic-republic-of-congo.png' },
  { name: 'denmark', img: 'denmark.png', code: 'dk' },
  { name: 'djibouti', img: 'djibouti.png', code: 'dj' },
  { name: 'dominican republic', img: 'dominican-republic.png', code: 'do' },
  { name: 'dominica', img: 'dominica.png', code: 'dm' },
  // { name: 'east timor', img: 'east-timor.png' },
  { name: 'ecuador', img: 'ecuador.png', code: 'ec' },
  { name: 'egypt', img: 'egypt.png', code: 'eg' },
  { name: 'england', img: 'england.png', code: 'gb-eng' },
  { name: 'equatorial guinea', img: 'equatorial-guinea.png', code: 'gq' },
  { name: 'eritrea', img: 'eritrea.png', code: 'er' },
  { name: 'estonia', img: 'estonia.png', code: 'ee' },
  { name: 'ethiopia', img: 'ethiopia.png', code: 'et' },
  { name: 'european union', img: 'european-union.png', code: 'eu' },
  { name: 'falkland islands', img: 'falkland-islands.png', code: 'fk' },
  { name: 'faroe islands', img: 'faroe-islands.png', code: 'fo' },
  { name: 'fiji', img: 'fiji.png', code: 'fj' },
  { name: 'finland', img: 'finland.png', code: 'fi' },
  { name: 'france', img: 'france.png', code: 'fr' },
  { name: 'french polynesia', img: 'french-polynesia.png', code: 'pf' },
  { name: 'gabon', img: 'gabon.png', code: 'ga' },
  // { name: 'galapagos islands', img: 'galapagos-islands.png' },
  { name: 'gambia', img: 'gambia.png', code: 'gm' },
  { name: 'georgia', img: 'georgia.png', code: 'ge' },
  { name: 'germany', img: 'germany.png', code: 'de' },
  { name: 'ghana', img: 'ghana.png', code: 'gh' },
  { name: 'gibraltar', img: 'gibraltar.png', code: 'gi' },
  { name: 'greece', img: 'greece.png', code: 'gr' },
  { name: 'greenland', img: 'greenland.png', code: 'gl' },
  { name: 'grenada', img: 'grenada.png', code: 'gd' },
  { name: 'guam', img: 'guam.png', code: 'gu' },
  { name: 'guatemala', img: 'guatemala.png', code: 'gt' },
  { name: 'guernsey', img: 'guernsey.png', code: 'gg' },
  { name: 'guinea bissau', img: 'guinea-bissau.png', code: 'gw' },
  { name: 'guinea', img: 'guinea.png', code: 'gn' },
  { name: 'guyana', img: 'guyana.png', code: 'gy' },
  { name: 'haiti', img: 'haiti.png', code: 'ht' },
  // { name: 'hawaii', img: 'hawaii.png' },
  { name: 'honduras', img: 'honduras.png', code: 'hn' },
  { name: 'hong kong', img: 'hong-kong.png', code: 'hk' },
  { name: 'hungary', img: 'hungary.png', code: 'hu' },
  { name: 'iceland', img: 'iceland.png', code: 'is' },
  { name: 'india', img: 'india.png', code: 'in' },
  { name: 'indonesia', img: 'indonesia.png', code: 'id' },
  { name: 'iran', img: 'iran.png', code: 'ir' },
  { name: 'iraq', img: 'iraq.png', code: 'iq' },
  { name: 'ireland', img: 'ireland.png', code: 'ie' },
  { name: 'isle of man', img: 'isle-of-man.png', code: 'im' },
  { name: 'israel', img: 'israel.png', code: 'il' },
  { name: 'italy', img: 'italy.png', code: 'it' },
  // { name: 'ivory coast', img: 'ivory-coast.png' },
  { name: 'jamaica', img: 'jamaica.png', code: 'jm' },
  { name: 'japan', img: 'japan.png', code: 'jp' },
  { name: 'jersey', img: 'jersey.png', code: 'je' },
  { name: 'jordan', img: 'jordan.png', code: 'jo' },
  { name: 'kazakhstan', img: 'kazakhstan.png', code: 'kz' },
  { name: 'kenya', img: 'kenya.png', code: 'ke' },
  { name: 'kiribati', img: 'kiribati.png', code: 'ki' },
  // { name: 'kosovo', img: 'kosovo.png' },
  { name: 'kuwait', img: 'kuwait.png', code: 'kw' },
  { name: 'kyrgyzstan', img: 'kyrgyzstan.png', code: 'kg' },
  // { name: 'laos', img: 'laos.png' },
  { name: 'latvia', img: 'latvia.png', code: 'lv' },
  { name: 'lebanon', img: 'lebanon.png', code: 'lb' },
  { name: 'lesotho', img: 'lesotho.png', code: 'ls' },
  { name: 'liberia', img: 'liberia.png', code: 'lr' },
  { name: 'libya', img: 'libya.png', code: 'ly' },
  { name: 'liechtenstein', img: 'liechtenstein.png', code: 'li' },
  { name: 'lithuania', img: 'lithuania.png', code: 'lt' },
  { name: 'luxembourg', img: 'luxembourg.png', code: 'lu' },
  { name: 'macao', img: 'macao.png', code: 'mo' },
  { name: 'madagascar', img: 'madagascar.png', code: 'mg' },
  // { name: 'madeira', img: 'madeira.png' },
  { name: 'malawi', img: 'malawi.png', code: 'mw' },
  { name: 'malaysia', img: 'malaysia.png', code: 'my' },
  { name: 'maldives', img: 'maldives.png', code: 'mv' },
  { name: 'mali', img: 'mali.png', code: 'ml' },
  { name: 'malta', img: 'malta.png', code: 'mt' },
  { name: 'marshall island', img: 'marshall-island.png', code: 'mh' },
  { name: 'martinique', img: 'martinique.png', code: 'mq' },
  { name: 'mauritania', img: 'mauritania.png', code: 'mr' },
  { name: 'mauritius', img: 'mauritius.png', code: 'mu' },
  // { name: 'melilla', img: 'melilla.png' },
  { name: 'mexico', img: 'mexico.png', code: 'mx' },
  { name: 'micronesia', img: 'micronesia.png', code: 'fm' },
  { name: 'moldova', img: 'moldova.png', code: 'md' },
  { name: 'monaco', img: 'monaco.png', code: 'mc' },
  { name: 'mongolia', img: 'mongolia.png', code: 'mn' },
  { name: 'montenegro', img: 'montenegro.png', code: 'me' },
  { name: 'montserrat', img: 'montserrat.png', code: 'ms' },
  { name: 'morocco', img: 'morocco.png', code: 'ma' },
  { name: 'mozambique', img: 'mozambique.png', code: 'mz' },
  { name: 'myanmar', img: 'myanmar.png', code: 'mm' },
  { name: 'namibia', img: 'namibia.png', code: 'na' },
  // { name: 'nato', img: 'nato.png' },
  { name: 'nauru', img: 'nauru.png', code: 'nr' },
  { name: 'nepal', img: 'nepal.png', code: 'np' },
  { name: 'netherlands', img: 'netherlands.png', code: 'nl' },
  { name: 'new zealand', img: 'new-zealand.png', code: 'nz' },
  { name: 'nicaragua', img: 'nicaragua.png', code: 'ni' },
  { name: 'nigeria', img: 'nigeria.png', code: 'ng' },
  { name: 'niger', img: 'niger.png', code: 'ne' },
  { name: 'niue', img: 'niue.png', code: 'nu' },
  { name: 'norfolk island', img: 'norfolk-island.png', code: 'nf' },
  // { name: 'northen cyprus', img: 'northen-cyprus.png' },
  { name: 'northern marianas islands', img: 'northern-marianas-islands.png', code: 'mp' },
  { name: 'north korea', img: 'north-korea.png', code: 'kp' },
  { name: 'norway', img: 'norway.png', code: 'no' },
  { name: 'oman', img: 'oman.png', code: 'om' },
  // { name: 'orkney islands', img: 'orkney-islands.png' },
  // { name: 'ossetia', img: 'ossetia.png' },
  { name: 'pakistan', img: 'pakistan.png', code: 'pk' },
  { name: 'palau', img: 'palau.png', code: 'pw' },
  { name: 'palestine', img: 'palestine.png', code: 'ps' },
  { name: 'panama', img: 'panama.png', code: 'pa' },
  { name: 'papua new guinea', img: 'papua-new-guinea.png', code: 'pg' },
  { name: 'paraguay', img: 'paraguay.png', code: 'py' },
  { name: 'peru', img: 'peru.png', code: 'pe' },
  { name: 'philippines', img: 'philippines.png', code: 'ph' },
  { name: 'pitcairn islands', img: 'pitcairn-islands.png', code: 'pn' },
  { name: 'portugal', img: 'portugal.png', code: 'pt' },
  { name: 'puerto rico', img: 'puerto-rico.png', code: 'pr' },
  { name: 'qatar', img: 'qatar.png', code: 'qa' },
  // { name: 'rapa nui', img: 'rapa-nui.png' },
  { name: 'republic of macedonia', img: 'republic-of-macedonia.png', code: 'mk' },
  { name: 'republic of poland', img: 'republic-of-poland.png', code: 'pl' },
  // { name: 'republic of the congo', img: 'republic-of-the-congo.png' },
  { name: 'romania', img: 'romania.png', code: 'ro' },
  { name: 'russia', img: 'russia.png', code: 'ru' },
  { name: 'rwanda', img: 'rwanda.png', code: 'rw' },
  // { name: 'saba island', img: 'saba-island.png' },
  { name: 'saint kitts and nevis', img: 'saint-kitts-and-nevis.png', code: 'kn' },
  { name: 'salvador', img: 'salvador.png', code: 'sv' },
  { name: 'samoa', img: 'samoa.png', code: 'ws' },
  { name: 'san marino', img: 'san-marino.png', code: 'sm' },
  { name: 'sao tome and principe', img: 'sao-tome-and-principe.png', code: 'st' },
  // { name: 'sardinia', img: 'sardinia.png' },
  { name: 'saudi arabia', img: 'saudi-arabia.png', code: 'sa' },
  { name: 'scotland', img: 'scotland.png', code: 'gb-sct' },
  { name: 'senegal', img: 'senegal.png', code: 'sn' },
  { name: 'serbia', img: 'serbia.png', code: 'rs' },
  { name: 'seychelles', img: 'seychelles.png', code: 'sc' },
  { name: 'sierra leone', img: 'sierra-leone.png', code: 'sl' },
  { name: 'singapore', img: 'singapore.png', code: 'sg' },
  { name: 'sint eustatius', img: 'sint-eustatius.png', code: 'bq' },
  { name: 'sint maarten', img: 'sint-maarten.png', code: 'sx' },
  { name: 'slovakia', img: 'slovakia.png', code: 'sk' },
  { name: 'slovenia', img: 'slovenia.png', code: 'si' },
  { name: 'solomon islands', img: 'solomon-islands.png', code: 'sb' },
  { name: 'somalia', img: 'somalia.png', code: 'so' },
  // { name: 'somaliland', img: 'somaliland.png' },
  { name: 'south africa', img: 'south-africa.png', code: 'za' },
  { name: 'south korea', img: 'south-korea.png', code: 'kr' },
  { name: 'south sudan', img: 'south-sudan.png', code: 'ss' },
  { name: 'spain', img: 'spain.png', code: 'es' },
  { name: 'sri lanka', img: 'sri-lanka.png', code: 'lk' },
  // { name: 'st barts', img: 'st-barts.png' },
  // { name: 'st lucia', img: 'st-lucia.png' },
  // { name: 'st vincent and the grenadines', img: 'st-vincent-and-the-grenadines.png' },
  { name: 'sudan', img: 'sudan.png', code: 'sd' },
  { name: 'suriname', img: 'suriname.png', code: 'sr' },
  // { name: 'swaziland', img: 'swaziland.png' },
  { name: 'sweden', img: 'sweden.png', code: 'se' },
  { name: 'switzerland', img: 'switzerland.png', code: 'ch' },
  { name: 'syria', img: 'syria.png', code: 'sy' },
  { name: 'taiwan', img: 'taiwan.png', code: 'tw' },
  { name: 'tajikistan', img: 'tajikistan.png', code: 'tj' },
  { name: 'tanzania', img: 'tanzania.png', code: 'tz' },
  { name: 'thailand', img: 'thailand.png', code: 'th' },
  // { name: 'tibet', img: 'tibet.png' },
  { name: 'togo', img: 'togo.png', code: 'tg' },
  { name: 'tokelau', img: 'tokelau.png', code: 'tk' },
  { name: 'tonga', img: 'tonga.png', code: 'to' },
  // { name: 'transnistria', img: 'transnistria.png' },
  { name: 'trinidad and tobago', img: 'trinidad-and-tobago.png', code: 'tt' },
  { name: 'tunisia', img: 'tunisia.png', code: 'tn' },
  { name: 'turkey', img: 'turkey.png', code: 'tr' },
  { name: 'turkmenistan', img: 'turkmenistan.png', code: 'tm' },
  { name: 'turks and caicos', img: 'turks-and-caicos.png', code: 'tc' },
  { name: 'tuvalu', img: 'tuvalu.png', code: 'tv' },
  { name: 'uganda', img: 'uganda.png', code: 'ug' },
  { name: 'ukraine', img: 'ukraine.png', code: 'ua' },
  { name: 'united arab emirates', img: 'united-arab-emirates.png', code: 'ae' },
  { name: 'united kingdom', img: 'united-kingdom.png', code: 'gb' },
  { name: 'united states of america', img: 'united-states-of-america.png', code: 'us' },
  { name: 'uruguay', img: 'uruguay.png', code: 'uy' },
  { name: 'uzbekistan', img: 'uzbekistan.png', code: 'uz' },
  { name: 'vanuatu', img: 'vanuatu.png', code: 'vu' },
  // { name: 'vatican city', img: 'vatican-city.png' },
  { name: 'venezuela', img: 'venezuela.png', code: 've' },
  { name: 'vietnam', img: 'vietnam.png', code: 'vn' },
  { name: 'virgin islands', img: 'virgin-islands.png', code: 'vi' },
  // { name: 'wales', img: 'wales.png' },
  { name: 'western sahara', img: 'western-sahara.png', code: 'eh' },
  { name: 'yemen', img: 'yemen.png', code: 'ye' },
  { name: 'zambia', img: 'zambia.png', code: 'zm' },
  { name: 'zimbabwe', img: 'zimbabwe.png', code: 'zw' },
] as const;
