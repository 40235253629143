import { colorsv2 } from 'constants/index';
import styled from 'styled-components';

export const WaveBGStyles = `
  background: ${colorsv2.bg.surfaceBase2};
  background-image: url(/Wave@2x.png);
  background-size: 1377px 944px;
  background-repeat: no-repeat;
  background-position: center 64px;
`

export const WaveBG = styled.div`
  ${WaveBGStyles}
`;