import React from 'react';
import styled, { css } from 'styled-components';

import { colorsv2 } from 'constants/colors';

const Container = styled.div<{ checked: boolean }>`
  width: 16px;
  height: 16px;
  border: 1.5px solid
    ${({ checked }) => (checked ? colorsv2.border.brand : colorsv2.border.default)};
  background: ${({ checked }) => (checked ? colorsv2.border.weak : '')};
  border-radius: 50%;
  cursor: pointer;
  transition: border;
  position: relative;

  &:hover {
    border-color: ${({ checked }) =>
      checked ? colorsv2.border.brand : colorsv2.border.default };
  }

  ${({ checked }) =>
    checked
      ? css`
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            top: 50%;
            left: 50%;
            transform: translate(-5px, -5px);
            border-radius: 50%;
            background-color: ${colorsv2.border.brand};
          }
        `
      : ''}
`;

interface Props {
  checked?: boolean;
  onClick?(): void;
}

export const Radio: React.FC<Props> = ({ checked = false, onClick }) => {
  return <Container onClick={onClick} checked={checked} />;
};
