import { CircularProgressBar } from 'components/CircularProgressBar';
import {
  BodyLarge,
  BodySmall,
  Caption,
  Collaboration,
  Subtitle2,
} from 'components/shared';
import { colors } from 'constants/colors';
import { CollaborationForPreviewFragment } from 'data/graphql/generated';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  margin-top: 15px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 442px;
`;

const AlignmentCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 100%;

  .resultDetails {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  background: ${colors.white};
  border-radius: 5px;
  border: 1px solid ${colors.black10};

  display: flex;
  flex-direction: column;
  padding: 30px;
`;

interface Props {
  header?: React.ReactNode;
  title: string;
  collaboration?: CollaborationForPreviewFragment | null;
  style?: CSSProperties;
  onClick?(): void;
  className?: string;
  totalUsers?: number;
  largeTitle?: boolean;
  showAligned?: boolean;
}

export const StatementV2: React.FC<Props> = ({
  children,
  header,
  title,
  collaboration,
  style,
  onClick,
  className,
  totalUsers = 0,
  largeTitle,
  showAligned = true,
}) => {
  return (
    <Wrapper className={className} style={style}>
      {header}
      <div onClick={onClick}>
        {largeTitle ? (
          <Subtitle2 style={{ marginBottom: 5 }} color={colors.black}>
            {title}
          </Subtitle2>
        ) : (
          <BodySmall style={{ marginBottom: 5 }} color={colors.greyDark}>
            {title}
          </BodySmall>
        )}
        {children}
      </div>
      {!!collaboration && (
        <FooterWrapper className={'StatementV2__footerWrapper'}>
          {showAligned && (
            <AlignmentCountWrapper>
              <CircularProgressBar
                progress={(collaboration.alignmentCount / totalUsers) * 100}
                stroke={2}
                size={25}
              />

              <div className="resultDetails">
                <BodyLarge>{collaboration?.alignmentCount}</BodyLarge>
                <Caption
                  color={colors.greyDark}
                >{`/${totalUsers}${' aligned'}`}</Caption>
              </div>
            </AlignmentCountWrapper>
          )}
          <Collaboration collaboration={collaboration} />
        </FooterWrapper>
      )}
    </Wrapper>
  );
};

export const StatementWrapper = styled.div<{ canEdit: boolean }>`
  > p {
    display: inline;
  }
  .statement__text--highlight {
    background: ${colors.purple10};
    border-radius: 5px;
    cursor: ${({ canEdit }) => (canEdit ? 'pointer' : 'not-allowed')};
    pointer-events: all;
    word-break: break-word;

    -webkit-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
  }
`;
