import React from 'react';
import styled, { css } from 'styled-components/macro';
import { WaveBGStyles } from './shared/PageBackground';

const BG = styled.div<{  
  bg?: string, 
  fixed?: boolean,
  height?: string | number,
  paddingTop: boolean; 
}>`
  padding-top: ${({ paddingTop }) => (paddingTop ? 120 : 30)}px;
  display: block;
  overflow-x: hidden;
  padding-bottom: 50px;
  height: ${({ height }) => (height ? String(height) : 'auto')};

  ${({ bg }) => bg === 'wave' && css`
    ${WaveBGStyles}
  `}

  ${({ fixed }) => fixed && css`
    height: 100%;
    position: fixed;
    width: 100%;
  `}
`;

export const Wrapper = styled.div<{ fullWidthMobile: boolean }>`
  max-width: 1400px;
  width: 90%;
  display: block;
  margin: 0px auto;

  ${({ fullWidthMobile }) =>
    fullWidthMobile
      ? css`
          @media (max-width: 480px) {
            width: 100%;
          }
        `
      : ``}
`;

interface Props {
  paddingTop?: boolean;
  className?: string;
  height?: string | number;
  fullWidthMobile?: boolean;
  bg?: string;
  fixed?: boolean;
}

export const Page: React.FC<Props> = ({
  className,
  children,
  paddingTop = true,
  height,
  fullWidthMobile = false,
  bg = '',
  fixed = false
}) => {
  return (
    <BG 
      bg={bg}
      className={className} 
      paddingTop={paddingTop} 
      height={height}
      fixed={fixed}
    >
      <Wrapper fullWidthMobile={fullWidthMobile}>{children}</Wrapper>
    </BG>
  );
};
