import styled from 'styled-components/macro';
import { colorsv2, texts } from 'constants/index';
import { toCss } from 'utils/toCss';

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledText = styled.p`
  ${() => toCss(texts.paragraph300)}
  margin: 0;
  margin-left: 10px;
  text-transform: capitalize;

  color: ${colorsv2.text.default};
`;
