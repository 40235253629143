import { ButtonLabel, Caption, CountryFlag, Icon } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import { CountryGlobal, CountryGlobalRegional } from 'types';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { countries } from 'constants/countries';

const StyledIcon = styled(Icon)<{ size: number }>`
  svg {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
  }
`;

const Wrapper = styled.div<{ active?: boolean }>`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: ${({ active }) =>
    active ? colors.purple80a : 'transparent'};
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
  &:hover {
    background-color: ${({ active }) =>
      active ? colors.purple80a : colors.purple30a};
  }
`;

const ToggleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  justify-content: center;
`;

interface Props {
  country?: CountryGlobal;
  active?: boolean;
  onClick?(e?: React.MouseEvent<HTMLDivElement>): void;
  viewOnly?: boolean;
  className?: string;
  hideCountryName?: boolean;
  largeIcon?: boolean;
  globalOptionText?: string;
  multiSelect?: boolean;
  selectedRegions?: CountryGlobal[];
  allCountries?: CountryGlobal[];
}

export const CustomCountriesDropdownToggle: React.FC<Props> = ({
  country,
  active,
  onClick,
  children,
  viewOnly,
  className,
  hideCountryName,
  largeIcon,
  globalOptionText,
  multiSelect,
  selectedRegions,
  allCountries,
}) => {
  const adjustedAllCountries = allCountries?.includes('global') 
    ? allCountries 
    : [...(allCountries || []), 'global'];
    
  const multipleRegionsSelected =
    selectedRegions && selectedRegions?.length > 1;
  const firstSelectedRegion = selectedRegions?.[0];
  const globalSelected = selectedRegions?.includes('global');
  const noRegionsSelected = selectedRegions?.length === 0;
  const nonGlobalRegion = selectedRegions?.find((r) => r !== 'global');
  const allRegionsSelected = selectedRegions?.length === adjustedAllCountries?.length;

  return (
    <Wrapper
      className={`${className} cypress-country-dropdown-toggle`}
      active={active}
      onClick={(e) => onClick && onClick(e)}
    >
      <div>
        {!multiSelect ? (
          country === 'global' ? (
            <StyledIcon name="Global" size={30} color={colors.black} />
          ) : (
            <CountryFlag country={country} size="sm" />
          )
        ) : multipleRegionsSelected  ? (
          <StyledIcon name="Global" size={30} color={colors.black} />
        )  : noRegionsSelected ? (
          <StyledIcon name="Global" size={30} color={colors.black} />
        ) : globalSelected ? (
          <StyledIcon name="Global" size={30} color={colors.black} />
        ) : (
          <CountryFlag
            country={nonGlobalRegion as CountryGlobalRegional}
            size="sm"
          />
        )}
      </div>

      <ToggleTextWrapper>
        {!hideCountryName ? (
          multiSelect ? (
            noRegionsSelected ? (
              <ButtonLabel>0</ButtonLabel>
            ) : allRegionsSelected ? (
              <ButtonLabel>All</ButtonLabel>
            ) : multipleRegionsSelected ? (
              <ButtonLabel>{selectedRegions?.length}</ButtonLabel>
            ) : globalSelected ? (
              <ButtonLabel>1</ButtonLabel>
            ) : (
              <ButtonLabel>
                {firstSelectedRegion === 'global' 
                  ? _.startCase(firstSelectedRegion)
                  : countries.find(c => 'code' in c && c.name.toLowerCase() === firstSelectedRegion?.toLowerCase())?.code?.toUpperCase() || _.startCase(firstSelectedRegion)}
              </ButtonLabel>
            )
          ) : (
            <ButtonLabel color={colors.white}>
              {country === 'global' && globalOptionText
                ? _.capitalize(globalOptionText)
                : countries.find(c => c.name.toLowerCase() === country?.toLowerCase())?.code || _.startCase(country)}
            </ButtonLabel>
          )
        ) : null}

        {viewOnly ? (
          <Caption color={colors.greyMedium}>View only</Caption>
        ) : null}
      </ToggleTextWrapper>

      <Icon
        name={active ? 'Chevron-up' : 'Chevron-down'}
        size={largeIcon ? 30 : 20}
        color={colors.white}
        className="chevron"
      />
      {children}
    </Wrapper>
  );
};
