import ErrorPage from 'components/ErrorPage';
import { ModalConfirmDelete } from 'components/ModalConfirmDelete';
import { Radio, BodySmall, ButtonLabel } from 'components/shared';
import { currencies } from 'constants/index';
import { colors, colorsv2 } from 'constants/index';
import {
  DrugsDocument,
  Stakeholder,
  useDrugQuery,
  useStakeholderDefinitionCreateMutation,
  useStakeholderDefinitionsQuery,
  useStakeholderDefinitionUpdateMutation,
  useStrategyDeleteMutation,
  useStrategyQuery,
  useStrategyUpdateMutation,
} from 'data/graphql/generated';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import StrategyCreationPage, {
  ModalStrategy,
} from '../../containers/StrategyCreationPage';
import FormTextInput from 'components/shared/FormTextInput';

const StrategyPeriodPage = styled.div`
  min-height: calc(100vh - 50px);
    @media ${device.desktopMin} {
      ${ModalStrategy} {
    }
  }
`;

const TimeLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
`;

const StratCurrency = styled.div`
`;

const StratCurrencies = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

const CurrencyOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  p {
    white-space: nowrap;
  }
`;

const StrategyPeriodCreate = () => {
  const [targetPopulation, setTargetPopulation] = React.useState<string>('');
  const [currency, setCurrency] = React.useState<string>('');
  const history = useHistory();
  const [strategyUpdate] = useStrategyUpdateMutation();
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();
  const [strategyDelete] = useStrategyDeleteMutation();

  const [
    discardStrategyModal,
    setDiscardStrategyModal,
  ] = React.useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'targetPopulation') {
      setTargetPopulation(e.target.value);
    }
  };

  const openDiscardStrategyModal = () => setDiscardStrategyModal(true);

  const [
    createStakeholderDefinition,
  ] = useStakeholderDefinitionCreateMutation();

  const [
    updateStakeholderDefinition
  ] = useStakeholderDefinitionUpdateMutation();

  const [ updateLoading, setUpdateLoading ] = React.useState<boolean>(false);

  const { 
    data: strategyData, 
    error: strategyError, 
    loading: strategyLoading
  } = useStrategyQuery({
    variables: {
      id: Number(strategyId),
    },
  });

  const { 
    data: drugData, 
    loading, 
    error: drugError 
  } = useDrugQuery({
    variables: { id: Number(drugId) },
  });

  const {
    data: stakeholderDefinitions,
    loading: stakeholderDefinitionsLoading,
  } = useStakeholderDefinitionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { 
        id: strategyData?.strategy?.stakeholderDefinitionId, 
        strategyId: strategyData?.strategy?.id || 0
      } 
    },
  });

  const stakeholderDefinition = React.useMemo(() => (
    stakeholderDefinitions?.stakeholderDefinitions?.items?.[0] || null
  ), [stakeholderDefinitions]);

  const stepValid = targetPopulation !== '' && currency !== '';
  const isLoading = loading || strategyLoading || stakeholderDefinitionsLoading;

  const handleSave = async () => {
    try {
      setUpdateLoading(true);

      let response = null;
      if (stakeholderDefinition) {
        response = await updateStakeholderDefinition({
          variables: {
            id: stakeholderDefinition.id,
            data: {
              title: targetPopulation,
            },
          },
        });

        response = response.data?.stakeholderDefinitionUpdate;
      } else {
        response = await createStakeholderDefinition({
          variables: {
            data: {
              stakeholder: Stakeholder.Patient,
              image: '',
              title: targetPopulation,
              strategy: Number(strategyId),
              drug: Number(drugId),
            },
          },
        });

        response = response.data?.stakeholderDefinitionCreate;
      }

      await strategyUpdate({
        variables: {
          id: +strategyId,
          data: {
            startYear: null,
            stakeholderDefinitionId: response?.id,
            endYear: null,
            currency,
          },
        },
        refetchQueries: [{ query: DrugsDocument }],
      });

      history.push('/');
    } catch (err) {
      console.error(err);
    }

    setUpdateLoading(false);
  }

  const discardStrategy = async () => {
    try {
      setUpdateLoading(true);

      await strategyDelete({
        variables: { id: +strategyId },
        refetchQueries: [{ query: DrugsDocument }],
      });

      console.info(`Strategy with id ${strategyId} successfully deleted`);
      history.push('/');
    } catch (err) {
      console.error(err);
    }

    setUpdateLoading(false);
  }

  const saveAndContinue = async () => {
    try {
      setUpdateLoading(true);

      let response: any = null;
      if (stakeholderDefinition) {
        console.log('updating stakeholder definition');
        response = await updateStakeholderDefinition({
          variables: {
            id: stakeholderDefinition.id,
            data: {
              title: targetPopulation,
            },
          }
        });

        response = response.data?.stakeholderDefinitionUpdate;
      } else {
        response = await createStakeholderDefinition({
          variables: {
            data: {
              stakeholder: Stakeholder.Patient,
              image: '',
              title: targetPopulation,
              strategy: Number(strategyId),
              drug: Number(drugId),
            },
          },
        });

        response = response.data?.stakeholderDefinitionCreate;
      }

      await strategyUpdate({
        variables: {
          id: +strategyId,
          data: {
            startYear: null,
            stakeholderDefinitionId: response?.id,
            endYear: null,
            currency,
          },
        },
        refetchQueries: [{ query: DrugsDocument }],
      });

      history.push(`/d/${drugId}/strategy/${strategyId}/create/collaborators`);
    } catch (err) {
      console.error(err);
    }

    setUpdateLoading(false);
  }

  React.useEffect(() => {
    if (strategyData?.strategy?.isDraft === false) {
      history.push('/');
      return;
    }

    if (isLoading || updateLoading) {
      return;
    }

    setTargetPopulation(() => stakeholderDefinition?.title || '');
    setCurrency(() => strategyData?.strategy?.currency || '');
  }, [
    strategyData, 
    stakeholderDefinition,
    isLoading,
    updateLoading,
    history
  ]);

  if ((strategyError || drugError) && !isLoading) {
    return <ErrorPage errorType="generic" />;
  }

  if ((!strategyData || !drugData) && !isLoading) {
    return <ErrorPage errorType="unpermitted" />;
  }

  return (
    <StrategyPeriodPage>
      <ModalConfirmDelete
        title="Discard strategy?"
        visible={discardStrategyModal}
        text="This strategy and details you’ve provided for it will be lost."
        leftButtonText="Cancel"
        rightButtonText="Discard"
        handleClose={() => setDiscardStrategyModal(false)}
        handleConfirm={discardStrategy}
      />

      <StrategyCreationPage
        title="Target Population and Currency"
        nextButtonDisabled={!stepValid}
        handleSave={handleSave}
        footerLeftButtonColor={colorsv2.text.danger}
        footerLeftButtonHandler={openDiscardStrategyModal}
        footerNextButtonHandler={saveAndContinue}
        isLoading={isLoading}
      >
        <TimeLine>
          <FormTextInput
            type='text'
            title="Target population"
            name="targetPopulation"
            value={targetPopulation}
            onChange={(e) => handleChange(e)}
          />
        </TimeLine>

        <StratCurrency>
          <BodySmall style={{ color: colors.black, marginBottom: 10 }}>
            Global currency
          </BodySmall>

          <StratCurrencies>
            {currencies.map((c) => (
              <CurrencyOption
                className="cypress-strategy-currency"
                onClick={() => setCurrency(c)}
                key={c}
              >
                <Radio checked={c === currency} />
                <ButtonLabel style={{ marginLeft: 6 }}>{c}</ButtonLabel>
              </CurrencyOption>
            ))}
          </StratCurrencies>
        </StratCurrency>
      </StrategyCreationPage>
    </StrategyPeriodPage>
  );
};

export default StrategyPeriodCreate;
