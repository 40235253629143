import { ButtonsContainer } from 'components/CompetitiveLandscape/CreateUpdateCompetitorModal';
import { ModalForm } from 'components/ModalForm';
import { BodySmall, ButtonPill, StatementV2, Tooltip } from 'components/shared';
import { EditableUnderlineTextDiv } from 'components/shared/EditableUnderlineTextDiv';
import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import {
  StatementFragment,
  StrategyFragment,
  useStatementUpdateMutation,
} from 'data/graphql/generated';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { verifyUserRole } from 'utils/verifyUserRole';
import { PostIts, TransitionWrapper } from '../../PostIts';
import { StatementWrapper } from '../StatementV2';
import { TheWhoCardGroups } from './TheWhoCardGroups';
import { useWidth } from 'hooks/useWidth';

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;

  @media ${device.tabletMax} {
    flex-direction: column-reverse;
    width: 100%;
    padding: 0 15px;
  }

  @media ${device.mobile} {
    padding: 0;
  }
`;

const Wrapper = styled.div<{ width: number }>`
  width: 100%;

  ${TransitionWrapper} {
    .postit-group {
      width: ${({ width }) =>
        `${
          width >= 1800 ? Math.floor(width / 1.8 + 120) : Math.floor(width / 1.5 - 40)
        }`}px;
      display: block;
      border: 1px solid ${colors.black10};
      border-radius: 0;
      margin-bottom: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      @media ${device.tabletMax} {
        border-radius: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      @media ${device.mobile} {
        padding: 0;
      }
    }

    width: 100%;
    display: flex;
    margin-bottom: 20px;

    @media ${device.tabletMax} {
      flex-direction: column-reverse;
      width: 100%;
      padding: 0 15px;
      gap: 0;

      .postit-group {
        width: 100%;
      }
    }

    @media ${device.mobile} {
      border-radius: 0;
      padding: 0;
    }
  }
`;

const StyledStatementV2 = styled(StatementV2)`
  flex: 1;
  width: 100%;
  max-width: 345px;
  min-width: 345px;
  border-radius: 0;
  height: auto !important;
  margin-bottom: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  @media ${device.tabletMax} {
    width: 100%;
    max-width: unset;
    padding: 15px;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  @media ${device.mobile} {
    border-radius: 0;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  white-space: pre-wrap;

  > * {
    display: inline-block;
  }
`;

interface Props {
  statements: StatementFragment[] | undefined;
  strategyData: StrategyFragment | undefined | null;
  updateStatement: ReturnType<typeof useStatementUpdateMutation>[0];
  postIts: ComponentProps<typeof PostIts>;
}

export const TheWho = ({
  statements,
  strategyData,
  updateStatement,
  postIts,
}: Props) => {
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const drugName = strategyData?.drug?.name;
  const width = useWidth();

  const mapGroupToStatement = useMemo(
    () =>
      statements?.reduce((prev, curr) => {
        curr.postItGroupId &&
          (prev[curr.postItGroupId as keyof typeof prev] = curr);

        return prev;
      }, {} as Record<number, StatementFragment>) || {},
    [statements]
  );

  return (
    <Wrapper width={width}>
      <Tooltip id={'statement__tooltip'} effect="float" place="right" />

      <ContentWrapper>
        <TheWhoCardGroups
          postIts={postIts}
          Content={(groupId) => {
            const statement = mapGroupToStatement[groupId];

            if (!statement) return null;
            return (
              <Statement
                statement={statement}
                strategyData={strategyData}
                isLead={isLead}
                drugName={drugName}
                updateStatement={updateStatement}
              />
            );
          }}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

function Modal({
  setStatementModalOpen,
  statementModalOpen,
  drugName,
  statement,
  updateStatement,
}: {
  setStatementModalOpen: (b: boolean) => void;
  statementModalOpen: boolean;
  drugName: string | undefined;
  statement: StatementFragment | undefined;
  updateStatement: ReturnType<typeof useStatementUpdateMutation>[0];
}) {
  const [modalStatement0Text, setModalStatement0Text] = useState(
    statement?.text[0] || ''
  );
  const [modalStatement1Text, setModalStatement1Text] = useState(
    statement?.text[1] || ''
  );

  const [isModalSubmitLoading, setIsModalSubmitLoading] = useState(false);

  useEffect(() => {
    if (typeof statement?.text[0] === 'string') {
      setModalStatement0Text(statement?.text[0]);
      setModalStatement1Text(statement?.text[1]);
    }
  }, [statement?.text]);

  return (
    <ModalForm
      heading={`Who it's for`}
      message="Describe the brand target and their motivation."
      handleClose={() => {
        setStatementModalOpen(false);
      }}
      visible={statementModalOpen}
    >
      <ModalContentWrapper>
        <BodySmall>{drugName} is for </BodySmall>
        <EditableUnderlineTextDiv
          placeholder="the brand target"
          suppressContentEditableWarning
          contentEditable
          onInput={(s) => {
            setModalStatement0Text(s);
          }}
          initialValue={statement?.text[0]}
          style={{ minWidth: 108 }}
        />

        <BodySmall> who </BodySmall>
        <EditableUnderlineTextDiv
          placeholder="want to overcome, or achieve something"
          suppressContentEditableWarning
          contentEditable
          onInput={(s) => {
            setModalStatement1Text(s);
          }}
          initialValue={statement?.text[1]}
          style={{ minWidth: 260 }}
        />
        <ButtonsContainer
          style={{ width: '100%', display: 'flex', marginTop: 30 }}
        >
          <ButtonPill
            onClick={() => {
              setStatementModalOpen(false);
            }}
            text="Cancel"
            level="secondary"
          />

          <ButtonPill
            type="submit"
            onClick={async () => {
              setIsModalSubmitLoading(true);
              if (!!statement?.id) {
                await updateStatement({
                  variables: {
                    id: statement?.id,
                    data: {
                      text: [modalStatement0Text, modalStatement1Text],
                    },
                  },
                });
              } else {
                alert('Something went wrong');
              }
              setIsModalSubmitLoading(false);
              setStatementModalOpen(false);
            }}
            text="Save"
            loading={isModalSubmitLoading}
            disabled={isModalSubmitLoading}
          />
        </ButtonsContainer>
      </ModalContentWrapper>
    </ModalForm>
  );
}

function Statement({
  statement,
  strategyData,
  isLead,
  drugName,
  updateStatement,
}: {
  statement: StatementFragment | undefined;
  strategyData: StrategyFragment | null | undefined;
  isLead: boolean;
  drugName: string | undefined;
  updateStatement: ReturnType<typeof useStatementUpdateMutation>[0];
}) {
  const [statementModalOpen, setStatementModalOpen] = useState(false);

  return (
    <>
      <Modal
        setStatementModalOpen={setStatementModalOpen}
        statementModalOpen={statementModalOpen}
        drugName={drugName}
        statement={statement}
        updateStatement={updateStatement}
      />

      <StyledStatementV2
        title={"Who it's for"}
        totalUsers={strategyData?.users.length}
      >
        <StatementWrapper canEdit={isLead}>
          <BodySmall>{drugName} is for </BodySmall>
          <BodySmall
            data-for={'statement__tooltip'}
            data-tip={isLead ? '' : 'Only Leads can edit'}
            onClick={() => {
              if (isLead) setStatementModalOpen(true);
            }}
            className="statement__text--highlight"
            style={{
              color: !!statement?.text[0] ? colors.black : colors.greyDark,
            }}
          >
            {statement?.text[0] || 'the brand target'}
          </BodySmall>
          <BodySmall>{' who '}</BodySmall>
          <BodySmall
            data-for={'statement__tooltip'}
            data-tip={isLead ? '' : 'Only Leads can edit'}
            onClick={() => {
              if (isLead) setStatementModalOpen(true);
            }}
            className="statement__text--highlight"
            style={{
              color: !!statement?.text[1] ? colors.black : colors.greyDark,
            }}
          >
            {statement?.text[1] || 'want to overcome, or achieve something'}
          </BodySmall>
        </StatementWrapper>
      </StyledStatementV2>
    </>
  );
}
