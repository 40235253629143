import { ModalForm } from 'components/ModalForm';
import {
  BodySmall,
  ButtonPill,
  ButtonIcon,
  CountryFlag,
  Heading1,
  Heading4,
  Icon,
  Subtitle1,
  Subtitle2,
  BodyNormal,
  Radio,
  ButtonLabel
} from 'components/shared';
import FormTextInput from 'components/shared/FormTextInput';
import { colors, colorsv2, globalContributor } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import { currencies } from 'constants/index';
import {
  DrugFragment,
  DrugsDocument,
  StrategyFragment,
  useDrugsQuery,
  useDrugUpdateMutation,
  useStakeholderDefinitionsQuery,
  useStakeholderDefinitionUpdateMutation,
  useStrategyCreateMutation,
  useStrategyUpdateMutation,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import _, { startCase, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { Navbar } from '../components/Navbar';
import { Page } from '../components/Page';
import { ModalButton, ModalButtonContainer } from './AdminCompany';
import { ErrorWrapper } from '../components/ErrorLoadingComponent';
import * as themeUtils from 'utils/themes';
import { Loading } from 'components/Loading';

// Initial states
const initialBrandModal = {
  visible: false,
  name: '',
  errorMessage: '',
  drugId: null,
};

const initialTPModal = {
  visible: false,
  name: '',
  errorMessage: '',
  id: null,
};

// @deprecated - replaced by isFullNmblr@utils/themes
export const checkIfFullNmblr = (featureSet: string[]): boolean => {
  const fullNmblr = new Set([
    '1.1',
    '1.2',
    '1.3',
    '1.4',
    '1.5',
    '2.1',
    '2.2',
    '2.3',
    '2.4',
    '2.5',
    '3.1',
    '3.2',
    '3.3',
    '3.4',
    '3.5',
    '4.1',
    '4.2',
    '4.3',
    '4.4',
    '4.5',
  ]);
  return featureSet.length === fullNmblr.size && featureSet.every(feature => fullNmblr.has(feature));
};

// START: Styled Globals (Some of this are unnecessary to export externally but...)
export const Divider = styled.div`
  width: 1px;
  background-color: ${colors.black10};
`;

export const DrugDetailWrapper = styled.div`
  width: 559px;
  min-height: 70px;
  background: ${colors.white};
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  > p {
    width: 88px;
    margin-right: 15px;
    align-self: flex-start;
  }

  .DrugDetailWrapper__textarea {
    display: block;
    border: none;
    padding: 0;
    overflow-y: hidden;
    resize: none;
    flex-grow: 1;
    font-family: ABCFavorit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    color: ${colors.greyDark};
    outline: 0.5px solid transparent;
    &:focus {
      outline: 0.5px solid ${colors.blue};
    }
    transition: outline 0.3s;
  }

  @media ${device.tabletMax} {
    width: 100%;
  }

  @media ${device.mobile} {
    flex-direction: column;
    margin-bottom: 2px;
    > p {
      width: 100%;
    }
  }
`;

export const DrugDetailBorderWrapper = styled.div`
  padding: 1px;
  border-radius: 5px;
  margin: 18px 0;
  background: ${colors.purplePinkGradient};
  ${DrugDetailWrapper} {
    margin-bottom: 0px;
  }
  opacity: 1;
  max-height: 1000px;
  transition: 0.3s ease;
`;

const StyledModalForm = styled(ModalForm)`
  > div div:last-child {
    min-width: auto;
  }

  @media ${device.mobile} {
    min-width: auto;
  }
`;

// END: Styled Globals

// START: Styled Blocks
const BlocksWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const BlockInner = styled.div`
  display: flex;
  gap: 4px;
`;

const BlockIconWrapper = styled.div`
  height: 24px;
`;

const BlockName = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  ${Heading4} {
    color: ${colorsv2.textInverted.default};
  }
`;
// END: Styled Blocks

// START: Styled Drugs
const PageWrapper = styled(Page)`
  color: ${colorsv2.textInverted.default};
  padding-top: 80px;

  > div {
    max-width: 1164px;
    width: 100%;
  }

  @media (max-width: 1190px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const DrugWrapper = styled.div`
  background-color: ${colorsv2.bg.surfaceBrand};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const DrugHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;

  ${Heading1} {
    line-height: 1;
  }
`;

const DrugsEmptyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  p {
    color: ${colorsv2.textInverted.default};
    text-align: center;
  }
`;

const DrugEditButton = styled(ButtonIcon)`
  height: 40px;
  width: 40px;
`;

export const DrugDetails = styled.div`
  margin-left: auto;
`;
// END: Styled Drugs

// START: Styled Strategy
const StrategiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StrategyContent = styled.div`
  align-items: center;
  background: ${colorsv2.bg.brand};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 16px;

  :hover {
    background: ${colorsv2.bg.brandHover};
  }
`;

const StrategyTargetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const StrategyEditButton = styled(ButtonIcon)`
  height: 40px;
  width: 40px;
`;

export const StrategyButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
`;

const StrategyFlagsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 24px);
  gap: 8px;
  flex-wrap: wrap;

  .cypress-country-flag-icon {
    border-color: ${colorsv2.border.default};
    height: 24px; 
    width: 24px;
  }
`;

const StrategyCurrencyOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
`;

const StrategyCountriesWrapper = styled.div`
  transition: 0.3s ease;
`;

const StrategyModalButton = styled(ButtonPill)`
  min-width: 160px;
  width: 180px;
`;

export const StrategicDrugDetailsWrapper = styled(DrugDetailWrapper)`
  align-items: flex-start;

  & > div {
    width: 50%;
    @media ${device.mobile} {
      width: 100%;
    }
  }
  ${Divider} {
    width: 1px;
    margin: 0 22px;

    @media ${device.mobile} {
      width: 100%;
      height: 1px;
      margin: 15px 0;
    }
  }
  ${BodySmall} {
    margin-bottom: 5px;
  }
  textarea {
    width: 100%;
  }

  transition: 0.3s ease;
`;

export const BottomSection = styled.div<{ hide: boolean }>`
  width: 100%;
  margin-top: -118px;
  display: flex;
  ${StrategyCountriesWrapper} {
    order: -1;
    align-self: flex-end;
  }

  @media ${device.tabletMax} {
    margin-top: 0px;
    flex-direction: column;
    & > div {
      width: 100%;
    }
    ${StrategyCountriesWrapper} {
      order: 0;
    }
  }
  .hide,
  ${StrategyCountriesWrapper} {
    transition: 0.3s ease;
    ${({ hide }) =>
    hide
      ? css`
            opacity: 0;
            max-height: 0px;
            min-height: 0px;
            margin: 0;
          `
      : css`
            opacity: 1;
            max-height: 1000px;
          `};
  }
`;
// END: Styled Strategy

const Strategies: React.FC<{ drug: DrugFragment, loading: boolean }> = ({ drug, loading }) => {
  const strategies = drug?.Strategy;

  if (loading || strategies === null) {
    return (
      <Loading 
        color="white" 
        text=""
        style={{
          alignItems: 'flex-start'
        }}
      />
    );
  }

  if (!strategies) {
    return null;
  }

  return (
    <StrategiesWrapper>
      {strategies?.map((strategy, index) => {
        return (
          <React.Fragment key={strategy?.id}>
            <StrategyDetails drug={drug} strategy={strategy} />
          </React.Fragment >
        );
      })}
    </StrategiesWrapper>
  )
};

const StrategyDetails: React.FC<{ 
  strategy: StrategyFragment; 
  drug: DrugFragment;
 }> = ({
  strategy, 
  drug 
}) => {
  const [strategyUpdate] = useStrategyUpdateMutation();
  const [values] = useState(strategy);
  const [currencyModalVisible, setCurrencyModalVisible] = useState(false);
  const [currency, setCurrency] = useState(strategy.currency || currencies[0]);
  const [currencySaving, setCurrencySaving] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const history = useHistory();

  const [
    updateStakeholderDefinition,
  ] = useStakeholderDefinitionUpdateMutation();

  const [renameTPModal, setRenameTPModal] = React.useState<{
    visible: boolean;
    name: string;
    errorMessage: string;
    id: number | null;
  }>(initialTPModal);

  const closeRenameTPModal = () => setRenameTPModal(initialTPModal);

  const { 
    data: drugData,
    loading: drugDataLoading,
  } = useDrugsQuery({
    variables: { where: { id: drug.id } },
    fetchPolicy: 'network-only',
  });

  const {
    data: stakeholderDefinitions,
    loading: stakeholderDefinitionsLoading,
  } = useStakeholderDefinitionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { 
        id: strategy.stakeholderDefinitionId, 
        strategyId: +strategy.id
      } 
    },
  });

  const ProductOnDrugs = drugData?.drugs?.items?.filter(
    (e) => e.id === drug.id
  )[0]?.ProductOnDrugs;

  const miniFeatureSet = useMemo(() => {
    if (ProductOnDrugs) {
      return ProductOnDrugs?.filter(i => i.drugId === drug.id).flatMap((f: any) => {
        if (f.Product?.features)
          return f.Product?.features?.map((g: any) => g.featureSet);
        return [];
      });
    }
    return [];
  }, [ProductOnDrugs, drug.id]);

  const targetPopulation = stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
    (e) => e.id === strategy.stakeholderDefinitionId
  )[0]?.title;

  const isDesktop = useDesktop();
  const isLoading = drugDataLoading || stakeholderDefinitionsLoading;

  const contributors = React.useMemo(() => {
    return uniqBy(strategy.users, 'country')
            .filter((user) => user.role === 'CONTRIBUTOR')
            .filter(
              (user) =>
                user?.country && user?.country !== globalContributor
                          )
  }, [strategy.users]);

  const handleTPUpdate = async (drugId: number) => {
    const { name } = renameTPModal;

    if (!name.length)
      return setRenameTPModal({
        ...renameTPModal,
        errorMessage: 'Name cannot be blank',
      });

    try {
      updateStakeholderDefinition({
        variables: {
          id: drugId,
          data: { title: name },
        },
      });

      closeRenameTPModal();
    } catch (err) {
      console.error(err);
    }
  }

  return strategy ? (
    <>
      <StyledModalForm visible={currencyModalVisible} handleClose={() => {}}>
        <Subtitle1 style={{ marginTop: -20 }}>
          Choose a global currency
        </Subtitle1>
        <BodyNormal>This is the currency to use for global budgets.</BodyNormal>

        <div>
          {currencies.map((c) => (
            <StrategyCurrencyOption onClick={() => setCurrency(c)} key={c}>
              <Radio checked={c === currency} />
              <BodySmall style={{ marginLeft: 6 }}>{c}</BodySmall>
            </StrategyCurrencyOption>
          ))}
        </div>

        <StrategyButtonsWrapper>
          <StrategyModalButton
            onClick={() => setCurrencyModalVisible(false)}
            text="Cancel"
            level="secondary"
          />
          <StrategyModalButton
            onClick={() => {
              setCurrencySaving(true);
              try {
                strategyUpdate({
                  variables: {
                    id: Number(values.id),
                    data: {
                      currency,
                    },
                  },
                });
                setCurrencySaving(false);
                setCurrencyModalVisible(false);
              } catch (err) {
                console.error(err);
                alert('Something went wrong');
                setCurrencySaving(false);
              }
            }}
            text="Save"
            loading={currencySaving}
            disabled={currencySaving}
          />
        </StrategyButtonsWrapper>
      </StyledModalForm>

      <StyledModalForm
        handleClose={closeRenameTPModal}
        visible={renameTPModal.visible}
        heading="Rename target population"
      >
        <FormTextInput
          name="name"
          title="Name"
          onChange={(e) =>
            setRenameTPModal({ ...renameTPModal, name: e.target.value })
          }
          value={renameTPModal.name}
          type="text"
          errorMessage={renameTPModal.errorMessage}
        />
        <ModalButtonContainer>
          <ModalButton
            onClick={closeRenameTPModal}
            text="Cancel"
            level="secondary"
          />
          <ModalButton
            onClick={() =>
              renameTPModal.id
                ? handleTPUpdate(renameTPModal.id)
                : null
            }
            text="Save name"
          />
        </ModalButtonContainer>
      </StyledModalForm>

      <StrategyContent
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
        onClick={(e) => {
          e.preventDefault();

          if (strategy.isDraft) {
            return history.push(`/d/${drug.id}/strategy/${values.id}/create/period`);
          }

          if (!themeUtils.isFullNmblr(miniFeatureSet)) {
            history.push(`/d/${drug.id}/strategy/${values.id}/welcome`);
          } else {
            history.push(`/d/${drug.id}/strategy/${values.id}`);
          }
        }}
      >
        {isLoading ? (
          <Loading 
            color="white" 
            text=""
          />
        ) : (
          <>
            <StrategyTargetWrapper>
              <ButtonLabel
                level={100}
                color={colorsv2.textInverted.default}
              >
                {targetPopulation || 'Unnamed target population'}
                {strategy.isDraft ? ` (Not published)` : ''}
              </ButtonLabel>

              {contributors.length > 0 && (
                <StrategyCountriesWrapper>
                  <StrategyFlagsWrapper>
                    {contributors.map((user) => {
                        return (
                          <CountryFlag
                            user={user}
                            key={user.id}
                            tooltip={startCase(user?.country || '')}
                            size={25}
                          />
                        );
                      })}
                  </StrategyFlagsWrapper>
                </StrategyCountriesWrapper>
              )}
            </StrategyTargetWrapper>
            
            <div style={{
              height: 40,
              width: 40
            }}>
              {!strategy.isDraft && (showIcon || !isDesktop) && (
                <StrategyEditButton
                  iconName="Pencil"
                  level="ghost-inverted"
                  onClick={(e) => {
                    e.stopPropagation()
                    setRenameTPModal({
                      ...renameTPModal,
                      visible: true,
                      name: targetPopulation || '',
                      id: strategy?.stakeholderDefinitionId ?? null,
                    })
                  }
                  }
                />
              )}
            </div>
          </>
        )}
      </StrategyContent>
    </>
  ) : null;
};

const Blocks: React.FC<{ drug: DrugFragment, loading: boolean }> = ({ drug, loading }): any => {
  const strategies = drug?.Strategy;
  const sortedDrugs = themeUtils.sortProductOnDrugs(drug?.ProductOnDrugs);

  if (loading || strategies === null) {
    return (
      <Loading 
        color="white" 
        text=""
        style={{
          alignItems: 'flex-start'
        }}
      />
    );
  }

  return (
    <BlocksWrapper>
      {sortedDrugs?.map((product: any, index: number) => {
        // get features
        const features = product?.Product?.features?.map((f: any) => f.featureSet);
        const isFullNmblr = themeUtils.isFullNmblr(features);

        if (isFullNmblr) {
          return (
            <BlockInner key={index}>
              <BlockIconWrapper>
                <Icon
                  color="white"
                  name={'Home'}
                  size={24}
                  height={24}
                  width={24}
                />
              </BlockIconWrapper>
              <BlockName>
                <Heading4 weight="light">Nmblr</Heading4>
                <Heading4>Strategy</Heading4>
              </BlockName>
            </BlockInner>
          )
        }

        const block = product.block;

        return (
          <BlockInner key={index}>
            <BlockIconWrapper>
              <Icon
                name={`Block${_.startCase(block.key)}` as any}
                size={24}
                height={24}
                width={24}
              />
            </BlockIconWrapper>
            <BlockName>
              <Heading4 weight="light">Nmblr</Heading4>
              <Heading4>{_.startCase(block.key)}</Heading4>
            </BlockName>
          </BlockInner>
        )
      })}
    </BlocksWrapper>
  )
}

export const Drugs: React.FC = () => {
  const history = useHistory();
  const [page] = useState<number>(0);
  const [{ user }] = useAuthContext();
  const [drugUpdate] = useDrugUpdateMutation();
  const [strategyCreate] = useStrategyCreateMutation();
  const pageSize = 20;
  const queryVars = {
    where: {},
    skip: page * pageSize,
    take: pageSize,
  };

  const { data: drugData, loading, error } = useDrugsQuery({
    variables: queryVars,
    fetchPolicy: 'network-only',
  });

  const [renameBrandModal, setRenameBrandModal] = React.useState<{
    visible: boolean;
    name: string;
    errorMessage: string;
    drugId: number | null;
  }>(initialBrandModal);

  const possibleDrugs = React.useMemo(() => drugData?.drugs?.items, [drugData?.drugs]);

  const closeRenameBrandModal = () => setRenameBrandModal(initialBrandModal);

  const handleBrandUpdate = async (drugId: number) => {
    const { name } = renameBrandModal;

    if (!name.length) {
      return setRenameBrandModal({
        ...renameBrandModal,
        errorMessage: 'Name cannot be blank',
      });
    }

    try {
      await drugUpdate({
        variables: {
          id: drugId,
          data: { name },
        },
        refetchQueries: [
          {
            query: DrugsDocument,
            variables: queryVars,
          },
        ],
      });

      closeRenameBrandModal();
    } catch (err) {
      console.error(err);
    }
  }

  const handleStrategyCreate = async (drugId: number) => {
    try {
      if (typeof user?.id === 'number') {
        const { data } = await strategyCreate({
          variables: {
            data: {
              isDraft: true,
              drug: drugId,
            },
          },
        });

        const strategyId = data?.strategyCreate.id;

        history.push(`/d/${drugId}/strategy/${strategyId}/create/period`);
      } else {
        throw new Error('User Id not found');
      }
    } catch (err) {
      alert('Something went wrong');
    }
  }

  return (
    <>
      <StyledModalForm
        handleClose={closeRenameBrandModal}
        visible={renameBrandModal.visible}
        heading="Rename brand"
      >
        <FormTextInput
          name="name"
          title="Brand name"
          onChange={(e) =>
            setRenameBrandModal({ ...renameBrandModal, name: e.target.value })
          }
          value={renameBrandModal.name}
          type="text"
          errorMessage={renameBrandModal.errorMessage}
        />
        <ModalButtonContainer>
          <ModalButton
            onClick={closeRenameBrandModal}
            text="Cancel"
            level="secondary"
          />
          <ModalButton
            onClick={() =>
              renameBrandModal.drugId
                ? handleBrandUpdate(renameBrandModal.drugId)
                : null
            }
            text="Save name"
          />
        </ModalButtonContainer>
      </StyledModalForm>

      <Navbar
        disableSecondary
        title="Drugs"
      />

      <ErrorWrapper
        bg="dark"
        isLoading={loading}
        errors={[error]}
        dataMissing={!drugData}
      >
        <PageWrapper bg="wave" fixed={true}>
          {!possibleDrugs?.length ? (
            <DrugWrapper>
              <DrugsEmptyWrapper>
                <Icon
                  name="GenericEmptyState"
                  size={115}
                  height={115}
                  color="initial"
                />
                <Subtitle2 color={colors.greyDark}>
                  You don’t have access to any strategies
                </Subtitle2>
                <BodySmall color={colors.greyDark}>
                  Contact a Lead for help
                </BodySmall>
              </DrugsEmptyWrapper>
            </DrugWrapper>
          ) : (
            possibleDrugs.map((d) => {
              const hasStrategies = !!d?.Strategy?.length;

              return (
                <DrugWrapper key={d.id}>
                  <DrugHeadingWrapper>
                    <Heading1 color={colorsv2.textInverted.default}>{d.name}</Heading1>

                    {user?.role === 'LEAD' && (
                      <>
                        <DrugEditButton
                          iconName="Pencil"
                          level="ghost-inverted"
                          onClick={() =>
                            setRenameBrandModal({
                              ...renameBrandModal,
                              visible: true,
                              name: d.name,
                              drugId: d.id,
                            })
                          }
                        />
                      </>
                    )}
                  </DrugHeadingWrapper>

                  <Blocks 
                    drug={d} 
                    loading={loading} 
                  />
                  
                  <Strategies 
                    drug={d} 
                    loading={loading} 
                  />
                  
                  <ButtonPill
                    level={hasStrategies ? 'ghost-inverted' : 'primary'}
                    text="Add target population"
                    iconName="PlusCircle"
                    iconSize={16}
                    onClick={() => handleStrategyCreate(d.id)}
                  />
                </DrugWrapper>
              );
            })
          )}
        </PageWrapper>
      </ErrorWrapper>
    </>
  );
};