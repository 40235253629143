import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { colors, colorsv2 } from 'constants/index';
import { Icon } from './Icon';
import { Tooltip } from './Tooltip';
import { uid } from 'uid';
import { IconName } from 'types';
import { AnimatingWaveIcon } from '.';

export type ButtonLevel =
  | 'primary'
  | 'ghost'
  | 'ghost-inverted'
  
function getLevelStyle(level: ButtonLevel) {
  switch (level) {
    case 'primary':
      return css`
        color: ${colors.white};
        background: ${colorsv2.bg.brand};
        position: relative;
        > p,
        > div {
          z-index: 1;
        }

        &:hover {
          background: ${colorsv2.bg.brandHover};
        }

        &:active {
          background: ${colorsv2.bg.brandDown};
        }
      `;

    case 'ghost':
      return css`
        background: none;
        color: ${colorsv2.text.default};

        svg {
          color: ${colorsv2.text.default};
          fill: ${colorsv2.text.default};
        }

        :hover {
          background: rgba(10, 0, 35, 0.16);
        }
      `;

    case 'ghost-inverted':
      return css`
        background: none;
        color: ${colorsv2.textInverted.default};

        svg {
          color: ${colorsv2.textInverted.default};
          fill: ${colorsv2.textInverted.default};
        }

        :hover {
          background: rgba(255, 255, 255, 0.16);
        }
      `;

    default:
      break;
  }
}

export const TooltipWrapper = styled.div<{
  width?: string;
  level: ButtonLevel;
}>`
  display: flex;
  border: none;
  height: auto;
  width: ${({ width }) => (width ? width : 'fit-content')};
`;

interface ButtonProps {
  level: ButtonLevel;
  icon: boolean;
  disabled: boolean;
  size?: string;
  width?: string;
}

export const ButtonIconWrapper = styled.button<ButtonProps>`
  align-items: center;
  border: none;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  user-select: none;

  * {
    pointer-events: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  ${({ level }) => getLevelStyle(level)}

  ${({ size }) => {
    if (size === 'small') {
      return css`
        height: 32px;
        width: 32px;
      `;
    } else {
      return css`
        height: 40px;
        width: 40px;
      `;
    }
  }}

  > .icon {
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }

  p {
    white-space: nowrap;
  }

  transition: all 0.3s ease;
`;

export interface ButtonIconProps {
  iconName?: IconName;
  iconSize?: number;
  disabled?: boolean;
  level?: ButtonLevel;
  loading?: boolean;
  tooltip?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  forceHover?: boolean;
  height?: string;
  width?: string;
  className?: string;
  clickClassName?: string;
  color?: string;
  size?: 'default' | 'small' | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

function getLevelColor(level: ButtonLevel) {
  switch (level) {
    case 'primary':
    case 'ghost-inverted':
      return colorsv2.textInverted.default;
    default:
      return colors.black;
  }
}

export const ButtonIcon = ({
  level = 'primary',
  loading = false,
  tooltip = '',
  iconName,
  iconSize,
  onClick,
  disabled = false,
  height,
  width,
  className = '',
  clickClassName = '',
  color,
  size,
  type,
}: ButtonIconProps) => {
  const key = uid();
  const [pressed] = useState(false);
  const AnimatingWaveIconColor = color === colors.white ? 'white' : undefined;

  return (
    <TooltipWrapper
      className={className}
      data-for={key}
      data-tip={tooltip}
      width={width}
      tabIndex={0}
      level={level}
    >
      <ButtonIconWrapper
        type={type}
        icon={!!iconName}
        level={level}
        size={size}
        disabled={disabled || loading}
        className={clickClassName}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) onClick?.(e);
        }}
      >
        {iconName ? (
          loading || pressed ? (
            <AnimatingWaveIcon
              className="icon"
              size={iconSize ? iconSize : 24}
              color={AnimatingWaveIconColor}
            />
          ) : (
            <Icon
              className="icon"
              name={iconName}
              size={iconSize ? iconSize : 24}
              color={color ?? getLevelColor(level)}
            />
          )
        ) : null}
      </ButtonIconWrapper>
      <Tooltip id={key} />
    </TooltipWrapper>
  );
};
